import React, { useEffect, useState } from 'react'

import { useNavigate, Link, useLocation } from 'react-router-dom'
import { useForm } from 'react-hook-form';
import axios from 'axios'
import {HiOutlineArrowRight} from 'react-icons/hi'

import SENDFORGOTPASSMAIL_API from '../../../../api/api'
import { Spinner } from 'react-bootstrap';
import { Helmet } from 'react-helmet';
import { loginModalTrue } from '../../../../redux/actions/action';
import { useDispatch } from 'react-redux';


const ResetMail = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation();
    const pathname = location.pathname;
    const [mailErr, setMailErr] = useState()
    const [spinLoadVisibility, setSpinLoadVisibility] = useState(false);

    const { register, handleSubmit } = useForm();


    useEffect(() => {
        document.body.classList.add("remove-home-header");
        return () => {
            document.body.classList.remove("remove-home-header");
        }
    }, [])

    const resetLink = async (data) => {
        setSpinLoadVisibility(true)
        await axios.post(SENDFORGOTPASSMAIL_API.SENDFORGOTPASSMAIL_API, { email: data.email })
            .then((res) => {
                setSpinLoadVisibility(false)

                if (res.data.success === true) {
                    const encryptedMail = encodeURI(data.email);
                    navigate(`/resetPasswordLink/${encryptedMail}`)
                } else {
                    setMailErr(res.data.message)
                }


            })
    }


    return (
        <React.Fragment>
               <Helmet>
                <title>Reset Koli Samaj Login Password</title>
                <meta name="description" content="Please enter your registered email address and we would send you the link to reset your password." />
                <meta name="keywords" content="Member koli samaj" />
                <meta name="author" content="mahendra koli" />
                <link rel="canonical" href="https://www.kolisamajvikashsanstha.com/resetmail" />
            </Helmet>
        
        <div className="container">
            <div className="max-new-second pd-t-b-100">
                <div className="main-theme bg-theme border otp-pad new shadow">
                    <section className="select-state">
                        <div className="state-bor">
                            <div className="log-in otp-num">
                                <div className="select-steps-box">
                                    <div className="input-head">
                                        <h2>Reset Password</h2>
                                        <p className='mb-0'>कृपया पासवर्ड बदलवाने के लिए एडमिन से संपर्क करे!</p>
                                        <p>अगर आप अपना पासवर्ड भूल चुके हे तो अपना मेल 7737813122 पर फॉरगॉट : आपका मेल लिख कर व्हाट्स एप्प पर मैसेज करे</p>
                                        <p className='mb-0'>Please contact admin to change password.</p>
                                        <p>If you have forgotten your password then forget your mail on 7737813122 : message on whatsapp by writing your mail</p>
                                    </div>
                                </div>
                                {/* <div className="select-box-top pr-0">
                                    <div className="input-box">
                                        <form onSubmit={handleSubmit(resetLink)}>
                                            <div className="col-12 p-0">
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <div className="form-group">
                                                            <input type="email" className="form-control" name="email" {...register('email', { required: true })} placeholder="Enter your Email" />
                                                            <small className=" form-text text-muted">{mailErr}</small>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="theme-btn">
                                                <button  disabled={spinLoadVisibility} type="submit" className="btn verfy-btn">
                                                {
                                                    spinLoadVisibility === false ?
                                                    <>
                                                    Submit <HiOutlineArrowRight />
                                                    </>:
                                                    <div className="spinner-visibility">
                                                    <Spinner
                                                        as="span"
                                                        animation="grow"
                                                        size="sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    />
                                                   Sending...
                                                </div>
                                                }
                                                   
                                                </button>
                                                <p className="resend new">Have an account? <span><Link onClick={()=>dispatch(loginModalTrue(true))} to="/">Login </Link></span></p>
                                            </div>
                                        </form>
                                    </div>

                                </div> */}
                                <p className="resend new">Have an account? <span><Link onClick={()=>dispatch(loginModalTrue(true))} to="/">Login </Link></span></p>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
        </React.Fragment>
    )
}

export default ResetMail;