
import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import moment from 'moment'
import { ToastContainer, toast, } from "react-toastify";
import { useForm, Controller } from 'react-hook-form';
import { GiSwordsEmblem } from 'react-icons/gi';


// icons
import { FaSearch } from 'react-icons/fa';
import {FaEdit} from 'react-icons/fa';
import { MdDeleteForever } from 'react-icons/md';

import PORTNUMBER_ADMIN from '../../../../api/api'




// Import Datepicker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from 'react-router-dom'

// react bootstrap
import { Modal, Button } from 'react-bootstrap'
import Pagination from "react-js-pagination";

import FormValidation from '../../../../formValidation/formValidation'

import {toasterValue} from '../../../../redux/actions/action'
import {useDispatch} from 'react-redux'

import {useNavigate} from 'react-router-dom'


const TopStudents = () => {
    const dispatch = useDispatch()
    const PORT_ADMIN = PORTNUMBER_ADMIN.PORTNUMBER_ADMIN
    const AdminToken = localStorage.getItem("AdminToken")

    const navigate = useNavigate()
    const { register, handleSubmit, reset } = useForm();
  
    const FormValidationNew = FormValidation


    // modal active
    const [activeShow, setActiveShow] = useState(false);
    const [inActiveShow, setInActiveShow] = useState(false);
    const [statusId, setStatusId] = useState();


    // const [Loading, setLoading] = useState(false);

    const [Count, setCount] = useState(1);
    const [indexPage, setIndexPage] = useState(1);
    const [activePage, setActivePage] = useState()


    const [allStudents, setAllStudents] = useState(["Not"]);
    const [loading, setLoading] = useState(false);


    const [userPerPage] = useState(50);

    const [searchTerm, setSearchTerm] = useState("");

    const [filterData, setFilterData] = useState([]);
    const [filterCount, setFilterCount] = useState([]);

    const [filterVisibility, setFilterVisibility] = useState(true);



    const [formValidation, setFormValidation] = useState({
        Email: "",
        Mobile: "",
        default: "",
        Success: null
    })
  

    
    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
        setIndexPage(pageNumber)
        LoadStudents(pageNumber)
        setLoading(false);
    }



    const activeHandleClose = () => setActiveShow(false);
    const activeHandleShow = (id) => {
        setActiveShow(true);
        setStatusId(id)

    }

    const inActiveHandleClose = () => setInActiveShow(false);
    const inActiveHandleShow = (id) => {
        setInActiveShow(true);
        setStatusId(id)


    }

    const deleteUser = async () => {
        await axios.post(`${PORT_ADMIN}/topStudents/delete`, { studentId: statusId}, { headers: { authorization: `Bearer ${AdminToken}` } }).then((res, err) => {
            LoadStudents()
            toast.success("Student is deleted successfully.")
            activeHandleClose()
        })
    }
   
 

    const LoadStudents = useCallback( () => {
        axios.get(`${PORT_ADMIN}/topStudents/list`)
        .then((res)=>{
            setAllStudents(res.data.data)
        setLoading(true);
        })
    },[PORT_ADMIN])





    const onSubmit = (data) => {

        const imageFiles = document.getElementById('studentImage').files[0];

        const fs = new FormData();
        for (var key in data) {
            fs.append(key, data[key]);
        }
        fs.append("image",imageFiles);

            axios.post(`${PORT_ADMIN}/topStudents/create`, fs, { headers: { authorization: `Bearer ${AdminToken}` } })
                .then((res) => {
                    dispatch(toasterValue("You have successfully added a new user"))
                    LoadStudents();
                    // reset()
                    // navigate("/userManagement")
            
                })
        
                LoadStudents();
                // navigate("/TopStudents")
            
        }

     

    


    useEffect(() => {
        document.body.classList.add('remove-home-header', 'admin-dashboard');
        return () => {
          document.body.classList.remove('remove-home-header', 'admin-dashboard');
    
        }

      }, [])


    useEffect(() => {
        FormValidationNew()
        LoadStudents()
    },[FormValidationNew,LoadStudents])

    return (
        <div className="tables-field">
          <div className="project-table">
                <div className="col-12">
                    <div className="row">
                        <div className="col-6">
                            <div className="main-head">
                                <h4>Add New Student</h4>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="theme-btn text-right top-head">
                                <Link to="/userManagement" className="btn">Back</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="table-order-box shadow">
                    <div className="tab-head">
                    <small  className=" form-text d-block text-center mb-4 text-muted">{formValidation.default}</small>
                        <form onSubmit={handleSubmit(onSubmit)} className="needs-validation" noValidate>
                            <div className="row">
                            <div className="col-lg-6">
                                    <div className="form-group">
                                    <input id="studentImage" name="image" {...register('image', { required: false })} type="file" className="form-control" />

                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <input type="text" className="form-control" id="validationCustom01"  {...register('full_name', { required: true })} placeholder=" Name" required />
                                        <div className="invalid-feedback">
                                            User name required.
                                        </div>

                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <input type="text" className="form-control" id="validationCustomTwo2" {...register('father_name', { required: true })} placeholder="Father Name" required />
                                        <div className="invalid-feedback">
                                           Please Enter Father Name
                                        </div>
                                     
                                    </div>
                                </div>
                     
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <input type="text" className="form-control" id="validationCustomThree2" {...register('class', { required: true })} placeholder="Class" required />
                                        <div className="invalid-feedback">
                                            Please enter class.
                                        </div>
                                      
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <input type="text" className="form-control" id="validationCustomFour2" {...register('percentage', { required: true })} placeholder="Percentage" required />
                                        <div className="invalid-feedback">
                                            Please enter percentage.
                                        </div>
                                      
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <input type="text" className="form-control" id="validationCustomFive2" {...register('school', { required: true })} placeholder="School Name" required />
                                        <div className="invalid-feedback">
                                            Please enter school name.
                                        </div>
                                      
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <input type="text" className="form-control" id="validationCustomSix2" {...register('address', { required: true })} placeholder="Address" required />
                                        <div className="invalid-feedback">
                                            Please enter adress.
                                        </div>
                                      
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <input type="text" className="form-control" id="validationCustomSeven2" {...register('roll_num', { required: false })} placeholder="Roll Number"  />
                                        <div className="invalid-feedback">
                                            Please enter roll number.
                                        </div>
                                      
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <input type="text" className="form-control" id="validationCustomEight2" {...register('studentid', { required: false })} placeholder="Student Year "  />
                                        <div className="invalid-feedback">
                                            Please enter Year.
                                        </div>
                                      
                                    </div>
                                </div>
                             
                                <div className="col-lg-12">
                                    <div className="theme-btn btn-group-right text-right">
                                        <button type="submit" className="btn">Save</button>
                                        <Link to="/userManagement" className="btn">Cancel</Link>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className="project-table">
                <div className="form-search">
                    <form>
                        <div className="form-group icon-input top-search">
                            <input type="text" className="form-control" onChange={(event) => { setSearchTerm(event.target.value) }} placeholder="Search" />
                            <FaSearch />
                        </div>

                    </form>
                </div>
                <div className="table-order shadow">

                    <table>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Father Name</th>
                                <th>Class</th>
                                <th>School</th>
                                <th>Year</th>
                                <th>Percentage</th>
                                <th>Actions </th>
                            </tr>
                        </thead>
                        <tbody>


                            { allStudents && allStudents.length ? allStudents.filter((val) => {
                                const date = val.createdAt
                                if (searchTerm === "") {
                                    return val
                                } else if (val.full_name.toLowerCase().includes(searchTerm.toLowerCase())) {
                                    return val
                                } else if (val.email.toLowerCase().includes(searchTerm.toLowerCase())) {
                                    return val
                                } else if (val.mobile.toLowerCase().includes(searchTerm.toLowerCase())) {
                                    return val
                                } else if (moment(date).format("DD/MM/YYYY").toLowerCase().includes(searchTerm.toLowerCase())) {
                                    return val
                                } else if (val.status.toLowerCase().includes(searchTerm.toLowerCase())) {
                                    return val
                                }else{
                                    return false
                                }


                            }).map(student => (
                                <tr key={student._id}>
                                    <td >
                                        {student.full_name}
                                    </td>
                                    <td >
                                        {student.father_name}
                                    </td>
                                    <td >
                                        {student.class}
                                    </td>
                                    <td >
                                        {student.school}
                                    </td>
                                    <td >
                                        {student.studentid}
                                    </td>
                                    <td >
                                        {student.percentage}
                                    </td>
                                    <td className="action-top">
                                        <Link to={`/admin/topStudents/${student._id}`} className="icons-action"><FaEdit /></Link>
                                       
                                        {
                                            <span onClick={() => activeHandleShow(student._id)} className="icons-action"><MdDeleteForever /></span>

                                        }


                                    </td>

                                </tr>

                            )) : <tr><td className="no-data">NO ANY USER FOUND</td></tr>
                               }



                        </tbody>
                    </table  >
                    {
                        !loading ?
                            <div className="loader-wrapper">
                                <div className="loader">
                                    <div className="loading-svg">
                                        <GiSwordsEmblem />
                                    </div>
                                </div>
                            </div> : ""
                    }


                    {/* <BootstrapTable keyField="id" colunms={columns} data={posts}  filter={filterFactory()} /> */}
                </div>
                <div className='pagination-top'>
                            <Pagination
                                activePage={activePage}
                                itemsCountPerPage={10}
                                totalItemsCount={Count}
                                pageRangeDisplayed={10}
                                onChange={handlePageChange}
                            />
                        </div>

              

            </div>

            {/* modals */}

            {/* active modal */}
            <Modal className="status-modal" animation={false} show={activeShow} onHide={activeHandleClose}>

                <Modal.Body>Are you sure you want to delete student?</Modal.Body>
                <Modal.Footer>
                    <div className="theme-btn btn-group-right text-right">
                        <Button variant="secondary" onClick={deleteUser}>
                            Yes
                        </Button>
                        <Button variant="primary" onClick={activeHandleClose}>
                            No
                        </Button>
                    </div>

                </Modal.Footer>
            </Modal>
            {/* active modal end */}

          
            <ToastContainer />
        </div>
    )
}
export default TopStudents;