import React from "react"

const Famous = () => {
    return (
        <section className="koli-history">
            <p>
            <span className="brown-color point d-block mr"> कान्होजी आंग्रे :- </span> मराठा समुंद्री सेना के अध्यक्ष।
            <span className="brown-color point d-block mr"> झलकारी बाई :- </span> 1857 की क्रांति की प्रथम सेनानी एवं झांसी की महिला सेना की सेनापति।
            <span className="brown-color point d-block mr"> तानाजी मालुसरे :- </span>  शिवाजी महाराज की मराठा सेना का सेनापति एवं महाबलेश्वर राजवाड़े के कोली देशमुख।
            <span className="brown-color point d-block mr"> यशवंत राव मुकने :- </span> जव्हार रियासत के अंतिम कोली महाराजा।
            <span className="brown-color point d-block mr"> राजेश चुडासमा :- </span> गुजरात सरकार में मंत्री।
            <span className="brown-color point d-block mr"> देवजीभाई गोविंदभाई फतेपारा :- </span>भारत की सोलहवीं लोकसभा में सांसद हैं। 2014 के चुनावों में इन्होंने गुजरात की सुरेन्द्रनगर सीट से भारतीय जनता पार्टी की ओर से भाग लिया।
            <span className="brown-color point d-block mr"> भारती शियाल :- </span> भारत की सोलहवीं लोकसभा में सांसद हैं। 2014 के चुनावों में इन्होंने गुजरात की भावनगर सीट से भारतीय जनता पार्टी की ओर से भाग लिया।[196]
            <span className="brown-color point d-block mr"> प्राजक्ता कोली :-</span>यूट्यूबर जिनको MostlySane के नाम से जाना जाता है।
            <span className="brown-color point d-block mr"> रामनाथ कोविंद :- </span> राम नाथ कोविंद (जन्म 1 अक्टूबर 1945) एक भारतीय राजनेता हैं जो भारत के 14 वें और वर्तमान राष्ट्रपति के रूप में सेवारत हैं। अपने नामांकन से पहले, उन्होंने 2015 से 2017 तक बिहार के 26 वें राज्यपाल और 1994 से 2006 तक राज्यसभा के सदस्य के रूप में कार्य किया।
            </p>
        </section>
    )
}

export default Famous;