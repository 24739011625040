import React, { useState, useEffect, useCallback } from 'react'
import { ImageObj } from '../../../assets/images/images'
import { Tabs, Tab, Modal, Button, Dropdown } from 'react-bootstrap'
import axios from 'axios'
import { useParams, Link } from 'react-router-dom'
import PORTNUMBER from '../../../api/api'
import { FaEllipsisV } from 'react-icons/fa';
import { FcGallery } from 'react-icons/fc';
import IMAGE_HOST from '../../../api/api'
import { toast } from 'react-toastify'
import { RiLoader2Fill } from 'react-icons/ri';

import moment from 'moment'

import { useForm } from 'react-hook-form';
import { Helmet } from 'react-helmet'
import PaginationCount from '../../../common/pagination/pagination'

const PublicPost = () => {
    const { id } = useParams()
    const PORT = PORTNUMBER.PORTNUMBER
    const TOKEN = localStorage.getItem("token")
    const UserTokenId = localStorage.getItem("UserTokenId")
    const Username = localStorage.getItem("username")
    const [Count, setCount] = useState(1);
    const HOST_PORT = IMAGE_HOST.IMAGE_HOST

    const [activeShow, setActiveShow] = useState(false);
    const [statusId, setStatusId] = useState();
    const [postIdEdit, setPostIdEdit] = useState();
    const [postById, setPostById] = useState(["not"])
    const [postIdEditVisible, setPostIdEditVisible] = useState();

    const [indexPage, setIndexPage] = useState(1);
    const [activePage, setActivePage] = useState()
    const [loading, setLoading] = useState(false);

    const [post, setPost] = useState(["not"])
    const [userIdData, setUserIdData] = useState(["not"])

    const { register, handleSubmit, reset } = useForm();
    const [state, setState] = useState({
        onMind: "",
        image: "",
        imageFiles: [],

    })

    const activeHandleClose = () => setActiveShow(false);

    const activeHandleShow = (id) => {
        setActiveShow(true);
        setStatusId(id)
    }


    const activeOrder = async () => {
        await axios.post(`${PORT}/user/post/delete`, { postId: statusId, }, { headers: { authorization: `Bearer ${TOKEN}` } }).then((res, err) => {
            LoadPost()
            // toast.success(`You succesfully  status`)
            activeHandleClose()
        })
    }

    const ScrollFromTop = () => {
        window.scrollTo(0, 600)
    }

    const LoadPostById = useCallback(async (postIdEdit) => {
        setPostIdEdit(postIdEdit)
        await axios.post(`${PORT}/user/post/detail`, { postId: postIdEdit }, { headers: { authorization: `Bearer ${TOKEN}` } })
            .then((res) => {
                setPostById(res.data.data)
                setPostIdEditVisible(true)

                // setCount(res.data.data.totalPage);

            })
    }, [PORT, TOKEN, postIdEdit])



    const LoadPost = useCallback(async (pageNumber) => {
        setLoading(true)
        await axios.post(`${PORT}/user/post/all`, { page: pageNumber }, { headers: { authorization: `Bearer ${TOKEN}` } })
            .then((res) => {
                setLoading(false)
                // setPost(res.data.data.postDetails)
                setCount(res.data.data.totalPage);
                setPost(res.data.data.postDetails)

            })
    }, [PORT, TOKEN])
    const LoadProfile = useCallback(async () => {
        await axios.post(`${PORT}/user/profile`, { userId: UserTokenId }, { headers: { authorization: `Bearer ${TOKEN}` } })
            .then((res) => {
                setUserIdData(res.data.data)
            })
    }, [PORT, TOKEN, id])

    const handelCommittee = (evt) => {
        let value = evt.target.value;
        setState({
            [evt.target.name]: value
        })
    }
    const onImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            let reader = new FileReader();

            reader.onload = (e) => {
                setState({ image: e.target.result });


            };
            reader.readAsDataURL(event.target.files[0]);

        }
    }

    const handlePageChange = (pageNumber) => {
        ScrollFromTop()
        LoadPost(pageNumber);
        setIndexPage(pageNumber)
        setActivePage(pageNumber);
        setLoading(false);
    }

    useEffect(() => {
        LoadPost()
        LoadProfile()
        return () => {
            setState({ image: "" });
        }
    }, [LoadProfile, LoadPost])

    const onSubmit = (data) => {
        const imageFiles = document.getElementById('postImage').files[0];
        const fs = new FormData();
        fs.append('title', Username);
        fs.append('image', imageFiles);
        fs.append('description', data.description);

        axios.post(`${PORT}/user/post/create`, fs, { headers: { authorization: `Bearer ${TOKEN}` } })
            .then((res) => {
                LoadPost()
                reset()
                setState({ image: "" });
            })
    }

    const onSubmitUpdate = (data) => {
        setPostIdEditVisible(false)


        const imageFiles = document.getElementById('postImage').files.length;
        const fs = new FormData();
        fs.append('title', Username);
        fs.append('postId', postIdEdit);
        // fs.append('description', data.description);
        for (const [key] of Object.entries(data)) {
            data[key] = (data[key] === undefined || data[key] === null || data[key] === "") ? postById[key] : data[key];
            fs.append(key, data[key]);
        }
        if (imageFiles > 0) {
            const File = document.getElementById('postImage').files[0];
            fs.append("image", File);
        }

        axios.post(`${PORT}/user/post/update`, fs, { headers: { authorization: `Bearer ${TOKEN}` } })
            .then((res) => {

                LoadPost()
                reset()
                setState({ image: "" });
                toast.success("Post created success")
            })
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>Koli Samaj Post Page</title>
                <meta name="description" content="Koli samaj posts" />
                <meta name="keywords" content="koli samaj news, koli smaj posts" />
                <meta name="author" content="mahendra koli" />
                <link rel="canonical" href="https://www.kolisamajvikashsanstha.com/publicPost" />
            </Helmet>
            <section className="committee public-post-top">
                <div className="container">
                    <div className="committee-profile">
                        <div className="cover-image" style={{ backgroundImage: `url(${ImageObj.SansthaTitle})` }}>
                            {/* <img aria-hidden="true" src={ImageObj.SansthaTitle} alt="Committee" /> */}

                        </div>
                        <div className="profile public-profile mt-0">
                            <div className="row">
                                {/* <div className="col-md-4 col-sm-5">

                                <div className="profile-img">
                                    <img aria-hidden="true" src={ImageObj.Teacher1} alt=""  />
                                </div>
                                <div className="user-info border radius">
                                    <ul>
                                        {userIdData.name ?
                                            <li>
                                                <span className="info-icon"><img alt="" aria-hidden="true" src={ImageObj.WorkIcon} /></span>
                                                <p>Name: <span>{userIdData.name}</span></p>
                                            </li> : ""
                                        }
                                        <li>
                                            <span className="info-icon"><img alt="" aria-hidden="true" src={ImageObj.WorkIcon} /></span>
                                            <p>{userIdData.email} </p>
                                        </li>
                                        <li>
                                            <span className="info-icon"><img alt="" aria-hidden="true" src={ImageObj.StudiesIcon} /></span>
                                            <p> Studied at <span> Raj rishi college alwar</span></p>
                                        </li>
                                        <li>
                                            <span className="info-icon"><img alt="" aria-hidden="true" src={ImageObj.StudiesIcon} /></span>
                                            <p>Went to new national sr sec school,chikani alwar</p>
                                        </li>
                                        <li>
                                            <span className="info-icon"><img alt="" aria-hidden="true" src={ImageObj.LivesInIcon} /></span>
                                            <p> Lives in <span> Jaipur, Rajasthan</span></p>
                                        </li>
                                        <li>
                                            <span className="info-icon"><img alt="" aria-hidden="true" src={ImageObj.LocationIcon} /></span>
                                            <p> From <span> Alwar City, Rajasthan, India</span></p>
                                        </li>
                                    </ul>
                                </div>


                            </div> */}
                                <div className="col-md-12 col-sm-12">
                                    <div className="main-profile-tab public-post">

                                        <div className="profile-tab tab-design">
                                            <Tabs defaultActiveKey="home" id="uncontrolled-tab-example">
                                                <Tab eventKey="home" title="Home" >
                                                    {
                                                        UserTokenId ?
                                                            <form onSubmit={!postIdEditVisible ? handleSubmit(onSubmit) : handleSubmit(onSubmitUpdate)}>
                                                                <div className="status-field">
                                                                    <div className={userIdData.post_status === true ? "status-box border radius" : "d-none"}>
                                                                        <div className="status-area second">
                                                                            <div className="status-profile">
                                                                                {

                                                                                    userIdData.image ?
                                                                                        <img src={`${HOST_PORT}/${userIdData.image}`} alt="" /> :
                                                                                        <img aria-hidden="true" src={ImageObj.Teacher1} alt="" />
                                                                                }

                                                                            </div>
                                                                            <div className="status-textarea">
                                                                                <textarea defaultValue={postIdEditVisible ? postById.description : ""} placeholder={`Whats On Your Mind, ${Username} ?`} className="form-control" {...register('description', { required: false })} required={postIdEditVisible ? "false" : "true"}></textarea>
                                                                            </div>
                                                                        </div>
                                                                        <div className="uploded-img theme-scroll">
                                                                            {
                                                                                !postIdEditVisible || state.image ?
                                                                                    state.image ?
                                                                                        <img className="post1" alt="" aria-hidden="true"
                                                                                            src={state.image}
                                                                                        /> : "" :
                                                                                    postById.image ?
                                                                                        <img src={`${HOST_PORT}/${postById.image}`} className="post" alt="" /> : ""
                                                                            }
                                                                        </div>


                                                                        <div className="upload-file d-flex border-top">

                                                                            <div className="file-icon">
                                                                                {
                                                                                    userIdData.post_status ?
                                                                                        <>
                                                                                            <FcGallery />
                                                                                            <input onChange={onImageChange} type="file" class="form-control" id="postImage" />
                                                                                        </>
                                                                                        : ""
                                                                                }
                                                                            </div>
                                                                            <div className="file-submit">
                                                                                <div className="brown-button sbm-btn text-right">
                                                                                    <button type="submit" className="btn">Post</button>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </form> : ""
                                                    }

                                                    {
                                                        !loading ? post && post.length ? post.map((postdata, i) => (
                                                            <div className="status-field" key={i + 1}>
                                                                <div className="status-box border radius">
                                                                    <div className="post-header">
                                                                        <Link to={`/profile/${postdata && postdata.user !== undefined ? postdata.user["_id"] : "N/A"}`} className="status-area">
                                                                            <div className="status-profile">
                                                                                {
                                                                                    postdata.userImage ?
                                                                                        <img src={`${HOST_PORT}/${postdata.userImage}`} alt="" /> :
                                                                                        <img aria-hidden="true" src={ImageObj.Teacher1} alt="" />
                                                                                }


                                                                            </div>
                                                                            <div className="profile-post">
                                                                                <h3>{postdata && postdata.user !== undefined ? postdata.user["name"] : "N/A"}</h3>
                                                                                <p>{postdata && postdata.user !== undefined ? postdata.user["type"] : "N/A"}</p>
                                                                            </div>
                                                                        </Link>

                                                                        <div className="right-post-box">
                                                                            <span className="date-box">{moment(postdata.createdAt).format("DD/MM/YYYY")}</span>
                                                                            {

                                                                                (UserTokenId === String(postdata && postdata.user !== undefined ? postdata.user["_id"] : "N/A")) ?

                                                                                    <div className="dropdown-box">

                                                                                        <span className="dropdown-side">
                                                                                            <Dropdown>
                                                                                                <Dropdown.Toggle className="btn" id="dropdown-basic">
                                                                                                    <FaEllipsisV />
                                                                                                </Dropdown.Toggle>

                                                                                                <Dropdown.Menu>
                                                                                                    <Dropdown.Item onClick={() => activeHandleShow(postdata._id)}>Delete</Dropdown.Item>
                                                                                                    <Dropdown.Item onClick={() => LoadPostById(postdata._id)}>Edit</Dropdown.Item>



                                                                                                </Dropdown.Menu>

                                                                                            </Dropdown>

                                                                                        </span>
                                                                                    </div> : ""
                                                                            }

                                                                        </div>


                                                                    </div>
                                                                    <div className="status-textarea">
                                                                        <p>{postdata.description}</p>
                                                                        <img src={`${HOST_PORT}/${postdata.image}`} alt="" />
                                                                    </div>


                                                                </div>
                                                            </div>
                                                        )) : <span className="no-data text-center">No Post Found</span> :
                                                            <div className="loader-wrapper absolute-sec">
                                                                <div className="loader">
                                                                    <div className="loading-svg">
                                                                        <RiLoader2Fill />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                    }
                                                   
                                                    <PaginationCount count={Count} itemperpage={50} activepage={activePage}
                                                        handlepagechange={handlePageChange} />

                                                </Tab>
                                                <Tab disabled eventKey="profile" title="Profile">
                                                    frfefrff
                                                </Tab>
                                                <Tab disabled eventKey="contact" title="Contact">
                                                    frfefrff
                                                </Tab>
                                            </Tabs>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* active modal */}
                <Modal className="status-modal" animation={false} show={activeShow} onHide={activeHandleClose}>

                    <Modal.Body>Are you sure you want to change the order status to    ?</Modal.Body>
                    <Modal.Footer>
                        <div className="theme-btn btn-group-right text-right">
                            <Button variant="secondary" onClick={activeOrder}>
                                Yes
                            </Button>
                            <Button variant="primary" onClick={activeHandleClose}>
                                No
                            </Button>
                        </div>

                    </Modal.Footer>
                </Modal>
                {/* active modal end */}
            </section>
        </React.Fragment>

    )
}


export default PublicPost;