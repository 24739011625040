import {combineReducers} from 'redux'
import toggleReducers from './toggleReducers'
import toastReducers from './toastReducer'
import toastErrReducers from './toastErrReducers'
import eventNameReducers from './eventNameReducers'
import userIdPostReducer from './userIdPostReducer'
import { authHocValueReducer,loginModalReducer,signUpModalReducer, userLoginReducer,scrollReducer } from './reducers';



  const rootReducer = combineReducers({
    toggleReducers,
    toastReducers,
    toastErrReducers,
    eventNameReducers,
    userIdPostReducer,
    authHocValueReducer,
    loginModalReducer,
    signUpModalReducer,
    userLoginReducer,
    scrollReducer
})

export default rootReducer