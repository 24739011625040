import React, { useEffect } from 'react';

import '../App.scss';
import 'bootstrap/dist/css/bootstrap.min.css'

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import '../assets/css/index.scss'
import '../assets/css/responsive.scss'
import Routes from '../routes/routes/index';
import { toast, ToastContainer } from 'react-toastify';
import axios from 'axios';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import 'aos/dist/aos.css';
//h import AOS from 'aos';
import { useState } from 'react';
import { userLoginTrue } from '../redux/actions/action';
import { useDispatch } from 'react-redux';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';


// Create a QueryClient instance
const queryClient = new QueryClient();

function App() {
const dispatch = useDispatch()
const [netwokError,setNetwokError] = useState()

useEffect(()=>{
  toast(netwokError)
},[netwokError])
  useEffect(() => {


    // AOS.init();

    // Add a request interceptor
    axios.interceptors.request.use(function (config) {
      // Do something before request is sent
      return config;
    }, function (error) {
      // Do something with request error
      return Promise.reject(error);
    });

    // Add a response interceptor
    axios.interceptors.response.use(function (response) {
      // console.log("error", response)
      // switch (response.data.status) {
      //   case 400:
      //     toast.dismiss()
      //     toast('Bad Request.');

      //     break;

      //     case 401:
      //       dispatch(userLoginTrue(false))

      //       localStorage.removeItem("RefId");
      //       localStorage.removeItem("token");
      //       localStorage.removeItem("username");
      //       localStorage.removeItem("UserTokenId");
      //       break;
       
      //   case 500:
      //     toast.error('Seems like something went wrong!', 'Error');
      //     break;
      //   case 504:
      //     toast.error('Sorry, could not access the external resource to refine the data for your request, please try again later!', 'Error');
      //     break;
      //   default:

      // }
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    }, function (error) {

      if (error.message === "Network Error") {

       
        setNetwokError("Network Error")
       
        
      }



      return Promise.reject(error);
    });
  }, [])


  return (
    <QueryClientProvider client={queryClient}>
    <div className="App new-home">
      <Routes />
      <ToastContainer />
    </div>
    </QueryClientProvider>
  );
}

export default App;
