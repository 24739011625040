import React, { useEffect } from 'react'
import { ImageObj } from '../../../assets/images/images';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

const LaxmiBai = () => {
    const { t } = useTranslation();

    useEffect(() => {
        document.body.classList.add("choclate-bg");
        return (() => {
            document.body.classList.remove("choclate-bg");

        })
    }, [])


    return (
        <React.Fragment>
        <Helmet>
            <title>halkari Bai Koli History</title>
            <meta name="description" content="Jhalkari Bai (22 November 1830 – 4 April 1857) was the commander of the women's wing Durga Dal, in the regular army of Rani Lakshmibai of Jhansi.[1] She was also a lookalike of Lakshmibai, so she disguised herself as a queen to mislead the enemy. She also fought. Even in her last days, she was caught by the British while fighting in the guise of a queen and the queen got an opportunity to escape from the fort" />
            <meta name="keywords" content="Jhalkari Bai, Durga Dal, Bundelkhand, Sadovar Singh, Jamuna Devi," />
            <meta name="author" content="mahendra koli" />
            <link rel="canonical" href="https://www.kolisamajvikashsanstha.com/jhalkariBai" />
        </Helmet>
        <div className="container padding-mob-0">
            <div className="history-show-top bg" style={{ backgroundImage: `url(${ImageObj.ShowHisBg2})` }}>
                <div className="show-history-inner">
                    <div className="koli-history show-his">
                       <h2>{t('HisJhalkari.titleOne')}</h2>
                        <p>{t('HisJhalkari.summeryOne')}</p>
                        <h2>{t('HisJhalkari.titleTwo')}</h2>
                        <p>{t('HisJhalkari.summeryTwo')}</p>
                        <h2>{t('HisJhalkari.titleThree')}</h2>
                        <p>{t('HisJhalkari.summeryThree')}</p>
                        <h2>{t('HisJhalkari.titleFour')}</h2>
                        <p>{t('HisJhalkari.summeryFour')}</p>
                        <p style={{"font-weight":"normal"}}>{t('HisJhalkari.summeryFive')}<span className="d-block"></span>
                        {t('HisJhalkari.summerySix')}</p>
                        <p>{t('HisJhalkari.summerySeven')}</p>
                        <p style={{"font-weight":"normal"}}>{t('HisJhalkari.summeryEight')}<span className="d-block"></span>
                        {t('HisJhalkari.summeryNine')}<span className="d-block"></span>
                        {t('HisJhalkari.summeryTen')}</p>

                    </div>
                </div>
            </div>
        </div>
        </React.Fragment>
    )
}

export default LaxmiBai;