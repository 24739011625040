
// const PORTNUMBER = "http://localhost:4500/v1";
// const PORTNUMBER_ADMIN = "http://localhost:4500/v1/admin";
// const IMAGE_HOST = "http://localhost:4500/images";

const IMAGE_HOST = "https://www.kolisamajvikashsanstha.com/backend/images";
const PORTNUMBER = "https://www.kolisamajvikashsanstha.com/backend/v1";
const PORTNUMBER_ADMIN = "https://www.kolisamajvikashsanstha.com/backend/v1/admin";

// const PORTNUMBER = "https://wine.betademo.net/backend/admin";



const API = {
  PORTNUMBER:`${PORTNUMBER}`,
  PORTNUMBER_ADMIN:`${PORTNUMBER_ADMIN}`,
  IMAGE_HOST:`${IMAGE_HOST}`,


  LOGIN_API:`${PORTNUMBER}/user/login`,
  SENDFORGOTPASSMAIL_API:`${PORTNUMBER}/user/forgot/password`,
  CHANGEPASSWORD_API:`${PORTNUMBER}/user/change/password`,
  VERIFY_OTP:`${PORTNUMBER}/user/verify/otp`,
  RESETPASSWORD:`${PORTNUMBER}/user/reset/password`,

  // admin api
  ADMIN_SIGNUP_API:`${PORTNUMBER}/admin/create`,
  ADMIN_LOGIN_API:`${PORTNUMBER}/admin/login`,
  FORGOT_PASSWORD_ADMIN_API:`${PORTNUMBER}/admin/forgot/password`,
  VERIFY_OTP_ADMIN:`${PORTNUMBER}/admin/verify/otp`,
  RESETPASSWORD_API_ADMIN:`${PORTNUMBER}/admin/reset/password`,
  CHANGEPASSWORD_API_ADMIN:`${PORTNUMBER}/admin/change/password`,






//   RESETPASSWORD_API:`${PORTNUMBER}/forgetPassword/updatePassword`
}
export default API;