import React from 'react'
import { Button, Spinner } from 'react-bootstrap'




const ButtonLoader = ({btndisabled, loader}) => {
    return (
        <React.Fragment>

            {
                loader === false ?
                    <button type="submit" disabled={btndisabled} className="btn mt-1 outline">
                        Submit
                    </button> :

                    <Button className='mt-1' disabled>
                        <Spinner
                            as="span"
                            animation="grow"
                            size="sm"
                            className='me-2'
                            role="status"
                            aria-hidden="true"
                        />
                        Please Wait...
                    </Button>
            }


        </React.Fragment>

    )
}


export default ButtonLoader