import React from "react";
import Slider from "react-slick";

import { ImageObj } from '../../../../assets/images/images'
import ProgressBar from "@ramonak/react-progress-bar";
import { useTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";

import { centerSlider, benifited} from '../../../sliders/sliders'



const Donation = () => {

    const { t } = useTranslation();



    return (

        <React.Fragment>
          <Helmet>
                <title>Help Koli Samaj People</title>
                <meta name="description" content="People who have the passion to do good work in the Koli society should come forward to help the poor and helpless. Giving knowledge can help the poor and needy to stand on their feet. Education is the beginning of getting out of the difficulties of life." />
                <meta name="keywords" content="koli help, koli poor people, koli study help" />
                <meta name="author" content="mahendra koli" />
                <link rel="canonical" href="https://www.kolisamajvikashsanstha.com/help" />
            </Helmet>
       

            {/* <section className="">
                <Slider {...settings}>
                    <div>
                        <img aria-hidden="true" src={ImageObj.Hands} />
                    </div>
                    <div>
                        <img aria-hidden="true" src={ImageObj.Poor} />
                    </div>
                    <div>
                        <img aria-hidden="true" src={ImageObj.Study} />
                    </div>
                </Slider>
            </section>
            <section className="donation-sec">
                <div className="container">
                    <div className="donation-box">
                        <div className="row">

                            <div className="col-md-4 col-sm-6">
                                <div className="classes-top">
                                    <div className="group-classes">
                                        <div className="group-top">
                                            <h4>Group Classes</h4>
                                            <h5>(All Inclusive)</h5>
                                        </div>
                                        <div className="group-mid">
                                            <h2 className="circle" />
                                            <p>Enrol into best youth fitness classes in DelhiNCR. You Get in:</p>
                                            <h3><i className="fas fa-rupee-sign" aria-hidden="true" />800</h3>
                                        </div>
                                        <div className="group-bottom">
                                            <p><i className="fas fa-check-circle mr-1" aria-hidden="true" />General Physical Literacy and Sports Readiness training</p>
                                            <p><i className="fas fa-check-circle mr-1" aria-hidden="true" />5 Sessions with Physiotherapist</p>
                                        </div>
                                        <div className="button-group">
                                            <button className="btn">Choose plan</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}



            {/* banner section start */}
            <section className="banner">
                <div className="container">
                    <div className="shadow-blue">
                    </div>
                    <div className="row">
                        <div className="col-md-6 col-sm-4">
                        </div>
                        <div className="col-md-6 col-sm-8">
                            <div className="shadow-content text-center" data-aos="zoom-in">
                                <h2>{t('Donation.Home.Heading')}</h2>
                                <p>{t('Donation.Home.Description')}</p>
                                <div className="brown-button">
                                    <button className="btn white rounded">Get a Quote</button>
                                </div>
                            </div>
                            {/* <div className="banner-icon">
                                <div className="box">
                                    <div className="box-img">
                                        <img aria-hidden="true" src="assets/images/home-icon.png" alt=""  />
                                    </div>
                                    <div className="box-con">
                                        <h4>Homeowners</h4>
                                        <h4>Insurance</h4>
                                    </div>
                                </div>
                                <div className="box">
                                    <div className="box-img">
                                        <img aria-hidden="true" src="assets/images/car-icon.png" alt=""  />
                                    </div>
                                    <div className="box-con">
                                        <h4>Auto</h4>
                                        <h4>Insurance</h4>
                                    </div>
                                </div>
                                <div className="box">
                                    <div className="box-img">
                                        <img aria-hidden="true" src="assets/images/buil-icon.png" alt=""  />
                                    </div>
                                    <div className="box-con">
                                        <h4>Commercial</h4>
                                        <h4>Insurance</h4>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </section>
            {/* banner section end */}
            {/* couses donation start */}
            <section className="causes-donation">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-4" data-aos="fade-up" data-aos-duration="1000">
                            <div className="dobation-card">
                                <div className="box-img">
                                    <img aria-hidden="true" src={ImageObj.Poor} alt="sydney-full" />
                                </div>
                                <div className="top-pic-content-box">
                                    <div className="top-pic-content enjoy-con">
                                        <h3>Fund for poor education</h3>
                                        <div className="process">
                                            <ProgressBar completed={60}  bgColor="#a78b60" borderRadius="0" />
                                        </div>
                                        <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor aeneanssa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. </p>
                                    </div>

                                    <div className="brown-button">
                                        <button className="btn">DONATE NOW</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-4" data-aos="fade-down" data-aos-duration="1000">
                            <div className="dobation-card">
                                <div className="box-img">
                                    <img aria-hidden="true" src={ImageObj.Poor} alt="sydney-full" />
                                </div>
                                <div className="top-pic-content-box">
                                    <div className="top-pic-content enjoy-con">
                                        <h3>Help for homeless people</h3>
                                        <div className="process">
                                            <ProgressBar completed={60}  bgColor="#a78b60" borderRadius="0" />
                                        </div>
                                        <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor aeneanssa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. </p>
                                    </div>
                                    <div className="brown-button">
                                        <button className="btn">DONATE NOW</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-4" data-aos="fade-up" data-aos-duration="1000">
                            <div className="dobation-card">
                                <div className="box-img">
                                    <img aria-hidden="true" src={ImageObj.Poor} alt="sydney-full" />
                                </div>
                                <div className="top-pic-content-box">
                                    <div className="top-pic-content enjoy-con">
                                        <h3>Fund for helth camp</h3>
                                        <div className="process">
                                            <ProgressBar completed={60}  bgColor="#a78b60" borderRadius="0" />
                                        </div>
                                        <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor aeneanssa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. </p>
                                    </div>
                                    <div className="brown-button">
                                        <button className="btn">DONATE NOW</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            {/* couses donation end */}

            {/* providing section start */}
            {/* <section className="providing">
                <div className="container">
                    <div className="providing-inner">
                        <div className="providing-head text-center">
                            <h3>Providing Florida Homeowners Peace of Mind Since 1999 </h3>
                            <p>When it comes to buying homeowners insurance you have a lot of </p>
                        </div>
                        <div className="providing-boxes">
                            <div className="row">
                                <div className="col-md-4">
                                    <div className="pro-box">
                                        <div className="img-top">
                                            <div className="box-img">
                                                <img aria-hidden="true" src="assets/images/home2.png" alt=""  />
                                            </div>
                                        </div>
                                        <div className="box-con text-center">
                                            <h3>Health</h3>
                                            <p>Your home is typically the largest investment you’ll ever make...
                          Read More</p>
                                            <a>Read More</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="pro-box">
                                        <div className="img-top">
                                            <div className="box-img">
                                                <img aria-hidden="true" src="assets/images/building2.png" alt=""  />
                                            </div>
                                        </div>
                                        <div className="box-con text-center">
                                            <h3> Education</h3>
                                            <p>Your home is typically the largest investment you’ll ever make...
                          Read More</p>
                                            <a>Read More</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="pro-box">
                                        <div className="img-top">
                                            <div className="box-img">
                                                <img aria-hidden="true" src="assets/images/sun.png" alt=""  />
                                            </div>
                                        </div>
                                        <div className="box-con text-center">
                                            <h3>Support</h3>
                                            <p>Your home is typically the largest investment you’ll ever make...
                          Read More</p>
                                            <a>Read More</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            {/* providing section end */}


            {/* Benefited section start  */}
            <section className="benifited-people">
                <div className="benifi-slider" data-aos="fade-left"
    
                    data-aos-duration="500">
                    <Slider {...benifited}>
                        <div className="item">
                            <div className="resume">

                                <div className="row">
                                    <div className="col-md-5">
                                        <div className="image-points">
                                            <div className="right-image">
                                                <img aria-hidden="true" src={ImageObj.Poor} alt="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-7">
                                        <div className="home-left new">
                                            <div className="plan">
                                                <div className="head">
                                                    <p>लाभान्वित परिवार</p>
                                                </div>
                                                <h2>अलवर का ये परिवार जो हमारी योजना से लाभान्वित हे</h2>
                                            </div>
                                            <div className="name-adress">
                                                <div className="head">
                                                    <p>नाम व पता</p>
                                                </div>
                                                <div className="points">
                                                    <ul>
                                                        <li>Name</li>
                                                        <li>User Name</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="problem">
                                                <div className="head">
                                                    <p>परिवार की समस्या</p>
                                                </div>
                                                <p>Building a network by association and working with accomplished executives from various backgrounds, industries, and culture. Build trusting relationships with a diverse group of peers who can provide wide-ranging insights into
                                                    <span>Business Challenges</span> and career decisions.
                                                </p>
                                            </div>


                                        </div>
                                        <div className="brown-button">
                                            <button className="btn yellow">Learn More</button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="item">
                            <div className="resume">

                                <div className="row">
                                    <div className="col-md-5" >
                                        <div className="image-points">
                                            <div className="right-image">
                                                <img aria-hidden="true" src={ImageObj.Hands} alt="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-7">
                                        <div className="home-left new">
                                            <div className="plan">
                                                <div className="head">
                                                    <p>लाभान्वित परिवार</p>
                                                </div>
                                                <h2>अलवर का ये परिवार जो हमारी योजना से लाभान्वित हे</h2>
                                            </div>
                                            <div className="name-adress">
                                                <div className="head">
                                                    <p>नाम व पता</p>
                                                </div>
                                                <div className="points">
                                                    <ul>
                                                        <li>Name</li>
                                                        <li>User Name</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="problem">
                                                <div className="head">
                                                    <p>परिवार की समस्या</p>
                                                </div>
                                                <p>Building a network by association and working with accomplished executives from various backgrounds, industries, and culture. Build trusting relationships with a diverse group of peers who can provide wide-ranging insights into
                                                    <span>Business Challenges</span> and career decisions.
                                                </p>
                                            </div>


                                        </div>
                                        <div className="brown-button">
                                            <button className="btn yellow">Learn More</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Slider>
                </div>
            </section>
            {/* Benefited section end  */}



            <section className="testimonials-top donation">
                <Slider {...centerSlider}>
                    <div className="item">
                        <div className="testimonials-main">
                            <div className="testimonials">
                                <div className="test-img">
                                    <img aria-hidden="true" src={ImageObj.Teacher1} alt="" />
                                </div>
                                <div className="test-con text-center">
                                    <h5>esse Rodriguez</h5>
                                    <p>In the past 7 years I have referred hundreds of clients to DFI222222 Loan Officer, Paramount Residential Mortgage, Miami, F</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="item">
                        <div className="testimonials-main">
                            <div className="testimonials">
                                <div className="test-img">
                                    <img aria-hidden="true" src={ImageObj.Teacher1} alt="" />
                                </div>
                                <div className="test-con text-center">
                                    <h5>esse Rodriguez</h5>
                                    <p>In the past 7 years I have referred hundreds of clients to DFI222222 Loan Officer, Paramount Residential Mortgage, Miami, F</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="item">
                        <div className="testimonials-main">
                            <div className="testimonials">
                                <div className="test-img">
                                    <img aria-hidden="true" src={ImageObj.Teacher1} alt="" />
                                </div>
                                <div className="test-con text-center">
                                    <h5>esse Rodriguez</h5>
                                    <p>In the past 7 years I have referred hundreds of clients to DFI222222 Loan Officer, Paramount Residential Mortgage, Miami, F</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Slider>
            </section>
            <section className="insurance reverse">
                <div className="row">
                    <div className="col-md-6" data-aos="fade-right">
                        <div className="insur-box">
                            <div className="box-con">
                                <h3>{t('HelpSection.Box_1.Head')}</h3>
                                <p>{t('HelpSection.Box_1.Description')}</p>
                                <div className="brown-button">
                                    <button className="btn">Get a Quote</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 p-0" data-aos="fade-left">
                        <div className="box-img">
                            <img aria-hidden="true" src={ImageObj.Study} alt="" />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 p-0" data-aos="fade-right">
                        <div className="box-img">
                            <img aria-hidden="true" src={ImageObj.Hands} alt="" />
                        </div>
                    </div>
                    <div className="col-md-6" data-aos="fade-left">
                        <div className="insur-box">
                            <div className="box-con">
                                <h3>{t('HelpSection.Box_2.Head')}</h3>
                                <p>{t('HelpSection.Box_2.Description')}</p>
                                <div className="brown-button">
                                    <button className="btn">Get a Quote</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row" >
                    <div className="col-md-6" data-aos="fade-right">
                        <div className="insur-box">
                            <div className="box-con">
                                <h3>{t('HelpSection.Box_3.Head')}</h3>
                                <p>{t('HelpSection.Box_3.Description')}</p>
                                <div className="brown-button">
                                    <button className="btn">Get a Quote</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 p-0" data-aos="fade-left">
                        <div className="box-img">
                            <img aria-hidden="true" src={ImageObj.Poor} alt="" />
                        </div>
                    </div>

                </div>


            </section>

        </React.Fragment>
    )
}

export default Donation;


// export default function Donation() {
//     var settings = {
//       dots: true,
//       infinite: true,
//       speed: 500,
//       slidesToShow: 1,
//       slidesToScroll: 1
//     };
//     return (
//       <Slider {...settings}>
//         <div>
//           <h3>1</h3>
//         </div>
//         <div>
//           <h3>2</h3>
//         </div>
//         <div>
//           <h3>3</h3>
//         </div>
//         <div>
//           <h3>4</h3>
//         </div>
//         <div>
//           <h3>5</h3>
//         </div>
//         <div>
//           <h3>6</h3>
//         </div>
//       </Slider>
//     );
//   }
