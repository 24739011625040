
import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import moment from 'moment'
import { ToastContainer, toast, } from "react-toastify";
import { useForm, Controller } from 'react-hook-form';
import { GiSwordsEmblem } from 'react-icons/gi';


// icons
import { FaSearch } from 'react-icons/fa';
import { FaEye, FaBan, FaRegStar } from 'react-icons/fa';
import { AiOutlineCheckCircle } from 'react-icons/ai';

import PORTNUMBER from '../../../../api/api'




// Import Datepicker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from 'react-router-dom'

// react bootstrap
import { Modal, Button } from 'react-bootstrap'


const GovtServantManagement = () => {
    const PORT = PORTNUMBER.PORTNUMBER
    const Token = localStorage.getItem("token")


    // modal active
    const [activeShow, setActiveShow] = useState(false);
    const [inActiveShow, setInActiveShow] = useState(false);
    const [statusId, setStatusId] = useState();
    // const [Loading, setLoading] = useState(false);


    const [users, setUser] = useState([]);
    const [Count, setCount] = useState([]);
    const [loading, setLoading] = useState(false);


    const [userPerPage] = useState(50);

    const [searchTerm, setSearchTerm] = useState("");

    const [filterData, setFilterData] = useState([]);
    const [filterCount, setFilterCount] = useState([]);

    const [filterVisibility, setFilterVisibility] = useState(true);



    const { register, control, handleSubmit, reset } = useForm();



  


    const activeHandleClose = () => setActiveShow(false);
    const activeHandleShow = (id) => {
        setActiveShow(true);
        setStatusId(id)
    }

    const inActiveHandleClose = () => setInActiveShow(false);
    const inActiveHandleShow = (id) => {
        setInActiveShow(true);
        setStatusId(id)

    }

    const activeUser = async () => {
        await axios.post(`${PORT}/user/changeStatus`, { userId: statusId, status: "active" }, { headers: { authorization: `Bearer ${Token}` } }).then((res, err) => {
            LoadUser()
            toast.success("User is activated successfully.")
            activeHandleClose()
        })
    }
    const inActiveUser = async () => {
        await axios.post(`${PORT}/user/changeStatus`, { userId: statusId, status: "inactive" }, { headers: { authorization: `Bearer ${Token}` } }).then((res, err) => {
            LoadUser()
            toast.success("User is inactivated successfully.")
            inActiveHandleClose()
        })
    }
    const LoadUser = useCallback(async(pageNumber) =>{

        setLoading(false);
        await axios.post(`${PORT}/user/details`, { page: pageNumber }, { headers: { authorization: `Bearer ${Token}` } })
            .then((res) => {
                setUser(res.data.data.userData);
                setCount(res.data.data.userCount);
                setLoading(true);
                setFilterVisibility(true)
            })


       reset()

    },[PORT, Token, reset, ],[users, Count])



    useEffect(() => {

        LoadUser()
    },[LoadUser])

      

    const pageNumbers = [];

   
    if(filterVisibility === true){
        for (let i = 1; i <= Math.ceil( Count / userPerPage); i++) {
            pageNumbers.push(i);
        }
    }else{
        for (let i = 1; i <= Math.ceil( filterCount / userPerPage); i++) {
            pageNumbers.push(i);
        }
    }
  
   

    // Change page


      const paginate = async (pageNumber) => {
        sendPage(pageNumber)
        LoadUser(pageNumber)
        setLoading(false);


    }


   const sendPage=(pageNumber)=>{
    axios.post(`${PORT}/user/filterdData`,{ page: pageNumber }, { headers: { authorization: `Bearer ${Token}` } })
   }

    const onSubmit = async (data) => {
        data.startDate = (!data.startDate || data.startDate === null || data.startDate === undefined) ? "" : data.startDate;
        data.endDate = (!data.endDate || data.endDate === null || data.endDate === undefined) ? "" : data.endDate;
        setLoading(false);
        // sendPage()
       await axios.post(`${PORT}/user/filterdData`, data, { headers: { authorization: `Bearer ${Token}` } })
            .then((res) => {
                setFilterCount(res.data.data.userCount)
                setFilterData(res.data.data.userData)
                setLoading(true);
                setFilterVisibility(false)

            })
      
    }


    useEffect(() => {
        document.body.classList.add('remove-home-header', 'admin-dashboard');
        return () => {
          document.body.classList.remove('remove-home-header', 'admin-dashboard');
    
        }
      }, [])



    return (
        <div className="tables-field">
            <div className="project-table">
                <div className="col-12">
                    <div className="row">
                        <div className="col-6">
                            <div className="main-head">
                                <h4>Government Servant</h4>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="theme-btn top-head text-right">
                                <Link to="/addNewUser" className="btn">Add New Govt User</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="table-order-box shadow">
                    <div className="tab-head">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="row">
                                <div className="col-lg-4">
                                    <div className="form-group">
                                        <input type="text" {...register('name', { required: false })} className="form-control" placeholder="Name" />
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="form-group">
                                        <input type="email" {...register('email', { required: false })} className="form-control" placeholder="Email" />
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="form-group">
                                        <input type="text" {...register('mobile', { required: false })} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Mobile" />
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="form-group">
                                        <select className="form-select form-control" {...register('status', { required: false })} aria-label="Default select example">
                                            <option className="disabled-value d-none"   value=""  >All Status</option>
                                            <option value="active">Active</option>
                                            <option value="inactive">Inactive</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="form-group before-con">
                                      
                                        <Controller
                                            control={control}
                                            name="startDate"
                                            value=""
                                            render={({ field }) => (
                                                <DatePicker


                                                    placeholderText='Click to select a date'
                                                    onChange={(date) => field.onChange(date)}
                                                    selected={field.value ? field.value = "" : field.value }



                                                />
                                            )}
                                        />

                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="form-group before-con after">
                                      

                                        <Controller
                                            control={control}
                                            name="endDate"

                                            render={({ field }) => (
                                               
                                                <DatePicker
                                                
                                                    dateFormat={"dd/mm/yyyy"}
                                                    placeholderText='Click to select a date'
                                                    onChange={(date) => field.onChange(date)}
                                                
                                                    selected={field.value ? field.value = "" : field.value }




                                                />
                                            )}
                                        />
                                    </div>
                                </div>


                                <div className="col-lg-12">
                                    <div className="theme-btn btn-group-right text-right">
                                        <button type="submit" className="btn">Search</button>
                                        <button type="reset" onClick={() => LoadUser()} className="btn">Reset</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className="project-table">
                <div className="form-search">
                    <form>
                        <div className="form-group icon-input top-search">
                            <input type="text" className="form-control" onChange={(event) => { setSearchTerm(event.target.value) }} placeholder="Search" />
                            <FaSearch />
                        </div>

                    </form>
                </div>
                <div className="table-order shadow">

                    <table>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Mobile</th>
                                <th>Registered On</th>
                                <th>Status</th>
                                <th>Actions </th>
                            </tr>
                        </thead>
                        <tbody>


                            {filterVisibility ? users && users.length ? users.filter((val) => {
                                const date = val.createdAt
                                if (searchTerm === "") {
                                    return val
                                } else if (val.name.toLowerCase().includes(searchTerm.toLowerCase())) {
                                    return val
                                } else if (val.email.toLowerCase().includes(searchTerm.toLowerCase())) {
                                    return val
                                } else if (val.mobile.toLowerCase().includes(searchTerm.toLowerCase())) {
                                    return val
                                } else if (moment(date).format("DD/MM/YYYY").toLowerCase().includes(searchTerm.toLowerCase())) {
                                    return val
                                } else if (val.status.toLowerCase().includes(searchTerm.toLowerCase())) {
                                    return val
                                }else{
                                    return false
                                }


                            }).map(user => (
                                <tr key={user._id}>
                                    <td >
                                        {user.name}
                                    </td>
                                    <td>{user.email}</td>
                                    <td>{user.mobile}</td>
                                    <td>{moment(user.createdAt).format("DD/MM/YYYY")}</td>
                                    <td className=""><span className={`badge bg-success ${user.status}`}>{user.status}</span>
                                    </td>
                                    <td className="action-top">
                                        {/* <Link to={`/editNewUser/${user._id}`} className="icons-action"><FaEdit /></Link> */}
                                        <Link to={`/userWineList/${user._id}`} className="icons-action"><FaEye /></Link>
                                        <Link to={`/userFavoriteList/${user._id}`} className="icons-action"><FaRegStar /></Link>
                                        {
                                            user.status === "inactive" ? <span onClick={() => activeHandleShow(user._id)} className="icons-action"><AiOutlineCheckCircle /></span> : <span onClick={() => inActiveHandleShow(user._id)} className="icons-action"><FaBan /></span>

                                        }


                                    </td>

                                </tr>

                            )) : <tr><td className="no-data">NO ANY USER FOUND</td></tr>
                                :
                                filterData && filterData.length ? filterData.filter((val) => {
                                    const date = val.createdAt
                                    if (searchTerm === "") {
                                        return val
                                    } else if (val.name.toLowerCase().includes(searchTerm.toLowerCase())) {
                                        return val
                                    } else if (val.email.toLowerCase().includes(searchTerm.toLowerCase())) {
                                        return val
                                    } else if (val.mobile.toLowerCase().includes(searchTerm.toLowerCase())) {
                                        return val
                                    } else if (moment(date).format("DD/MM/YYYY").toLowerCase().includes(searchTerm.toLowerCase())) {
                                        return val
                                    } else if (val.status.toLowerCase().includes(searchTerm.toLowerCase())) {
                                        return val
                                    }else{
                                        return false
                                    }


                                }).map(user => (
                                    <tr key={user._id}>
                                        <td >
                                            {user.name}
                                        </td>
                                        <td>{user.email}</td>
                                        <td>{user.mobile}</td>
                                        <td>{moment(user.createdAt).format("DD/MM/YYYY")}</td>
                                        <td className=""><span className={`badge bg-success ${user.status}`}>{user.status}</span>
                                        </td>
                                        <td className="action-top">
                                            {/* <Link to={`/editNewUser/${user._id}`} className="icons-action"><FaEdit /></Link> */}
                                            <Link to={`/userWineList/${user._id}`} className="icons-action"><FaEye /></Link>
                                            <Link to={`/userFavoriteList/${user._id}`} className="icons-action"><FaRegStar /></Link>
                                            {
                                                user.status === "inactive" ? <span onClick={() => activeHandleShow(user._id)} className="icons-action"><AiOutlineCheckCircle /></span> : <span onClick={() => inActiveHandleShow(user._id)} className="icons-action"><FaBan /></span>

                                            }


                                        </td>

                                    </tr>

                                )) : <tr ><td className="no-data">NO ANY USER FOUND</td></tr>}



                        </tbody>
                    </table  >
                    {
                        !loading ?
                            <div className="loader-wrapper">
                                <div className="loader">
                                    <div className="loading-svg">
                                        <GiSwordsEmblem />
                                    </div>
                                </div>
                            </div> : ""
                    }


                    {/* <BootstrapTable keyField="id" colunms={columns} data={posts}  filter={filterFactory()} /> */}
                </div>

                <nav>
                    <ul className='pagination-top'>
                        {pageNumbers.map(number => (
                            <li key={number} className='page-item'>
                                <p onClick={() => paginate(number)
                                } className='page-link'>
                                    {number}
                                </p>
                            </li>
                        ))}
                    </ul>
                </nav>

              

            </div>

            {/* modals */}

            {/* active modal */}
            <Modal className="status-modal" animation={false} show={activeShow} onHide={activeHandleClose}>

                <Modal.Body>Are you sure you want to activate user?</Modal.Body>
                <Modal.Footer>
                    <div className="theme-btn btn-group-right text-right">
                        <Button variant="secondary" onClick={activeUser}>
                            Yes
                        </Button>
                        <Button variant="primary" onClick={activeHandleClose}>
                            No
                        </Button>
                    </div>

                </Modal.Footer>
            </Modal>
            {/* active modal end */}

            {/* inactive modal */}
            <Modal className="status-modal"  animation={false} show={inActiveShow} onHide={inActiveHandleClose}>
                <Modal.Body>Are you sure you want to inactivate user?</Modal.Body>
                <Modal.Footer>
                    <div className="theme-btn btn-group-right text-right">
                        <Button variant="secondary" onClick={inActiveUser}>
                            Yes
                        </Button>
                        <Button variant="primary" onClick={inActiveHandleClose}>
                            No
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>
            {/* inactive modal end */}
            <ToastContainer />
        </div>
    )
}
export default GovtServantManagement;