import React from 'react'

import { Modal } from 'react-bootstrap';
// import { useDispatch } from 'react-redux';
import AddMemberForm from '../../../components/user/form/addMember/addMemberForm';
// import GovtEmployee from '../../../components/user/form/govtServentForm/govtEmployeeForm';
// import AddMlaForm from '../../../components/user/form/politicianForm/politicianForm';
// import { loginModalTrue, signUpModalTrue } from '../../../redux/actions/action';



const UserSignUp = (props) => {
    // const [key, setKey] = useState('member');
    // const dispatch = useDispatch()
    // const LoginPopUp = useSelector((state)=> state.loginModalReducer)


    // useEffect(()=>{
    //     dispatch(loginModalTrue(false))
    // },[LoginPopUp])


    return (
        <React.Fragment>
          
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
              
            >

                <div className="rounded-0 get-in-touch touch-bg sec padding pb-0  position-relative">
                <button
                            onClick={() => props.onHide()}
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        />
                    <div className="sign-in ">

                    <AddMemberForm profileimage={"profilemember"} />
                       
                    {/* <Tabs
                        id="controlled-tab-example"
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                        className="mb-3"
                    >
                        <Tab eventKey="govt-employee" title="Govt. Employee">
                            <GovtEmployee profileimage={"profilegovt"} employeeidentity={"employeeidentity"}/>
                        </Tab>
                        <Tab eventKey="member" title="Member">
                            <AddMemberForm profileimage={"profilemember"}/>
                        </Tab>
                        <Tab eventKey="politician" title="Politician" >
                            <AddMlaForm profileimage={"profilpolitician"} politicianidentity={"identitypolitician"}/>
                        </Tab>
                    </Tabs> */}
                    </div>
                </div>

            </Modal>

        </React.Fragment>
    )
}

export default UserSignUp;
