import axios from "axios"
import React, { useCallback, useEffect, useState } from "react"
import PORTNUMBER from '../../../api/api'
import { useNavigate, useParams } from "react-router-dom"
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import { ImageObj } from "../../../assets/images/images";
import IMAGE_HOST from '../../../api/api'

// Import Datepicker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { useDispatch } from 'react-redux';
import { toasterValue } from '../../../redux/actions/action'




const MarriageDataEdit = () => {

    const { id } = useParams();
    const navigate = useNavigate();

    const PORT = PORTNUMBER.PORTNUMBER
    const TOKEN = localStorage.getItem("token")
    const [marriageDetail, setMarriageDetail] = useState([])
    const [imageTypeErr, setImageTypeErr] = useState("")
    const [submitDisabled, setSubmitDisabled] = useState(null)

    const dispatch = useDispatch()
    const HOST_PORT = IMAGE_HOST.IMAGE_HOST

    // dote of birth 
    let DobLoad = new Date(marriageDetail.dob);
    DobLoad.toDateString()
    const DateLoad = marriageDetail.dob === undefined ? new Date() : DobLoad
    const [state, setState] = useState({
        onMind: "",
        image: "",
    })

    const onImageChange = (event) => {
        var fileName = event.target.value;
        var dot = fileName.lastIndexOf(".") + 1;
        var extFile = fileName.substr(dot, fileName.length).toLowerCase();
        if (extFile == "jpg" || extFile == "jpeg" || extFile == "png") {
            if (event.target.files && event.target.files[0]) {
                let reader = new FileReader();
                reader.onload = (e) => {
                    setState({ image: e.target.result });
                    setImageTypeErr("")
                    setSubmitDisabled(false)
                };
                reader.readAsDataURL(event.target.files[0]);

            }
        } else {
            setSubmitDisabled(true)
            setImageTypeErr("Only jpg, png and jpeg image files allowed!")
        }

    }


    const { t } = useTranslation();



    const { control, register, handleSubmit } = useForm();



    const loadMarrigeDetails = useCallback(async () => {
        await axios.post(`${PORT}/user/marriage/detail`, { marriageId: id }, { headers: { authorization: `Bearer ${TOKEN}` } })
            .then((res) => {
                setMarriageDetail(res.data.data)
            })
    }, [PORT, id, TOKEN])


    const onSubmit = (data) => {
        const imageFiles = document.getElementById('marriageImage').files.length;
        const fs = new FormData();
        fs.append("marriageId", id);
        for (const [key] of Object.entries(data)) {
            data[key] = (data[key] === undefined || data[key] === null || data[key] === "") ? marriageDetail[key] : data[key];
            fs.append(key, data[key]);
        }
        if (imageFiles > 0) {
            const File = document.getElementById('marriageImage').files[0];
            fs.append("image", File);
        }

        axios.post(`${PORT}/user/marriage/update`, fs, { headers: { authorization: `Bearer ${TOKEN}` } })
            .then((res) => {
    
                dispatch(toasterValue("Marriage application updated"))
                navigate("/matrimonial")
            })
    }
    // const onImageChange = (event) => {
    //     if (event.target.files && event.target.files[0]) {
    //         let reader = new FileReader();
    //         reader.onload = (e) => {
    //             setState({ image: e.target.result });
    //         };
    //         reader.readAsDataURL(event.target.files[0]);

    //     }

    // }

    useEffect(() => {
        loadMarrigeDetails()
    }, [loadMarrigeDetails])
    return (
        <section className="app-form sec padding">
            <div className="container ">
                <div className="application">
                    <div className="touch-head app">
                        <h4>Marriage Application Form</h4>
                        <p>{t('dataMsg.msg')}</p>

                    </div>
                    <div className="touch-form app">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="col-12">
                                <div className="information-user d-flex">
                                    <div className="info-image">
                                        {!state.image ? marriageDetail.image ?
                                            <img src={`${HOST_PORT}/${marriageDetail.image}`} alt="" />
                                            : <img aria-hidden="true" src={ImageObj.UserBlank} alt="marrige applicant" />
                                            : <img src={state.image} alt="" />
                                        }
                                    </div>
                                    <h3>{state.image || marriageDetail.image ? "Change Image" : "Upload Image"} <input id="marriageImage" name="image" onChange={onImageChange} type="file" className="form-control" />
                                        <div className="form-text text-muted">
                                            {imageTypeErr}
                                        </div>
                                    </h3>
                                    {/* <h4>Remove</h4> */}

                                </div>

                            </div>
                            <div className="col-sm-12 ">
                                <div className="row ">
                                    <div className="col-sm-12">
                                        <h3 className="form-h">{t('Marriage_Data.PersonalDetails.Heading')}</h3>
                                    </div>
                                    <div className="col-sm-6 ">
                                        <div className="form-group ">
                                            <label>{t('Marriage_Data.PersonalDetails.FullName')}<sup>*</sup></label>
                                            <input type="text " defaultValue={marriageDetail && marriageDetail.full_name !== null ? marriageDetail.full_name : "N/A"} name="full_name" {...register('full_name', { required: false })} className="form-control " id="exampleInputName " placeholder={t('Marriage_Data.PersonalDetails.FullName')} />

                                        </div>
                                    </div>
                                    <div className="col-sm-6 ">
                                        <div className="form-group ">
                                            <label>{t('Marriage_Data.PersonalDetails.Cast')}<sup>*</sup></label>
                                            <input type="text " defaultValue={marriageDetail && marriageDetail.caste !== null ? marriageDetail.caste : "N/A"} name="caste"   {...register('caste', { required: false })} className="form-control " id="exampleInputEmail " placeholder={t('Marriage_Data.PersonalDetails.Cast')} />
                                        </div>
                                    </div>
                                    <div className="col-sm-6 ">
                                        <div className="form-group">
                                            <label>{t('Marriage_Data.PersonalDetails.Marital_Status.head')}<sup>*</sup></label>
                                            <select className="form-select form-control"   {...register('marital_status', { required: false })} aria-label="Default select example">
                                                <option className="disabled-value" disabled value={marriageDetail && marriageDetail.marital_status !== null ? marriageDetail.marital_status : "N/A"}>{marriageDetail && marriageDetail.marital_status !== null ? marriageDetail.marital_status : "N/A"}</option>
                                                <option value={t('Marriage_Data.PersonalDetails.Marital_Status.Status1')}>{t('Marriage_Data.PersonalDetails.Marital_Status.Status1')}</option>
                                                <option value={t('Marriage_Data.PersonalDetails.Marital_Status.Status2')}>{t('Marriage_Data.PersonalDetails.Marital_Status.Status2')}</option>
                                                <option value={t('Marriage_Data.PersonalDetails.Marital_Status.Status3')}>{t('Marriage_Data.PersonalDetails.Marital_Status.Status3')}</option>
                                                <option value={t('Marriage_Data.PersonalDetails.Marital_Status.Status4')}>{t('Marriage_Data.PersonalDetails.Marital_Status.Status4')}</option>
                                                <option value={t('Marriage_Data.PersonalDetails.Marital_Status.Status5')}>{t('Marriage_Data.PersonalDetails.Marital_Status.Status5')}</option>
                                                <option value={t('Marriage_Data.PersonalDetails.Marital_Status.Status6')}>{t('Marriage_Data.PersonalDetails.Marital_Status.Status6')}</option>


                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 ">
                                        <div className="form-group ">
                                            <label>{t('Marriage_Data.PersonalDetails.Birth_date')}</label>
                                            <Controller id="validationCustomEleven"
                                                control={control}
                                                name='dob'
                                                render={({ field }) => (
                                                    <DatePicker
                                                        placeholderText={t('Marriage_Data.PersonalDetails.Birth_date')}
                                                        onChange={(date) => field.onChange(date)}
                                                        maxDate={new Date()}
                                                        selected={field.value ? field.value : DateLoad}
                                                    />
                                                )}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-6 ">
                                        <div className="form-group">
                                            <label>{t('Marriage_Data.PersonalDetails.Height.head')}<sup>*</sup></label>
                                            <select className="form-select form-control"  {...register('height', { required: false })} aria-label="Default select example">
                                                <option value={marriageDetail && marriageDetail.height !== null ? marriageDetail.height : "N/A"}>{marriageDetail && marriageDetail.height !== null ? marriageDetail.height : "N/A"}</option>
                                                <option value={t('Marriage_Data.PersonalDetails.Height.height1')}>{t('Marriage_Data.PersonalDetails.Height.height1')}</option>
                                                <option value={t('Marriage_Data.PersonalDetails.Height.height2')}>{t('Marriage_Data.PersonalDetails.Height.height2')}</option>
                                                <option value={t('Marriage_Data.PersonalDetails.Height.height3')}>{t('Marriage_Data.PersonalDetails.Height.height3')}</option>
                                                <option value={t('Marriage_Data.PersonalDetails.Height.height4')}>{t('Marriage_Data.PersonalDetails.Height.height4')}</option>
                                                <option value={t('Marriage_Data.PersonalDetails.Height.height5')}>{t('Marriage_Data.PersonalDetails.Height.height5')}</option>
                                                <option value={t('Marriage_Data.PersonalDetails.Height.height6')}>{t('Marriage_Data.PersonalDetails.Height.height6')}</option>

                                                <option value={t('Marriage_Data.PersonalDetails.Height.height7')}>{t('Marriage_Data.PersonalDetails.Height.height7')}</option>
                                                <option value={t('Marriage_Data.PersonalDetails.Height.height8')}>{t('Marriage_Data.PersonalDetails.Height.height8')}</option>
                                                <option value={t('Marriage_Data.PersonalDetails.Height.height9')}>{t('Marriage_Data.PersonalDetails.Height.height9')}</option>
                                                <option value={t('Marriage_Data.PersonalDetails.Height.height10')}>{t('Marriage_Data.PersonalDetails.Height.height10')}</option>
                                                <option value={t('Marriage_Data.PersonalDetails.Height.height11')}>{t('Marriage_Data.PersonalDetails.Height.height11')}</option>
                                                <option value={t('Marriage_Data.PersonalDetails.Height.height12')}>{t('Marriage_Data.PersonalDetails.Height.height12')}</option>

                                                <option value={t('Marriage_Data.PersonalDetails.Height.height13')}>{t('Marriage_Data.PersonalDetails.Height.height13')}</option>
                                                <option value={t('Marriage_Data.PersonalDetails.Height.height14')}>{t('Marriage_Data.PersonalDetails.Height.height14')}</option>
                                                <option value={t('Marriage_Data.PersonalDetails.Height.height15')}>{t('Marriage_Data.PersonalDetails.Height.height15')}</option>
                                                <option value={t('Marriage_Data.PersonalDetails.Height.height16')}>{t('Marriage_Data.PersonalDetails.Height.height16')}</option>
                                                <option value={t('Marriage_Data.PersonalDetails.Height.height17')}>{t('Marriage_Data.PersonalDetails.Height.height17')}</option>
                                                <option value={t('Marriage_Data.PersonalDetails.Height.height18')}>{t('Marriage_Data.PersonalDetails.Height.height18')}</option>

                                                <option value={t('Marriage_Data.PersonalDetails.Height.height19')}>{t('Marriage_Data.PersonalDetails.Height.height19')}</option>
                                                <option value={t('Marriage_Data.PersonalDetails.Height.height20')}>{t('Marriage_Data.PersonalDetails.Height.height20')}</option>
                                                <option value={t('Marriage_Data.PersonalDetails.Height.height21')}>{t('Marriage_Data.PersonalDetails.Height.height21')}</option>
                                                <option value={t('Marriage_Data.PersonalDetails.Height.height22')}>{t('Marriage_Data.PersonalDetails.Height.height22')}</option>
                                                <option value={t('Marriage_Data.PersonalDetails.Height.height23')}>{t('Marriage_Data.PersonalDetails.Height.height23')}</option>
                                                <option value={t('Marriage_Data.PersonalDetails.Height.height24')}>{t('Marriage_Data.PersonalDetails.Height.height24')}</option>

                                                <option value={t('Marriage_Data.PersonalDetails.Height.height25')}>{t('Marriage_Data.PersonalDetails.Height.height25')}</option>
                                                <option value={t('Marriage_Data.PersonalDetails.Height.height26')}>{t('Marriage_Data.PersonalDetails.Height.height26')}</option>
                                                <option value={t('Marriage_Data.PersonalDetails.Height.height27')}>{t('Marriage_Data.PersonalDetails.Height.height27')}</option>
                                                <option value={t('Marriage_Data.PersonalDetails.Height.height28')}>{t('Marriage_Data.PersonalDetails.Height.height28')}</option>
                                                <option value={t('Marriage_Data.PersonalDetails.Height.height29')}>{t('Marriage_Data.PersonalDetails.Height.height29')}</option>
                                                <option value={t('Marriage_Data.PersonalDetails.Height.height30')}>{t('Marriage_Data.PersonalDetails.Height.height30')}</option>
                                                <option value={t('Marriage_Data.PersonalDetails.Height.height31')}>{t('Marriage_Data.PersonalDetails.Height.height31')}</option>



                                            </select>
                                        </div>
                                    </div>
                                    {/* <div className="col-sm-6 ">
                                        <div className="form-group">
                                           <label>{t('Marriage_Data.PersonalDetails.Blood_Group.head')}<sup>*</sup></label>
                                           <select className="form-select form-control" {...register('blood_group', { required: false })}  aria-label="Default select example">
                                            <option value={marriageDetail && marriageDetail.blood_group !== null ? marriageDetail.blood_group : "N/A"}>{marriageDetail && marriageDetail.blood_group !== null ? marriageDetail.blood_group : "N/A"}</option>
                                            <option value={t('Marriage_Data.PersonalDetails.Blood_Group.Group_1')}>{t('Marriage_Data.PersonalDetails.Blood_Group.Group_1')}</option>
                                            <option value={t('Marriage_Data.PersonalDetails.Blood_Group.Group_2')}>{t('Marriage_Data.PersonalDetails.Blood_Group.Group_2')}</option>
                                            <option value={t('Marriage_Data.PersonalDetails.Blood_Group.Group_3')}>{t('Marriage_Data.PersonalDetails.Blood_Group.Group_3')}</option>
                                            <option value={t('Marriage_Data.PersonalDetails.Blood_Group.Group_4')}>{t('Marriage_Data.PersonalDetails.Blood_Group.Group_4')}</option>
                                            <option value={t('Marriage_Data.PersonalDetails.Blood_Group.Group_5')}>{t('Marriage_Data.PersonalDetails.Blood_Group.Group_5')}</option>
                                            <option value={t('Marriage_Data.PersonalDetails.Blood_Group.Group_6')}>{t('Marriage_Data.PersonalDetails.Blood_Group.Group_6')}</option>
                                            </select>
                                        </div>
                                    </div> */}
                                    <div className="col-sm-6 ">
                                        <div className="form-group">
                                            <label>{t('Marriage_Data.PersonalDetails.Complexion.head')}<sup>*</sup></label>
                                            <select className="form-select form-control"  {...register('complexion', { required: false })} aria-label="Default select example">
                                                <option value={marriageDetail && marriageDetail.complexion !== null ? marriageDetail.complexion : "N/A"}>{marriageDetail && marriageDetail.complexion !== null ? marriageDetail.complexion : "N/A"}</option>
                                                <option value={t('Marriage_Data.PersonalDetails.Complexion.Complexion_1')}>{t('Marriage_Data.PersonalDetails.Complexion.Complexion_1')}</option>
                                                <option value={t('Marriage_Data.PersonalDetails.Complexion.Complexion_2')}>{t('Marriage_Data.PersonalDetails.Complexion.Complexion_2')}</option>
                                                <option value={t('Marriage_Data.PersonalDetails.Complexion.Complexion_3')}>{t('Marriage_Data.PersonalDetails.Complexion.Complexion_3')}</option>
                                                <option value={t('Marriage_Data.PersonalDetails.Complexion.Complexion_4')}>{t('Marriage_Data.PersonalDetails.Complexion.Complexion_4')}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 ">
                                        <div className="form-group">
                                            <label>{t('Member.Gender')}</label>
                                            <select name="gender" {...register('gender', { required: false })} className="form-control form-select" id="validationCustomThree10" required >
                                                <option value={marriageDetail && marriageDetail.gender !== null ? marriageDetail.gender : "N/A"} className="disabled-value d-none" >{marriageDetail && marriageDetail.gender !== null ? marriageDetail.gender : "N/A"}</option>
                                                <option value={t('Member.Male')}>{t('Member.Male')}</option>
                                                <option value={t('Member.Female')}>{t('Member.Female')}</option>
                                            </select>
                                            <div className="invalid-feedback">
                                                Select Your Gender.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 ">
                                        <div className="form-group ">
                                            <label>{t('Marriage_Data.PersonalDetails.age')}<sup>*</sup></label>
                                            <input type="text " defaultValue={marriageDetail && marriageDetail.age !== null ? marriageDetail.age : "N/A"} name="age"  {...register('age', { required: false })} className="form-control " placeholder={t('Marriage_Data.PersonalDetails.age')} id="validationCustom29" />
                                            <div className="invalid-feedback">
                                                Please Fill Your age.
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-sm-6 ">
                                        <div className="form-group ">
                                            <label>{t('Marriage_Data.PersonalDetails.state')}<sup>*</sup></label>
                                            <input type="text " defaultValue={marriageDetail && marriageDetail.state !== null ? marriageDetail.state : "N/A"} name="state" {...register('state', { required: false })} className="form-control " placeholder={t('Marriage_Data.PersonalDetails.state')} />

                                        </div>
                                    </div>
                                    <div className="col-sm-6 ">
                                        <div className="form-group ">
                                            <label>{t('Marriage_Data.PersonalDetails.city')}<sup>*</sup></label>
                                            <input defaultValue={marriageDetail && marriageDetail.city !== null ? marriageDetail.city : "N/A"} type="text " name="city"  {...register('city', { required: false })} className="form-control " placeholder={t('Marriage_Data.PersonalDetails.city')} id="validationCustom30" />
                                            <div className="invalid-feedback">
                                                Please Fill Your city.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12">
                                        <h3 className="form-h">{t('Marriage_Data.Horoscope_Details.Heading')}</h3>
                                    </div>
                                    {/* <div className="col-sm-6 ">
                                        <div className="form-group ">
                                            <label>{t('Marriage_Data.Horoscope_Details.Details')}<sup>*</sup></label>
                                            <input type="text " defaultValue={marriageDetail && marriageDetail.time_of_birth !== null ? marriageDetail.time_of_birth : "N/A"} name="time_of_birth" {...register('time_of_birth', { required: false })} className="form-control "  placeholder={t('Marriage_Data.Horoscope_Details.Details')} />
                                            
                                        </div>
                                    </div> */}
                                    <div className="col-sm-6 ">
                                        <div className="form-group ">
                                            <label>{t('Marriage_Data.Horoscope_Details.Details2')}<sup>*</sup></label>
                                            <input type="text " defaultValue={marriageDetail && marriageDetail.place_of_birth !== null ? marriageDetail.place_of_birth : "N/A"} name="place_of_birth" {...register('place_of_birth', { required: false })} className="form-control " placeholder={t('Marriage_Data.Horoscope_Details.Details')} />

                                        </div>
                                    </div>
                                    {/* <div className="col-sm-6 ">
                                        <div className="form-group">
                                            <label>{t('Marriage_Data.Horoscope_Details.Mangal.head')}<sup>*</sup></label>
                                            <select className="form-select form-control"  {...register('mangal', { required: false })} aria-label="Default select example">
                                                <option value={marriageDetail && marriageDetail.mangal !== null ? marriageDetail.mangal : "N/A"}>{marriageDetail && marriageDetail.mangal !== null ? marriageDetail.mangal : "N/A"}</option>
                                                <option value={t('Marriage_Data.Horoscope_Details.Mangal.yes')}>{t('Marriage_Data.Horoscope_Details.Mangal.yes')}</option>
                                                <option value={t('Marriage_Data.Horoscope_Details.Mangal.no')}>{t('Marriage_Data.Horoscope_Details.Mangal.no')}</option>

                                            </select>
                                        </div>
                                    </div> */}
                                    <div className="col-sm-6 ">
                                        <div className="form-group ">
                                            <label>{t('Marriage_Data.Horoscope_Details.Details3')}<sup>*</sup></label>
                                            <input type="text " defaultValue={marriageDetail && marriageDetail.gotra !== null ? marriageDetail.gotra : "N/A"} name="gotra" {...register('gotra', { required: false })} className="form-control " placeholder={t('Marriage_Data.Horoscope_Details.Details3')} />

                                        </div>
                                    </div>

                                    <div className="col-sm-12">
                                        <h3 className="form-h">{t('Marriage_Data.Education.Heading')}</h3>
                                    </div>
                                    <div className="col-sm-6 ">
                                        <div className="form-group ">
                                            <label>{t('Marriage_Data.Education.Detail_edu')}<sup>*</sup></label>
                                            <input type="text " defaultValue={marriageDetail && marriageDetail.education_level !== null ? marriageDetail.education_level : "N/A"} name="education_level" {...register('education_level', { required: false })} className="form-control " placeholder={t('Marriage_Data.Education.Detail_edu')} />

                                        </div>
                                    </div>
                                    <div className="col-sm-6 ">
                                        <div className="form-group ">
                                            <label>{t('Marriage_Data.Education.Detail_edu2')}<sup>*</sup></label>
                                            <input type="text " defaultValue={marriageDetail && marriageDetail.education_details !== null ? marriageDetail.education_details : "N/A"} name="education_details" {...register('education_details', { required: false })} className="form-control " placeholder={t('Marriage_Data.Education.Detail_edu2')} />

                                        </div>
                                    </div>
                                    <div className="col-sm-6 ">
                                        <div className="form-group ">
                                            <label>{t('Marriage_Data.Education.Detail_edu3')}<sup>*</sup></label>
                                            <input type="text " name="occupation" defaultValue={marriageDetail && marriageDetail.occupation !== null ? marriageDetail.occupation : "N/A"} {...register('occupation', { required: false })} className="form-control " placeholder={t('Marriage_Data.Education.Detail_edu3')} />

                                        </div>
                                    </div>
                                    {/* <div className="col-sm-6 ">
                                        <div className="form-group ">
                                            <label>{t('Marriage_Data.Education.Detail_income')}<sup>*</sup></label>
                                            <input type="text " defaultValue={marriageDetail && marriageDetail.income_details !== null ? marriageDetail.income_details : "N/A"} {...register('occupation', { required: false })}   placeholder={t('Marriage_Data.Education.Detail_edu3')}  id="validationCustomFifteen6"   />
                                             <div className="invalid-feedback">
                                                Please Fill Your occupation.
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="col-sm-12">
                                        <h3 className="form-h">{t('Marriage_Data.Family_Background.Heading')}</h3>
                                    </div>

                                    <div className="col-sm-6 ">
                                        <div className="form-group">
                                            <label>{t('Marriage_Data.Family_Background.Detail1.head')}<sup>*</sup></label>
                                            <select className="form-select form-control"  {...register('father', { required: false })} aria-label="Default select example">
                                                <option value={marriageDetail && marriageDetail.father !== null ? marriageDetail.father : "N/A"}>{marriageDetail && marriageDetail.father !== null ? marriageDetail.father : "N/A"}</option>
                                                <option value={t('Marriage_Data.Family_Background.Detail1.yes')}>{t('Marriage_Data.Family_Background.Detail1.yes')}</option>
                                                <option value={t('Marriage_Data.Family_Background.Detail1.no')}>{t('Marriage_Data.Family_Background.Detail1.no')}</option>

                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 ">
                                        <div className="form-group">
                                            <label>{t('Marriage_Data.Family_Background.Detail2.head')}<sup>*</sup></label>
                                            <select className="form-select form-control" {...register('mother', { required: false })} aria-label="Default select example">
                                                <option value={marriageDetail && marriageDetail.mother !== null ? marriageDetail.mother : "N/A"}>{marriageDetail && marriageDetail.mother !== null ? marriageDetail.mother : "N/A"}</option>
                                                <option value={t('Marriage_Data.Family_Background.Detail2.yes')}>{t('Marriage_Data.Family_Background.Detail2.yes')}</option>
                                                <option value={t('Marriage_Data.Family_Background.Detail2.no')}>{t('Marriage_Data.Family_Background.Detail2.no')}</option>

                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 ">
                                        <div className="form-group ">
                                            <label>{t('Marriage_Data.Family_Background.Detail3')}<sup>*</sup></label>
                                            <input type="text " defaultValue={marriageDetail && marriageDetail.father_name !== null ? marriageDetail.father_name : "N/A"} name="father_name" {...register('father_name', { required: false })} className="form-control " placeholder={t('Marriage_Data.Family_Background.Detail3')} />

                                        </div>
                                    </div>
                                    <div className="col-sm-6 ">
                                        <div className="form-group ">
                                            <label>{t('Marriage_Data.Family_Background.Detail4')}<sup>*</sup></label>
                                            <input type="text " defaultValue={marriageDetail && marriageDetail.mother_name !== null ? marriageDetail.mother_name : "N/A"} name="mother_name" {...register('mother_name', { required: false })} className="form-control " placeholder={t('Marriage_Data.Family_Background.Detail4')} />

                                        </div>
                                    </div> <div className="col-sm-6 ">
                                        <div className="form-group ">
                                            <label>{t('Marriage_Data.Family_Background.Detail5')}<sup>*</sup></label>
                                            <input type="text " defaultValue={marriageDetail && marriageDetail.total_brothers !== null ? marriageDetail.total_brothers : "N/A"} name="total_brothers" {...register('total_brothers', { required: false })} className="form-control " placeholder={t('Marriage_Data.Family_Background.Detail5')} />

                                        </div>
                                    </div> <div className="col-sm-6 ">
                                        <div className="form-group ">
                                            <label>{t('Marriage_Data.Family_Background.Detail6')}<sup>*</sup></label>
                                            <input type="text " defaultValue={marriageDetail && marriageDetail.married_brothers !== null ? marriageDetail.married_brothers : "N/A"} name="married_brothers" {...register('married_brothers', { required: false })} className="form-control " placeholder={t('Marriage_Data.Family_Background.Detail6')} />

                                        </div>
                                    </div> <div className="col-sm-6 ">
                                        <div className="form-group ">
                                            <label>{t('Marriage_Data.Family_Background.Detail7')}<sup>*</sup></label>
                                            <input type="text " defaultValue={marriageDetail && marriageDetail.parents_contact_no !== null ? marriageDetail.parents_contact_no : "N/A"} name="parents_contact_no" {...register('parents_contact_no', { required: false })} className="form-control " placeholder={t('Marriage_Data.Family_Background.Detail7')} />

                                        </div>
                                    </div> <div className="col-sm-6 ">
                                        <div className="form-group ">
                                            <label>{t('Marriage_Data.Family_Background.Detail8')}<sup>*</sup></label>
                                            <input type="text " defaultValue={marriageDetail && marriageDetail.total_sisters !== null ? marriageDetail.total_sisters : "N/A"} name="total_sisters" {...register('total_sisters', { required: false })} className="form-control " placeholder={t('Marriage_Data.Family_Background.Detail8')} />

                                        </div>
                                    </div> <div className="col-sm-6 ">
                                        <div className="form-group ">
                                            <label>{t('Marriage_Data.Family_Background.Detail9')}<sup>*</sup></label>
                                            <input type="text " defaultValue={marriageDetail && marriageDetail.married_sisters !== null ? marriageDetail.married_sisters : "N/A"} name="married_sisters" {...register('married_sisters', { required: false })} className="form-control " placeholder={t('Marriage_Data.Family_Background.Detail9')} />

                                        </div>
                                    </div> <div className="col-sm-6 ">
                                        <div className="form-group ">
                                            <label>{t('Marriage_Data.Family_Background.Detail10')}<sup>*</sup></label>
                                            <input type="text " defaultValue={marriageDetail && marriageDetail.residential_address !== null ? marriageDetail.residential_address : "N/A"} name="residential_address" {...register('residential_address', { required: false })} className="form-control " placeholder={t('Marriage_Data.Family_Background.Detail10')} />

                                        </div>
                                    </div>
                                    {/* <div className="col-sm-6 ">
                                        <div className="form-group ">
                                            <label>{t('Marriage_Data.Family_Background.Detail12')}<sup>*</sup></label>
                                            <input type="text " defaultValue={marriageDetail && marriageDetail.father_occupation !== null ? marriageDetail.father_occupation : "N/A"} name="father_occupation" {...register('father_occupation', { required: false })} className="form-control " placeholder={t('Marriage_Data.Family_Background.Detail12')} />

                                        </div>
                                    </div> */}
                                    {/* <div className="col-sm-6 ">
                                        <div className="form-group ">
                                            <label>{t('Marriage_Data.Family_Background.Detail13')}<sup>*</sup></label>
                                            <input type="text " defaultValue={marriageDetail && marriageDetail.maternal_uncle_place !== null ? marriageDetail.maternal_uncle_place : "N/A"} name="maternal_uncle_place" {...register('maternal_uncle_place', { required: false })} className="form-control "  placeholder={t('Marriage_Data.Family_Background.Detail13')} />
                                            
                                        </div>
                                    </div>  */}
                                    {/* <div className="col-sm-6 ">
                                        <div className="form-group ">
                                            <label>{t('Marriage_Data.Family_Background.Detail14')}<sup>*</sup></label>
                                            <input type="text " defaultValue={marriageDetail && marriageDetail.surname_relatives !== null ? marriageDetail.surname_relatives : "N/A"} name="surname_relatives" {...register('surname_relatives', { required: false })} className="form-control "  placeholder={t('Marriage_Data.Family_Background.Detail14')} />
                                            
                                        </div>
                                    </div>  */}
                                    {/* <div className="col-sm-6 ">
                                        <div className="form-group ">
                                            <label>{t('Marriage_Data.Family_Background.Detail15')}<sup>*</sup></label>
                                            <input type="text " defaultValue={marriageDetail && marriageDetail.expectations_from_partner !== null ? marriageDetail.expectations_from_partner : "N/A"} name="expectations_from_partner" {...register('expectations_from_partner', { required: false })} className="form-control "  placeholder={t('Marriage_Data.Family_Background.Detail15')} />
                                            
                                        </div>
                                    </div> */}

                                    <div className="col-md-12 ">
                                        <div className="brown-button submit ">
                                            <button disabled={submitDisabled} type="submit" className="btn mt-1">Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default MarriageDataEdit;