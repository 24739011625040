import React,{useState, useEffect} from 'react'
import Pagination from "react-js-pagination";



const PaginationCount = (props) =>{
    const [pageCount, setPageCount] = useState();


    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      };

    const useWindowSize = () => {
        const [size, setSize] = useState([window.innerWidth]);
        useEffect(() => {
            const handleResize = () => setSize([window.innerWidth]);
            window.addEventListener("resize", handleResize);
            return () => {
                window.removeEventListener("resize", handleResize);
            };
        }, []);
        return size;
    }
   
    const [width] = useWindowSize();

    useEffect(() => {
        if (width < 376) {
            setPageCount(3)
            console.log("width1", width)

        } else if (width < 576) {
            setPageCount(5)
            console.log("width2", width)

        }
        else {
            setPageCount(10)
            console.log("width3", width)


        }
    }, [width, pageCount])
    return(
        <React.Fragment>
        {
            (props.count > props.itemperpage) &&
                <div className='pagination-top' onClick={()=>scrollToTop()}>
                    <Pagination
                        activePage={props.activepage}
                        itemsCountPerPage={pageCount}
                        totalItemsCount={props.itemperpage}
                        pageRangeDisplayed={pageCount}
                        onChange={props.handlepagechange}
                       
                    />
                </div> 
        }
    </React.Fragment>

    )
}

export default PaginationCount;