import React, { useCallback, useEffect, useState } from 'react'
import { ImageObj } from '../../../../assets/images/images'
import { useNavigate, useParams } from 'react-router-dom'
import OtpInput from 'react-otp-input';
import VERIFY_OTP_ADMIN from '../../../../api/api'
import axios from 'axios'

const ResetPasswordLink = () => {
    const navigate = useNavigate()
    const [otp, setOtp] = useState()
    const { id } = useParams()
    const [decryptedMail, setDecryptedMail] = useState()



    const DeCryptLoad = useCallback(() => {
        setDecryptedMail(decodeURI(id))
    }, [id])


    const handleOtp = (otp) => {
        setOtp(otp)
    };
    useEffect(() => {
        DeCryptLoad()

        document.body.classList.add("remove-home-header");
        return () => {
            document.body.classList.remove("remove-home-header");
        }

    }, [DeCryptLoad])

    const VerifyOtp = async (data) => {
        alert(1)
        await axios.post(VERIFY_OTP_ADMIN.VERIFY_OTP_ADMIN, { otp: otp, email: decryptedMail })
            .then((res) => {
                if (res.data.success === true) {
                    navigate("/admin")
                    navigate(`admin/ResetPasswordAdmin/${id}`)
                } else {
                }
            })
    }

    return (
        <div className="container">
            <div className="max-new-second max-margin">
                <div className="main-theme bg-theme border otp-pad new shadow">
                    <section className="select-state">
                        <div className="state-bor">
                            <div className="log-in otp-num">
                                <div className="select-steps-box">
                                    <div className="input-head">
                                        <h2>Check Your Email</h2>
                                        <p>We have sent an email to your registered email address. Please check that to continue.</p>
                                    </div>
                                    <div className="otp-box">
                                        <OtpInput
                                            value={otp}
                                            onChange={handleOtp}
                                            numInputs={4}
                                            isInputNum={true}
                                            containerStyle="otp-inner"

                                        />
                                    </div>
                                </div>
                                <div className="select-box-top pr-0">

                                    <div className="theme-btn">
                                        <button className="btn verfy-btn" onClick={VerifyOtp}>Go Back To Login <span><img aria-hidden="true" src={ImageObj.WhiteArrow} alt="" /></span></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    )
}

export default ResetPasswordLink;