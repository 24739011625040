import React,{useEffect} from 'react'
import { ImageObj } from '../../../assets/images/images';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

const YashwantRaoMukne =()=>{

    const { t } = useTranslation();


    useEffect(() => {
        document.body.classList.add("choclate-bg");
        return(()=>{
        document.body.classList.remove("choclate-bg");

        })
   }, [])


    return(
        <React.Fragment>
        <Helmet>
            <title>Maharaja Yashwantrao Martandrao Mukne History</title>
            <meta name="description" content="Maharaja Yashwantrao Martanda Rao Mukne alias Maharaja Patangsah Mukne (fifth) (11 December 1917 – 4 June 1978) was the last Koli Maharaja of the princely state of Jawhar. He was the son of Maharaja Maharaja Martandrao Malharrao Mukne, after his death, he assumed the throne of the princely state of Jawhar." />
            <meta name="keywords" content="Maharaja Yashwantrao Martanda Rao Mukne, mukne, Maharaja Yashwantrao, Maharaja Patangsah Mukne, Maharaja Martandrao Malharrao Mukne, Maharaja Martandrao " />
            <meta name="author" content="mahendra koli" />
            <link rel="canonical" href="https://www.kolisamajvikashsanstha.com/yashwantRaoMukne" />
        </Helmet>
        <div className="container padding-mob-0">
        <div className="history-show-top bg" style={{backgroundImage: `url(${ImageObj.ShowHisBg2})`}}>
                <div className="show-history-inner">
                    <div className="koli-history show-his">
                        <h2>{t('HisMukne.titleOne')}</h2>
                        <p>{t('HisMukne.summeryOne')}</p>
                        <h2>{t('HisMukne.titleTwo')}</h2>
                        <p>{t('HisMukne.summeryTwo')}</p>
                        <p>{t('HisMukne.summeryThree')}</p>
                        <p>{t('HisMukne.summeryFour')}</p>
                        <h2>{t('HisMukne.titleThree')}</h2>
                        <p>{t('HisMukne.summeryFive')}</p>
                    </div>
                </div>
            </div>
        </div>
        </React.Fragment>
    )
}

export default YashwantRaoMukne;