import React, { useCallback, useEffect, useState } from 'react'
import { HiOutlineArrowRight } from 'react-icons/hi'
import { Spinner } from 'react-bootstrap';

import { useNavigate, useParams, Link } from 'react-router-dom'
import OtpInput from 'react-otp-input';
import VERIFY_OTP from '../../../../api/api'
import axios from 'axios'

const ResetPasswordLink = () => {
    const navigate = useNavigate()
    const [otp, setOtp] = useState()
    const { id } = useParams()
    const [decryptedMail, setDecryptedMail] = useState()
    const [spinLoadVisibility, setSpinLoadVisibility] = useState(false);
    const [responseMsg, setResponseMsg] = useState()



    const DeCryptLoad = useCallback(() => {
        setDecryptedMail(decodeURI(id))
    }, [id])


    const handleOtp = (otp) => {
        setOtp(otp)
    };
    useEffect(() => {
        document.body.classList.add("remove-home-header");
        return () => {
            document.body.classList.remove("remove-home-header");
        }
    }, [])

    useEffect(() => {
        DeCryptLoad()

    }, [DeCryptLoad])

    const VerifyOtp = async (data) => {
        setSpinLoadVisibility(true)
        await axios.post(VERIFY_OTP.VERIFY_OTP, { otp: otp, email: decryptedMail })
            .then((res) => {

                setResponseMsg(res.data.message)
                setSpinLoadVisibility(false)
                if (res.data.success === true) {
                    navigate("/")
                    navigate(`/resetPassword/${id}`)
                } else {

                }


            })
    }

    return (
        <div className="container">
            <div className="max-new-second pd-t-b-100">
                <div className="main-theme bg-theme border otp-pad new shadow">
                    <section className="select-state">
                        <div className="state-bor">
                            <div className="log-in otp-num">
                                <div className="select-steps-box">
                                    <div className="input-head">
                                        <h2>Check Your Email</h2>
                                        <p>We have sent an email to your registered email address. Please check that to continue.</p>
                                    </div>
                                    <div className="otp-box">
                                        <OtpInput
                                            value={otp}
                                            onChange={handleOtp}
                                            numInputs={4}
                                            isInputNum={true}
                                            containerStyle="otp-inner"

                                        />
                                    </div>
                                    <small id="emailHelp" className=" text-center mb-2 form-text text-muted">{responseMsg}</small>
                                </div>
                                <div className="select-box-top pr-0">

                                    <div className="theme-btn">
                                        <button disabled={spinLoadVisibility} className="btn verfy-btn" onClick={VerifyOtp}>
                                            {
                                                spinLoadVisibility === false ?
                                                    <>
                                                        Submit Otp <HiOutlineArrowRight />
                                                    </> :
                                                    <div className="spinner-visibility">
                                                        <Spinner
                                                            as="span"
                                                            animation="grow"
                                                            size="sm"
                                                            role="status"
                                                            aria-hidden="true"
                                                        />
                                                        Sending...
                                                    </div>
                                            }


                                        </button>
                                        <p className="resend new"><span><Link to="/resetmail">Resend Otp </Link></span></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    )
}

export default ResetPasswordLink;