import React, { useEffect, useState } from 'react'

import { useNavigate, Link, useLocation } from 'react-router-dom'
import { useForm } from 'react-hook-form';
import axios from 'axios'


import { ImageObj } from '../../../../assets/images/images'
import FORGOT_PASSWORD_ADMIN_API from '../../../../api/api'


const ResetMail = () => {
    const navigate = useNavigate()
    const location = useLocation();
    const pathname = location.pathname;
    const [mailErr, setMailErr] = useState()

    const { register, handleSubmit } = useForm();



    useEffect(() => {
        document.body.classList.add("remove-home-header");
        return () => {
            document.body.classList.remove("remove-home-header");
        }
    }, [])

    const resetLink = async (data) => {
    
        await axios.post(FORGOT_PASSWORD_ADMIN_API.FORGOT_PASSWORD_ADMIN_API, { email: data.email })
            .then((res) => {
        
                if (res.data.success === true) {
                    const encryptedMail = encodeURI(data.email);
                    navigate(`/admin/resetPasswordLink/${encryptedMail}`)
                } else {
                    setMailErr(res.data.message)
                }


            })
    }


    return (
        <div className="container">
            <div className="max-new-second max-margin">
                <div className="main-theme bg-theme border otp-pad new shadow">
                    <section className="select-state">
                        <div className="state-bor">
                            <div className="log-in otp-num">
                                <div className="select-steps-box">
                                    <div className="input-head">
                                        <h2>Reset Password</h2>
                                        <p>Please enter your registered email address and we would send you the link to reset your password.</p>
                                    </div>
                                </div>
                                <div className="select-box-top pr-0">
                                    <div className="input-box">
                                        <form onSubmit={handleSubmit(resetLink)}>
                                            <div className="col-12 p-0">
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <div className="form-group">
                                                            <input type="email" className="form-control" name="email" {...register('email', { required: true })} placeholder="Enter your Email" />
                                                            <small className=" form-text text-muted">{mailErr}</small>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="theme-btn">
                                                <button type="submit" className="btn verfy-btn">Submit <span><img aria-hidden="true" src={ImageObj.WhiteArrow} alt="" /></span></button>
                                                <p className="resend new">Have an account? <span><Link to="/">Login <i className="fas fa-angle-right " aria-hidden="true" /></Link></span></p>
                                            </div>
                                        </form>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    )
}

export default ResetMail;