import React,{useEffect} from 'react'
import { ImageObj } from '../../../assets/images/images';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

const TanaJi =()=>{
    const { t } = useTranslation();

    useEffect(() => {
        document.body.classList.add("choclate-bg");
        return(()=>{
        document.body.classList.remove("choclate-bg");

        })
   }, [])


    return(
        <React.Fragment>
        <Helmet>
            <title>Tanaji Malusare History</title>
            <meta name="description" content="Tanaji Malusare was a Maratha Subedar Sardar in the Maratha army of the Maratha Empire. He used to play the role of Subedar (Killedar) with Chhatrapati Shivaji Maharaj for the establishment of Maratha Empire, Hindavi Swarajya. Tanaji was a childhood friend of Chhatrapati Shivaji Maharaj, they played together in childhood." />
            <meta name="keywords" content="Tanaji Malusare, Maratha, Maratha army, Maratha Empire,  Hindavi Swarajya, Sinhagad, Konkan, Kondana Fort" />
            <meta name="author" content="mahendra koli" />
            <link rel="canonical" href="https://www.kolisamajvikashsanstha.com/tanaji" />
        </Helmet>
        <div className="container padding-mob-0">
        <div className="history-show-top bg" style={{backgroundImage: `url(${ImageObj.ShowHisBg2})`}}>
                <div className="show-history-inner">
                    <div className="koli-history show-his">
                        <h2>{t('HisTanaji.titleOne')}</h2>
                        <p>{t('HisTanaji.summeryOne')}</p>
                        <p>{t('HisTanaji.summeryTwo')}</p>
                        <h2>{t('HisTanaji.titleTwo')}</h2>
                        <p>{t('HisTanaji.summeryThree')}</p>
                        <p>{t('HisTanaji.summeryFour')}</p>
                        <p>{t('HisTanaji.summeryFive')}</p>
                        <h2>{t('HisTanaji.titleThree')}</h2>
                        <p>{t('HisTanaji.summerySix')}</p>
                        <h2>{t('HisTanaji.titleFour')}</h2>
                        <p>{t('HisTanaji.summerySeven')}</p>

                    </div>
                </div>
            </div>
        </div>
        </React.Fragment>
    )
}

export default TanaJi;