import React from "react"

const Venerable = () => {
    return (
        <section className="koli-history">
            <p>
            <span className="brown-color point d-block mr"> कुबेर दास :- </span> गुजरात के आनंद सेहर के पास सरसा गांव के एक संत थे। इनको कुबेरा के नाम से भी जाना जाता है। वो गुजरात की कोली जाती से संबंद रखते थे। लेकिन उनको पूजने वाले ज्यादातर लुहार जाती के लोग थे। कुबेर के गुरुजी का का कृष्णदास था। कुबेरजी के बिस हज़ार से ज्यादा अनुयायी थे जो अपने आप को कुबेरपंथी बोलते थे। संत कुबेर के अनुयायी उनको अपना जनक मानते हैं।
            </p>
        </section>
    )
}

export default Venerable;