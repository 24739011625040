import React, { useState, useEffect, useCallback } from 'react'
import { ImageObj } from '../../../../assets/images/images'
import { Tabs, Tab, Modal, Button, Dropdown } from 'react-bootstrap'
import axios from 'axios'
import { useParams, Link } from 'react-router-dom'
import PORTNUMBER from '../../../../api/api'
import { FaEllipsisV, FaUser, FaNetworkWired, FaCity, FaBlackTie, FaMapMarkedAlt, FaChartArea } from 'react-icons/fa';
import { FcGallery } from 'react-icons/fc';
import { HiOfficeBuilding, HiOutlineUserGroup } from 'react-icons/hi';
import { MdLocationCity } from 'react-icons/md';



// import { GrMail } from 'react-icons/gr';
import moment from 'moment'
import { toast } from 'react-toastify'
import Pagination from "react-js-pagination";
import { useTranslation } from 'react-i18next';
import IMAGE_HOST from '../../../../api/api'
// import { FaRegUserCircle } from 'react-icons/fa';

import { useForm } from 'react-hook-form';


const Profile = () => {
    const { id } = useParams()
    const PORT = PORTNUMBER.PORTNUMBER
    const TOKEN = localStorage.getItem("token")
    const UserTokenId = localStorage.getItem("UserTokenId")
    const Username = localStorage.getItem("username")
    const HOST_PORT = IMAGE_HOST.IMAGE_HOST

    const [Count, setCount] = useState(1);
    // const [indexPage, setIndexPage] = useState(1);
    const [activePage, setActivePage] = useState()
    const [loading, setLoading] = useState(false);
    const [postIdEdit, setPostIdEdit] = useState();
    const [postIdEditVisible, setPostIdEditVisible] = useState();



    const [activeShow, setActiveShow] = useState(false);
    // const [statusId, setStatusId] = useState();

    const [post, setPost] = useState(["not"])
    const [postById, setPostById] = useState(["not"])

    const [userIdData, setUserIdData] = useState(["not"])

    const { t } = useTranslation();

    const { register, handleSubmit, reset } = useForm();
    const [state, setState] = useState({
        onMind: "",
        image: "",
    })
    const activeHandleClose = () => setActiveShow(false);

    const activeHandleShow = (id) => {
        setActiveShow(true);
        // setStatusId(id)
    }


    const activeOrder = async () => {
        await axios.post(`${PORT}/user/post/delete`, { postId: postIdEdit, }, { headers: { authorization: `Bearer ${TOKEN}` } })
            .then((res, err) => {
                LoadPost()
                // toast.success(`You succesfully  status`)
                activeHandleClose()
            })
    }




    const LoadPost = useCallback(async (pageNumber) => {
        await axios.post(`${PORT}/user/post/list`, { page: pageNumber, userId: id }, { headers: { authorization: `Bearer ${TOKEN}` } })
            .then((res) => {
                setPost(res.data.data.postDetails)
                setCount(res.data.data.totalPosts);

            })
    }, [PORT, TOKEN, id])

    const LoadPostById = useCallback(async (postIdEdit) => {
        setPostIdEdit(postIdEdit)
        await axios.post(`${PORT}/user/post/detail`, { postId: postIdEdit }, { headers: { authorization: `Bearer ${TOKEN}` } })
            .then((res) => {
                setPostById(res.data.data)
                setPostIdEditVisible(true)

                // setCount(res.data.data.totalPage);

            })
    }, [PORT, TOKEN])

    const LoadProfile = useCallback(async () => {
        await axios.post(`${PORT}/user/profile`, { userId: id }, { headers: { authorization: `Bearer ${TOKEN}` } })
            .then((res) => {

                setUserIdData(res.data.data)
            })
    }, [id, TOKEN, PORT])



    // const handelCommittee = (evt) => {
    //     let value = evt.target.value;
    //     setState({
    //         [evt.target.name]: value
    //     })
    // }
    const onImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            let reader = new FileReader();
            reader.onload = (e) => {
                setState({ image: e.target.result });
            };
            reader.readAsDataURL(event.target.files[0]);

        }
    }


    const ScrollFromTop = () => {
        window.scrollTo(0, 600)
    }


    const handlePageChange = (pageNumber) => {
        ScrollFromTop()
        LoadPost(pageNumber);
        // setIndexPage(pageNumber)
        setActivePage(pageNumber);
        setLoading(false);
    }

    useEffect(() => {
        LoadPost()
        LoadProfile()

    }, [LoadPost, LoadProfile])


    const onSubmit = (data) => {
        const imageFiles = document.getElementById('postImage').files[0];
        const fs = new FormData();
        fs.append('title', Username);
        fs.append('image', imageFiles);
        fs.append('description', data.description);
        axios.post(`${PORT}/user/post/create`, fs, { headers: { authorization: `Bearer ${TOKEN}` } })
            .then((res) => {
                LoadPost()
                reset()
                setState({ image: "" });

                toast.success("Post created success")
            })
    }

    const onSubmitUpdate = (data) => {
        setPostIdEditVisible(false)


        const imageFiles = document.getElementById('postImage').files.length;
        const fs = new FormData();
        fs.append('title', Username);
        fs.append('postId', postIdEdit);
        // fs.append('description', data.description);
        for (const [key] of Object.entries(data)) {
            data[key] = (data[key] === undefined || data[key] === null || data[key] === "") ? postById[key] : data[key];
            fs.append(key, data[key]);
        }
        if (imageFiles > 0) {
            const File = document.getElementById('postImage').files[0];
            fs.append("image", File);
        }

        axios.post(`${PORT}/user/post/update`, fs, { headers: { authorization: `Bearer ${TOKEN}` } })
            .then((res) => {
                LoadPost()
                reset()
                setState({ image: "" });
                toast.success("Post created success")
            })
    }


    return (
        <section className="committee">
            <div className="container">
                <div className="committee-profile">
                    <div className="cover-image" style={{ backgroundImage: `url(${ImageObj.SansthaTitle})` }}>
                        <div className="edit-btn d-mobile-none">
                            {
                                UserTokenId === id ?
                                    <div className="brown-button text-right" >
                                      
                                            <Link className="btn" to={`../editMemberForm/${userIdData._id}`} >Edit Profile</Link>

                                    </div> : ""
                            }

                        </div>
                        {/* <img aria-hidden="true" src={ImageObj.Hands} alt="Committee" /> */}
                    </div>
                    <div className="profile user-profile">
                        <div className="row">
                            <div className="col-md-4 col-sm-5">

                                <div className="profile-img">
                                    {userIdData.image ?
                                        <img src={`${HOST_PORT}/${userIdData.image}`} alt="" />
                                        : <img aria-hidden="true" src={ImageObj.UserBlank} alt="koli mambers" />
                                    }
                                </div>
                                <div className="user-info border radius">
                                    <ul>
                                        {userIdData.name ?
                                            <li>
                                                <span className="info-icon"><FaUser /></span>
                                                <p>{t('Member.Name')}: <span>{userIdData.name}</span></p>
                                            </li> : ""
                                        }
                                        {userIdData.work && !userIdData.designation?
                                            <li>
                                                <span className="info-icon"><FaBlackTie /></span>
                                                <p>{t('Member.Work2')} : <span> {userIdData.work} </span></p>
                                            </li> : ""
                                        }
                                        {userIdData.city ?
                                            <li>
                                                <span className="info-icon"><FaCity /></span>
                                                <p>{t('Member.City')} : <span> {userIdData.city} </span></p>
                                            </li> : ""
                                        }
                                        {userIdData.state ?
                                            <li>
                                                <span className="info-icon"><FaMapMarkedAlt /></span>
                                                <p> {t('Member.State')} : <span>  {userIdData.state}</span></p>
                                            </li> : ""
                                        }
                                        {userIdData.gender ?
                                            <li>
                                                <span className="info-icon"><FaUser /></span>
                                                <p> {t('Member.Gender')} : <span> {userIdData.gender} </span></p>
                                            </li> : ""
                                        }
                                        {/* {userIdData.dob ?
                                            <li>
                                                <span className="info-icon"><FaUser /></span>
                                                <p> {t('Member.Age')} : <span> {moment(userIdData.dob).format("DD/MM/YYYY")} </span></p>
                                            </li> : ""
                                        } */}
                                        {userIdData.designation ?
                                            <li>
                                                <span className="info-icon"><FaNetworkWired /></span>
                                                <p> {t('Servant.Designation')} : <span> {userIdData.designation} </span></p>
                                            </li> : ""
                                        }
                                        {userIdData.office_city ?
                                            <li>
                                                <span className="info-icon"><HiOfficeBuilding /></span>
                                                <p> {t('Servant.OfficeCity')} : <span> {userIdData.office_city} </span></p>
                                            </li> : ""
                                        }
                                        {userIdData.office_address ?
                                            <li>
                                                <span className="info-icon"><HiOfficeBuilding /></span>
                                                <p> {t('Servant.OfficeAddress')} : <span> {userIdData.office_address} </span></p>
                                            </li> : ""
                                        }
                                        {userIdData.office_state ?
                                            <li>
                                                <span className="info-icon"><MdLocationCity /></span>
                                                <p> {t('Servant.OfficeState')} : <span> {userIdData.office_state} </span></p>
                                            </li> : ""
                                        }
                                        {userIdData.politician_area ?
                                            <li>
                                                <span className="info-icon"><FaChartArea /></span>
                                                <p> {t('Politician.PoliticianArea')} : <span> {userIdData.politician_area} </span></p>
                                            </li> : ""
                                        }
                                        {userIdData.politician_party ?

                                            <li>
                                                <span className="info-icon"><HiOutlineUserGroup /></span>
                                                <p> {t('Politician.PoliticianParty')} : <span> {userIdData.politician_party} </span></p>
                                            </li> : ""
                                        }

                                    </ul>

                                </div>
                                <div className="edit-btn d-mobile-block">
                                    {
                                        UserTokenId === id ?
                                            <div className="brown-button text-right" >
                                              
                                            <Link className="btn" to={`../editMemberForm/${userIdData._id}`} >Edit Profile</Link>
                                                               

                                            </div> : ""
                                    }

                                </div>

                            </div>
                            <div className="col-md-8 col-sm-7">
                                <div className="main-profile-tab ">
                                    <div className="profile-con">
                                        <div className="row">
                                            <div className="col-sm-6 sm-none">
                                                {/* <div className="head text-left">
                                                    <h2>{userIdData.name}</h2>
                                                </div> */}
                                            </div>

                                        </div>
                                    </div>
                                    <div className="profile-tab tab-design">
                                        <Tabs defaultActiveKey="home" id="uncontrolled-tab-example">
                                            <Tab eventKey="home" title="Home" >
                                                {
                                                    UserTokenId === id ?

                                                        <form onSubmit={!postIdEditVisible ? handleSubmit(onSubmit) : handleSubmit(onSubmitUpdate)}>

                                                            <div className="status-field">
                                                                <div className={userIdData.post_status === true ? "status-box border radius" : "d-none"}>
                                                                    <div className="status-area second">
                                                                        <div className="status-profile">

                                                                            {userIdData.image ?
                                                                                <img src={`${HOST_PORT}/${userIdData.image}`} alt="" />
                                                                                : <img aria-hidden="true" src={ImageObj.UserBlank} alt="koli mambers" />
                                                                            }

                                                                        </div>
                                                                        <div className="status-textarea">
                                                                            <textarea defaultValue={postIdEditVisible ? postById.description : ""} placeholder={`Whats On Your Mind, ${userIdData.name} ?`} className="form-control" {...register('description', { required: false })} required={postIdEditVisible ? "false" : "true"}></textarea>
                                                                        </div>
                                                                    </div>
                                                                    <div className="uploded-img theme-scroll">
                                                                        {
                                                                            !postIdEditVisible || state.image ?
                                                                                state.image ?
                                                                                    <img alt="" aria-hidden="true"
                                                                                        src={state.image}
                                                                                    /> : "" :
                                                                                postById.image ?
                                                                                    <img src={`${HOST_PORT}/${postById.image}`} alt="" /> : ""
                                                                        }
                                                                    </div>

                                                                    <div className="upload-file d-flex border-top">

                                                                        <div className="file-icon">
                                                                            {
                                                                                userIdData.post_status === true ?

                                                                                    <>
                                                                                        <FcGallery />
                                                                                        <input onChange={onImageChange} type="file" className="form-control" id="postImage" />
                                                                                    </>
                                                                                    : ""
                                                                            }
                                                                        </div>
                                                                        <div className="file-submit">
                                                                            <div className="brown-button sbm-btn text-right">
                                                                                <button type="submit" className="btn">Post</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>

                                                        : ""

                                                }

                                                {


                                                    post?.filter((val) => {
                                                        if (String(val && val.user !== undefined ? val.user["_id"] : "N/A") === id) {
                                                            return val
                                                        } else {
                                                            return false
                                                        }
                                                    }).map((postdata, i) => (

                                                        <div className="status-field" key={i + 1}>
                                                            <div className="status-box border radius">
                                                                <div className="top-prof-post">
                                                                    <div className="post-header">
                                                                        <div className="status-area">
                                                                            <div className="status-profile">
                                                                                {userIdData.image ?
                                                                                    <img src={`${HOST_PORT}/${userIdData.image}`} alt="" />
                                                                                    : <img aria-hidden="true" src={ImageObj.UserBlank} alt="" />
                                                                                }
                                                                            </div>
                                                                            <div className="profile-post">
                                                                                <h3>{postdata && postdata.user !== undefined ? postdata.user["name"] : "N/A"}</h3>
                                                                                <p>{postdata && postdata.user !== undefined ? postdata.user["type"] : "N/A"}</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="right-post-box">
                                                                            <span className="date-box">{moment(postdata.createdAt).format("DD/MM/YYYY")}</span>
                                                                            {

                                                                                (UserTokenId === String(postdata && postdata.user !== undefined ? postdata.user["_id"] : "N/A")) ?
                                                                                    <div className="dropdown-box">

                                                                                        <span className="dropdown-side">
                                                                                            <Dropdown>
                                                                                                <Dropdown.Toggle className="btn" id="dropdown-basic">
                                                                                                    <FaEllipsisV />
                                                                                                </Dropdown.Toggle>

                                                                                                <Dropdown.Menu>
                                                                                                    <Dropdown.Item onClick={() => activeHandleShow(postdata._id)}>Delete</Dropdown.Item>
                                                                                                    <Dropdown.Item onClick={() => LoadPostById(postdata._id)}>Edit</Dropdown.Item>




                                                                                                </Dropdown.Menu>

                                                                                            </Dropdown>

                                                                                        </span>
                                                                                    </div> : ""
                                                                            }
                                                                        </div>

                                                                    </div>
                                                                    <div className="status-textarea">
                                                                        <p>{postdata.description}</p>

                                                                        <img src={`${HOST_PORT}/${postdata.image}`} alt="" />

                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    ))
                                                }
                                                {
                                                    (Count > 50) ?
                                                        <div className='pagination-top'>
                                                            <Pagination
                                                                activePage={activePage}
                                                                itemsCountPerPage={10}
                                                                totalItemsCount={Count}
                                                                pageRangeDisplayed={10}
                                                                onChange={handlePageChange}
                                                            />
                                                        </div> : ""
                                                }
                                            </Tab>
                                            <Tab disabled eventKey="profile" title="Profile">
                                                frfefrff
                                            </Tab>
                                            <Tab disabled eventKey="contact" title="Contact">
                                                frfefrff
                                            </Tab>
                                        </Tabs>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* active modal */}
            <Modal className="status-modal" animation={false} show={activeShow} onHide={activeHandleClose}>

                <Modal.Body>Are you sure you want to change the order status to    ?</Modal.Body>
                <Modal.Footer>
                    <div className="theme-btn btn-group-right text-right">
                        <Button variant="secondary" onClick={activeOrder}>
                            Yes
                        </Button>
                        <Button variant="primary" onClick={activeHandleClose}>
                            No
                        </Button>
                    </div>

                </Modal.Footer>
            </Modal>
            {/* active modal end */}
        </section>
    )
}


export default Profile;
