export const  TOGGLE_VALUE = "TOGGLE_VALUE";
export const  TOGGLE_REMOVE = "TOGGLE_REMOVE";
export const  TOASTER = "TOASTER";
export const  TOASTERERR = "TOASTERERR";
export const  EVENTNAME = "EVENTNAME";
export const  USER_POST_ID = "USER_POST_ID";
export const  AUTH_HOC = "AUTH_HOC";
export const  LOGIN_MODAL_TRUE = "LOGIN_MODAL_TRUE";
export const  SIGNUP_MODAL_TRUE = "SIGNUP_MODAL_TRUE";
export const  USER_LOGIN = "USER_LOGIN";
export const  PAGE_SCROLL = "PAGE_SCROLL";









