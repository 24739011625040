import axios from "axios"
import React, { useEffect, useState } from "react"
import PORTNUMBER from '../../../api/api'
import { useNavigate } from "react-router-dom"
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from 'react-i18next';
// Import Datepicker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch } from 'react-redux';
import { toasterValue, toasterErrValue } from '../../../redux/actions/action'
import FormValidation from '../../../formValidation/formValidation'
import { ImageObj } from "../../../assets/images/images";
import { Helmet } from "react-helmet";

const MarriageData = () => {


    const PORT = PORTNUMBER.PORTNUMBER
    const TOKEN = localStorage.getItem("token")
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [language, setLanguage] = useState(false);
    const [imageTypeErr, setImageTypeErr] = useState("")
    const [submitDisabled, setSubmitDisabled] = useState(null)
    const FormValidationNew = FormValidation

    const [state, setState] = useState({
        onMind: "",
        image: "",
    })



    const { t } = useTranslation();


    const { i18n } = useTranslation();
    function HandelClick(lang) {
        i18n.changeLanguage(lang);
        setLanguage(!language)

    }



    const { control, register, handleSubmit } = useForm();

    const onSubmit = (data) => {

        const imageFiles = document.getElementById('marriageImage').files[0];
        const fs = new FormData();
        for (var key in data) {
            fs.append(key, data[key]);
        }
        fs.append("image", state.image);
        axios.post(`${PORT}/user/marriage/create`, fs, { headers: { authorization: `Bearer ${TOKEN}` } })
            .then((res) => {
                
                if (res.data.success === true) {
                    dispatch(toasterValue("Marriage application created"))
                    navigate("/matrimonial")
                } else {
                    dispatch(toasterErrValue("Marriage application not created"))

                }

            })
    }
    const onImageChange = (event) => {
        var fileName = event.target.value;
        var dot = fileName.lastIndexOf(".") + 1;
        var extFile = fileName.substr(dot, fileName.length).toLowerCase();
        if (extFile == "jpg" || extFile == "jpeg" || extFile == "png") {
            if (event.target.files && event.target.files[0]) {
                let reader = new FileReader();
                reader.onload = (e) => {
                    setState({ image: e.target.result });
                    setImageTypeErr("")
                    setSubmitDisabled(false)
                };
                reader.readAsDataURL(event.target.files[0]);

            }
        } else {
            setSubmitDisabled(true)
            setImageTypeErr("Only jpg, png and jpeg image files allowed!")
        }

    }

    useEffect(() => {
        FormValidationNew()
    }, [FormValidationNew])
    return (
        <React.Fragment>
             <Helmet>
                <title>Koli Samaj Marriage Form</title>
                <meta name="description" content="Koli samaj matrimonial register here To find best partner" />
                <meta name="keywords" content="Application form koli matrimonial" />
                <meta name="author" content="mahendra koli" />
                <link rel="canonical" href="https://www.kolisamajvikashsanstha.com/marriageForm" />
            </Helmet>
       
        <section className="app-form sec padding">
            <div className="container ">
                <div className="application">
                    <div className="touch-head app">
                        <h4>Marriage Application Form</h4>
                        <p>{"Please use english words"}</p>
                    </div>
                    <div className="touch-form app">
                        <form onSubmit={handleSubmit(onSubmit)} className="needs-validation" noValidate>
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="information-user d-flex">
                                        <div className="info-image">
                                            {state.image ?
                                                <img src={state.image} alt="" />
                                                : <img aria-hidden="true" src={ImageObj.UserBlank} alt="Committee" />
                                            }
                                        </div>
                                        <h3>{!state.image ? "Upload Image" : "Change Image"} <input id="marriageImage" name="image" onChange={onImageChange} type="file" className="form-control" />
                                        <div className="form-text text-muted">
                                            {imageTypeErr}
                                        </div>
                                        </h3>
                                        {/* <h4>Remove</h4> */}
                                    </div>
                                </div>
                                {/* <div className="col-sm-6">
                                    <div className="brown-button lang-btn">
                                        {
                                            language ? <button onClick={() => HandelClick('hi')} className="btn">हिंदी</button> : <button onClick={() => HandelClick('en')} className="btn">English</button>
                                        }
                                    </div>

                                </div> */}
                            </div>
                            <div className="col-sm-12 ">
                                <div className="row ">
                                    <div className="col-sm-12">
                                        <h3 className="form-h">{t('Marriage_Data.PersonalDetails.Heading')}</h3>
                                    </div>
                                    <div className="col-sm-6 ">
                                        <div className="form-group ">
                                            <label>{t('Marriage_Data.PersonalDetails.FullName')}<sup>*</sup></label>
                                            <input type="text " name="full_name" {...register('full_name', { required: false })} className="form-control " placeholder={t('Marriage_Data.PersonalDetails.FullName')} id="validationCustomTwo8" required />
                                            <div className="invalid-feedback">
                                                Please Fill Your Name.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 ">
                                        <div className="form-group ">
                                            <label>{t('Marriage_Data.PersonalDetails.Cast')}<sup>*</sup></label>
                                            <input type="text " name="caste"  {...register('caste', { required: false })} className="form-control " placeholder={t('Marriage_Data.PersonalDetails.Cast')} id="validationCustomThree9" required />
                                            <div className="invalid-feedback">
                                                Please Fill Your Cast.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 ">
                                        <div className="form-group">
                                            <label>{t('Marriage_Data.PersonalDetails.Marital_Status.head')}<sup>*</sup></label>
                                            <select className="form-select form-control"   {...register('marital_status', { required: false })} aria-label="Default select example" id="validationCustomFour11" required >
                                                <option value="" className="disabled-value d-none">{t('Marriage_Data.PersonalDetails.Marital_Status.head')}</option>
                                                <option value={t('Marriage_Data.PersonalDetails.Marital_Status.Status1')}>{t('Marriage_Data.PersonalDetails.Marital_Status.Status1')}</option>
                                                <option value={t('Marriage_Data.PersonalDetails.Marital_Status.Status2')}>{t('Marriage_Data.PersonalDetails.Marital_Status.Status2')}</option>
                                                <option value={t('Marriage_Data.PersonalDetails.Marital_Status.Status3')}>{t('Marriage_Data.PersonalDetails.Marital_Status.Status3')}</option>
                                                <option value={t('Marriage_Data.PersonalDetails.Marital_Status.Status4')}>{t('Marriage_Data.PersonalDetails.Marital_Status.Status4')}</option>
                                                <option value={t('Marriage_Data.PersonalDetails.Marital_Status.Status5')}>{t('Marriage_Data.PersonalDetails.Marital_Status.Status5')}</option>
                                                <option value={t('Marriage_Data.PersonalDetails.Marital_Status.Status6')}>{t('Marriage_Data.PersonalDetails.Marital_Status.Status6')}</option>


                                            </select>
                                            <div className="invalid-feedback">
                                                Please Select Your Status.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 ">
                                        <div className="form-group">
                                            <label>{t('Marriage_Data.PersonalDetails.Birth_date')}<sup>*</sup></label>
                                            <Controller id="validationCustomFive9"
                                                control={control}
                                                name='dob'
                                                render={({ field }) => (
                                                    <DatePicker
                                                        placeholderText={t('Marriage_Data.PersonalDetails.Birth_date')}
                                                        onChange={(date) => field.onChange(date)}
                                                        maxDate={new Date()}
                                                        selected={field.value}
                                                    />
                                                )}
                                            />
                                            <div className="invalid-feedback">
                                                Please select date.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 ">
                                        <div className="form-group">
                                            <label>{t('Marriage_Data.PersonalDetails.Height.head')}<sup>*</sup></label>
                                            <select className="form-select form-control" {...register('height', { required: false })} aria-label="Default select example" id="validationCustomSix11" required >
                                                <option value="" className="disabled-value d-none">{t('Marriage_Data.PersonalDetails.Height.head')}</option>
                                                <option value={t('Marriage_Data.PersonalDetails.Height.height1')}>{t('Marriage_Data.PersonalDetails.Height.height1')}</option>
                                                <option value={t('Marriage_Data.PersonalDetails.Height.height2')}>{t('Marriage_Data.PersonalDetails.Height.height2')}</option>
                                                <option value={t('Marriage_Data.PersonalDetails.Height.height3')}>{t('Marriage_Data.PersonalDetails.Height.height3')}</option>
                                                <option value={t('Marriage_Data.PersonalDetails.Height.height4')}>{t('Marriage_Data.PersonalDetails.Height.height4')}</option>
                                                <option value={t('Marriage_Data.PersonalDetails.Height.height5')}>{t('Marriage_Data.PersonalDetails.Height.height5')}</option>
                                                <option value={t('Marriage_Data.PersonalDetails.Height.height6')}>{t('Marriage_Data.PersonalDetails.Height.height6')}</option>

                                                <option value={t('Marriage_Data.PersonalDetails.Height.height7')}>{t('Marriage_Data.PersonalDetails.Height.height7')}</option>
                                                <option value={t('Marriage_Data.PersonalDetails.Height.height8')}>{t('Marriage_Data.PersonalDetails.Height.height8')}</option>
                                                <option value={t('Marriage_Data.PersonalDetails.Height.height9')}>{t('Marriage_Data.PersonalDetails.Height.height9')}</option>
                                                <option value={t('Marriage_Data.PersonalDetails.Height.height10')}>{t('Marriage_Data.PersonalDetails.Height.height10')}</option>
                                                <option value={t('Marriage_Data.PersonalDetails.Height.height11')}>{t('Marriage_Data.PersonalDetails.Height.height11')}</option>
                                                <option value={t('Marriage_Data.PersonalDetails.Height.height12')}>{t('Marriage_Data.PersonalDetails.Height.height12')}</option>

                                                <option value={t('Marriage_Data.PersonalDetails.Height.height13')}>{t('Marriage_Data.PersonalDetails.Height.height13')}</option>
                                                <option value={t('Marriage_Data.PersonalDetails.Height.height14')}>{t('Marriage_Data.PersonalDetails.Height.height14')}</option>
                                                <option value={t('Marriage_Data.PersonalDetails.Height.height15')}>{t('Marriage_Data.PersonalDetails.Height.height15')}</option>
                                                <option value={t('Marriage_Data.PersonalDetails.Height.height16')}>{t('Marriage_Data.PersonalDetails.Height.height16')}</option>
                                                <option value={t('Marriage_Data.PersonalDetails.Height.height17')}>{t('Marriage_Data.PersonalDetails.Height.height17')}</option>
                                                <option value={t('Marriage_Data.PersonalDetails.Height.height18')}>{t('Marriage_Data.PersonalDetails.Height.height18')}</option>

                                                <option value={t('Marriage_Data.PersonalDetails.Height.height19')}>{t('Marriage_Data.PersonalDetails.Height.height19')}</option>
                                                <option value={t('Marriage_Data.PersonalDetails.Height.height20')}>{t('Marriage_Data.PersonalDetails.Height.height20')}</option>
                                                <option value={t('Marriage_Data.PersonalDetails.Height.height21')}>{t('Marriage_Data.PersonalDetails.Height.height21')}</option>
                                                <option value={t('Marriage_Data.PersonalDetails.Height.height22')}>{t('Marriage_Data.PersonalDetails.Height.height22')}</option>
                                                <option value={t('Marriage_Data.PersonalDetails.Height.height23')}>{t('Marriage_Data.PersonalDetails.Height.height23')}</option>
                                                <option value={t('Marriage_Data.PersonalDetails.Height.height24')}>{t('Marriage_Data.PersonalDetails.Height.height24')}</option>

                                                <option value={t('Marriage_Data.PersonalDetails.Height.height25')}>{t('Marriage_Data.PersonalDetails.Height.height25')}</option>
                                                <option value={t('Marriage_Data.PersonalDetails.Height.height26')}>{t('Marriage_Data.PersonalDetails.Height.height26')}</option>
                                                <option value={t('Marriage_Data.PersonalDetails.Height.height27')}>{t('Marriage_Data.PersonalDetails.Height.height27')}</option>
                                                <option value={t('Marriage_Data.PersonalDetails.Height.height28')}>{t('Marriage_Data.PersonalDetails.Height.height28')}</option>
                                                <option value={t('Marriage_Data.PersonalDetails.Height.height29')}>{t('Marriage_Data.PersonalDetails.Height.height29')}</option>
                                                <option value={t('Marriage_Data.PersonalDetails.Height.height30')}>{t('Marriage_Data.PersonalDetails.Height.height30')}</option>
                                                <option value={t('Marriage_Data.PersonalDetails.Height.height31')}>{t('Marriage_Data.PersonalDetails.Height.height31')}</option>



                                            </select>
                                            <div className="invalid-feedback">
                                                Please Select Your Height.
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="col-sm-6 ">
                                        <div className="form-group">
                                            <label>{t('Marriage_Data.PersonalDetails.Blood_Group.head')}<sup>*</sup></label>
                                            <select className="form-select form-control" {...register('blood_group', { required: false })} aria-label="Default select example" id="validationCustomSeven11" >
                                                <option value="" className="disabled-value d-none">{t('Marriage_Data.PersonalDetails.Blood_Group.head')}</option>
                                                <option value={t('Marriage_Data.PersonalDetails.Blood_Group.Group_1')}>{t('Marriage_Data.PersonalDetails.Blood_Group.Group_1')}</option>
                                                <option value={t('Marriage_Data.PersonalDetails.Blood_Group.Group_2')}>{t('Marriage_Data.PersonalDetails.Blood_Group.Group_2')}</option>
                                                <option value={t('Marriage_Data.PersonalDetails.Blood_Group.Group_3')}>{t('Marriage_Data.PersonalDetails.Blood_Group.Group_3')}</option>
                                                <option value={t('Marriage_Data.PersonalDetails.Blood_Group.Group_4')}>{t('Marriage_Data.PersonalDetails.Blood_Group.Group_4')}</option>
                                                <option value={t('Marriage_Data.PersonalDetails.Blood_Group.Group_5')}>{t('Marriage_Data.PersonalDetails.Blood_Group.Group_5')}</option>
                                                <option value={t('Marriage_Data.PersonalDetails.Blood_Group.Group_6')}>{t('Marriage_Data.PersonalDetails.Blood_Group.Group_6')}</option>
                                            </select>
                                            <div className="invalid-feedback">
                                                Please Select Your Blood Group.
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="col-sm-6 ">
                                        <div className="form-group">
                                            <label>{t('Marriage_Data.PersonalDetails.Complexion.head')}<sup>*</sup></label>
                                            <select className="form-select form-control"  {...register('complexion', { required: false })} aria-label="Default select example" id="validationCustomEight11" >
                                                <option value="" className="disabled-value d-none">{t('Marriage_Data.PersonalDetails.Complexion.head')}</option>
                                                <option value={t('Marriage_Data.PersonalDetails.Complexion.Complexion_1')}>{t('Marriage_Data.PersonalDetails.Complexion.Complexion_1')}</option>
                                                <option value={t('Marriage_Data.PersonalDetails.Complexion.Complexion_2')}>{t('Marriage_Data.PersonalDetails.Complexion.Complexion_2')}</option>
                                                <option value={t('Marriage_Data.PersonalDetails.Complexion.Complexion_3')}>{t('Marriage_Data.PersonalDetails.Complexion.Complexion_3')}</option>
                                                <option value={t('Marriage_Data.PersonalDetails.Complexion.Complexion_4')}>{t('Marriage_Data.PersonalDetails.Complexion.Complexion_4')}</option>
                                            </select>
                                            <div className="invalid-feedback">
                                                Please Select Your Complexion.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 ">
                                        <div className="form-group">
                                            <label>{t('Marriage_Data.Gender')}</label>
                                            <select name="gender" {...register('gender', { required: true })} className="form-control form-select" id="validationCustomThree" required >
                                                <option value="" className="disabled-value d-none" >{t('Marriage_Data.Gender')}</option>
                                                <option value={t('Marriage_Data.Male')}>{t('Marriage_Data.Male')}</option>
                                                <option value={t('Marriage_Data.Female')}>{t('Marriage_Data.Female')}</option>
                                            </select>
                                            <div className="invalid-feedback">
                                                Select Your Gender.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 ">
                                        <div className="form-group ">
                                            <label>{t('Marriage_Data.PersonalDetails.age')}<sup>*</sup></label>
                                            <input type="text " name="age"  {...register('age', { required: false })} className="form-control " placeholder={t('Marriage_Data.PersonalDetails.age')} id="validationCustom29" required />
                                            <div className="invalid-feedback">
                                                Please Fill Your age.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 ">
                                        <div className="form-group ">
                                            <label>{t('Marriage_Data.PersonalDetails.state')}<sup>*</sup></label>
                                            <input onChange={(e) => {
                                                let value = e.target.value

                                                value = value.replace(/[^A-Za-z]/ig, '')

                                                this.setState({
                                                    value,
                                                })
                                            }} type="text " name="state"  {...register('state', { required: false })} className="form-control " placeholder={t('Marriage_Data.PersonalDetails.state')} id="validationCustom31" required />
                                            <div className="invalid-feedback">
                                                Please Fill Your state.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 ">
                                        <div className="form-group ">
                                            <label>{t('Marriage_Data.PersonalDetails.city')}<sup>*</sup></label>
                                            <input onChange={(e) => {
                                                let value = e.target.value

                                                value = value.replace(/[^A-Za-z]/ig, '')

                                                this.setState({
                                                    value,
                                                })
                                            }} type="text " name="city"  {...register('city', { required: false })} className="form-control " placeholder={t('Marriage_Data.PersonalDetails.city')} id="validationCustom30" required />
                                            <div className="invalid-feedback">
                                                Please Fill Your city.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-12">
                                        <h3 className="form-h">{t('Marriage_Data.Horoscope_Details.Heading')}</h3>
                                    </div>
                                    {/* <div className="col-sm-6 ">
                                        <div className="form-group ">
                                            <label>{t('Marriage_Data.Horoscope_Details.Details')}<sup>*</sup></label>
                                            <input type="text " name="time_of_birth" {...register('time_of_birth', { required: false })} className="form-control " placeholder={t('Marriage_Data.Horoscope_Details.Details')} id="validationCustomNine4" />
                                            <div className="invalid-feedback">
                                                Please Fill Your Time Of Birth.
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="col-sm-6 ">
                                        <div className="form-group ">
                                            <label>{t('Marriage_Data.Horoscope_Details.Details2')}<sup>*</sup></label>
                                            <input type="text " name="place_of_birth" {...register('place_of_birth', { required: false })} className="form-control " placeholder={t('Marriage_Data.Horoscope_Details.Details2')} id="val_ten4" required />
                                            <div className="invalid-feedback">
                                                Please Fill Your Place Of Birth.
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="col-sm-6 ">
                                        <div className="form-group">
                                            <label>{t('Marriage_Data.Horoscope_Details.Mangal.head')}<sup>*</sup></label>
                                            <select className="form-select form-control"  {...register('mangal', { required: false })} aria-label="Default select example" id="validationCustomEleven" required >
                                                <option value="" className="disabled-value d-none">{t('Marriage_Data.Horoscope_Details.Mangal.head')}</option>
                                                <option value={t('Marriage_Data.Horoscope_Details.Mangal.yes')}>{t('Marriage_Data.Horoscope_Details.Mangal.yes')}</option>
                                                <option value={t('Marriage_Data.Horoscope_Details.Mangal.no')}>{t('Marriage_Data.Horoscope_Details.Mangal.no')}</option>

                                            </select>
                                            <div className="invalid-feedback">
                                                Please Select Mangal.
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="col-sm-6 ">
                                        <div className="form-group ">
                                            <label>{t('Marriage_Data.Horoscope_Details.Details3')}<sup>*</sup></label>
                                            <input type="text " name="gotra" {...register('gotra', { required: false })} className="form-control " placeholder={t('Marriage_Data.Horoscope_Details.Details3')} id="validationCustomTwelve6" required />
                                            <div className="invalid-feedback">
                                                Please Fill Your Gotra.
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-sm-12">
                                        <h3 className="form-h">{t('Marriage_Data.Education.Heading')}</h3>
                                    </div>
                                    <div className="col-sm-6 ">
                                        <div className="form-group ">
                                            <label>{t('Marriage_Data.Education.Detail_edu')}<sup>*</sup></label>
                                            <input type="text " name="education_level" {...register('education_level', { required: false })} className="form-control " placeholder={t('Marriage_Data.Education.Detail_edu')} id="validationCustomThirteen5" required />
                                            <div className="invalid-feedback">
                                                Please Fill Your Education Level.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 ">
                                        <div className="form-group ">
                                            <label>{t('Marriage_Data.Education.Detail_edu2')}<sup>*</sup></label>
                                            <input type="text " name="education_details" {...register('education_details', { required: false })} className="form-control " placeholder={t('Marriage_Data.Education.Detail_edu2')} id="validationCustomFour12teen" required />
                                            <div className="invalid-feedback">
                                                Please Fill Your Education Details.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 ">
                                        <div className="form-group ">
                                            <label>{t('Marriage_Data.Education.Detail_edu3')}<sup>*</sup></label>
                                            <input type="text " name="occupation" {...register('occupation', { required: false })} className="form-control " placeholder={t('Marriage_Data.Education.Detail_edu3')} id="validationCustomFifteen5" required />
                                            <div className="invalid-feedback">
                                                Please Fill Your Occupation.
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-sm-12">
                                        <h3 className="form-h">{t('Marriage_Data.Family_Background.Heading')}</h3>
                                    </div>

                                    <div className="col-sm-6 ">
                                        <div className="form-group">
                                            <label>{t('Marriage_Data.Family_Background.Detail1.head')}<sup>*</sup></label>
                                            <select className="form-select form-control"  {...register('father', { required: false })} aria-label="Default select example" id="validationCustomSixteen25" required >
                                                <option value="" className="disabled-value d-none">{t('Marriage_Data.Family_Background.Detail1.head')}</option>
                                                <option value={t('Marriage_Data.Family_Background.Detail1.yes')}>{t('Marriage_Data.Family_Background.Detail1.yes')}</option>
                                                <option value={t('Marriage_Data.Family_Background.Detail1.no')}>{t('Marriage_Data.Family_Background.Detail1.no')}</option>

                                            </select>
                                            <div className="invalid-feedback">
                                                Please Select Father Yes/No.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 ">
                                        <div className="form-group">
                                            <label>{t('Marriage_Data.Family_Background.Detail2.head')}<sup>*</sup></label>
                                            <select className="form-select form-control" {...register('mother', { required: false })} aria-label="Default select example" id="validationCustomSeventy18" required >
                                                <option value="" className="disabled-value d-none">{t('Marriage_Data.Family_Background.Detail2.head')}</option>
                                                <option value={t('Marriage_Data.Family_Background.Detail2.yes')}>{t('Marriage_Data.Family_Background.Detail2.yes')}</option>
                                                <option value={t('Marriage_Data.Family_Background.Detail2.no')}>{t('Marriage_Data.Family_Background.Detail2.no')}</option>

                                            </select>
                                            <div className="invalid-feedback">
                                                Please Select Mother Yes/No.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 ">
                                        <div className="form-group ">
                                            <label>{t('Marriage_Data.Family_Background.Detail3')}<sup>*</sup></label>
                                            <input type="text " name="father_name" {...register('father_name', { required: false })} className="form-control " placeholder={t('Marriage_Data.Family_Background.Detail3')} id="validationCustomEighteen" required />
                                            <div className="invalid-feedback">
                                                Please Fill Your Father Name.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 ">
                                        <div className="form-group ">
                                            <label>{t('Marriage_Data.Family_Background.Detail4')}<sup>*</sup></label>
                                            <input type="text " name="mother_name" {...register('mother_name', { required: false })} className="form-control " placeholder={t('Marriage_Data.Family_Background.Detail4')} id="validationCustomNinteen" required />
                                            <div className="invalid-feedback">
                                                Please Fill Your Mother Name.
                                            </div>
                                        </div>
                                    </div> <div className="col-sm-6 ">
                                        <div className="form-group ">
                                            <label>{t('Marriage_Data.Family_Background.Detail5')}<sup>*</sup></label>
                                            <input type="text " name="total_brothers" {...register('total_brothers', { required: false })} className="form-control " placeholder={t('Marriage_Data.Family_Background.Detail5')} id="validationCustomTwenty" required />
                                            <div className="invalid-feedback">
                                                Please Add Your Total Brothers.
                                            </div>
                                        </div>
                                    </div> <div className="col-sm-6 ">
                                        <div className="form-group ">
                                            <label>{t('Marriage_Data.Family_Background.Detail6')}<sup>*</sup></label>
                                            <input type="text " name="married_brothers" {...register('married_brothers', { required: false })} className="form-control " placeholder={t('Marriage_Data.Family_Background.Detail6')} id="validationCustom21" required />
                                            <div className="invalid-feedback">
                                                Please Add Your Total Married Brothers.
                                            </div>
                                        </div>
                                    </div> <div className="col-sm-6 ">
                                        <div className="form-group ">
                                            <label>{t('Marriage_Data.Family_Background.Detail7')}<sup>*</sup></label>
                                            <input type="text " name="parents_contact_no" {...register('parents_contact_no', { required: false })} className="form-control " placeholder={t('Marriage_Data.Family_Background.Detail7')} id="validationCustom22" required />
                                            <div className="invalid-feedback">
                                                Please Enter Contact Number.
                                            </div>
                                        </div>
                                    </div> <div className="col-sm-6 ">
                                        <div className="form-group ">
                                            <label>{t('Marriage_Data.Family_Background.Detail8')}<sup>*</sup></label>
                                            <input type="text " name="total_sisters" {...register('total_sisters', { required: false })} className="form-control " placeholder={t('Marriage_Data.Family_Background.Detail8')} id="validationCustom23" required />
                                            <div className="invalid-feedback">
                                                Please Add Your Total Sisters.
                                            </div>
                                        </div>
                                    </div> <div className="col-sm-6 ">
                                        <div className="form-group ">
                                            <label>{t('Marriage_Data.Family_Background.Detail9')}<sup>*</sup></label>
                                            <input type="text " name="married_sisters" {...register('married_sisters', { required: false })} className="form-control " placeholder={t('Marriage_Data.Family_Background.Detail9')} id="validationCustom24" required />
                                            <div className="invalid-feedback">
                                                Please Add Your Total Married Sisters.
                                            </div>
                                        </div>
                                    </div> <div className="col-sm-6 ">
                                        <div className="form-group ">
                                            <label>{t('Marriage_Data.Family_Background.Detail10')}<sup>*</sup></label>
                                            <input type="text " name="residential_address" {...register('residential_address', { required: false })} className="form-control " placeholder={t('Marriage_Data.Family_Background.Detail10')} id="validationCustom25" required />
                                            <div className="invalid-feedback">
                                                Please Enter Your Address.
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="col-sm-6 ">
                                        <div className="form-group ">
                                            <label>{t('Marriage_Data.Family_Background.Detail12')}</label>
                                            <input type="text " name="father_occupation" {...register('father_occupation', { required: false })} className="form-control " placeholder={t('Marriage_Data.Family_Background.Detail12')} id="validationCustom27"  />
                                            <div className="invalid-feedback">
                                                Please Fill Your Father Occupation.
                                            </div>
                                        </div>
                                    </div>  */}
                                    {/* <div className="col-sm-6 ">
                                        <div className="form-group ">
                                            <label>{t('Marriage_Data.Family_Background.Detail13')}<sup>*</sup></label>
                                            <input type="text " name="maternal_uncle_place" {...register('maternal_uncle_place', { required: false })} className="form-control " placeholder={t('Marriage_Data.Family_Background.Detail13')} id="validationCustom28" />
                                            <div className="invalid-feedback">
                                                Please Fill Your Maternal Uncle Place.
                                            </div>
                                        </div>
                                    </div> */}
                                    {/* <div className="col-sm-6 ">
                                        <div className="form-group ">
                                            <label>{t('Marriage_Data.Family_Background.Detail15')}<sup>*</sup></label>
                                            <input type="text " name="expectations_from_partner" {...register('expectations_from_partner', { required: false })} className="form-control " placeholder={t('Marriage_Data.Family_Background.Detail15')} id="validationCustom30" />
                                            <div className="invalid-feedback">
                                                Please Fill Your Expectations From Partner.
                                            </div>
                                        </div>
                                    </div> */}

                                    <div className="col-md-12 ">
                                        <div className="brown-button submit ">
                                            <button disabled={submitDisabled} type="submit" className="btn mt-1">Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
        </React.Fragment>
    )
}

export default MarriageData;