import React, { useEffect } from 'react'
import { ImageObj } from '../../../assets/images/images';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

const GoutamBuddha = () => {
    const { t } = useTranslation();
    useEffect(() => {
        document.body.classList.add("choclate-bg");
        return (() => {
            document.body.classList.remove("choclate-bg");

        })
    }, [])


    return (
        <React.Fragment>
        <Helmet>
            <title>Gautama Buddha History</title>
            <meta name="description" content="Gautama Buddha, popularly known as the Buddha or Lord Buddha (also known as Siddhattha Gotama or Siddhārtha Gautama or Buddha Shakyamuni), was a Śramaṇa who lived in ancient India (c. 5th to 4th century BCE). He is regarded as the founder of the world religion of Buddhism," />
            <meta name="keywords" content="Gautama Buddha, Lord Buddha, Siddhattha Gotama, Buddha Shakyamuni,Buddhism, Buddhist, Gayatri Mantra" />
            <meta name="author" content="mahendra koli" />
            <link rel="canonical" href="https://www.kolisamajvikashsanstha.com/goutamBuddha" />
        </Helmet>
        <div className="container padding-mob-0">
            <div className="history-show-top bg" style={{ backgroundImage: `url(${ImageObj.ShowHisBg2})` }}>
                <div className="show-history-inner">
                    <div className="koli-history show-his">
                        <h2>{t('GoutamBuddh.titleOne')}</h2>
                        <p>{t('GoutamBuddh.summeryOne')}</p>
                        <p>{t('GoutamBuddh.summeryTwo')}</p>
                        <h2>{t('GoutamBuddh.titleTwo')}</h2>
                        <p>{t('GoutamBuddh.summeryThree')}</p>
                        <h2>{t('GoutamBuddh.titleThree')}</h2>
                        <p>{t('GoutamBuddh.summeryFour')}</p>
                        <h2>{t('GoutamBuddh.titleFour')}</h2>
                        <p>{t('GoutamBuddh.summeryFive')}</p>
                        <h2>{t('GoutamBuddh.titleFive')}</h2>
                        <p>{t('GoutamBuddh.summerySix')}</p>
                        <h2>{t('GoutamBuddh.titleSix')}</h2>
                        <p>{t('GoutamBuddh.summerySeven')}</p>
                        <h2>{t('GoutamBuddh.titleSeven')}</h2>
                        <p>{t('GoutamBuddh.summeryEight')}</p>
                        <h2>{t('GoutamBuddh.titleEight')}</h2>
                        <p>{t('GoutamBuddh.summeryNine')}</p>
                        <h2>{t('GoutamBuddh.titleNine')}</h2>
                        <p>{t('GoutamBuddh.summeryTen')}</p>
                        <div className="list">
                            <ul>
                                <li>{t('GoutamBuddh.summeryEleven')}</li>
                                <li>{t('GoutamBuddh.summeryTwelve')}</li>
                                <li>{t('GoutamBuddh.summeryThirteen')}</li>
                                <li>{t('GoutamBuddh.summeryFourteen')}</li>
                                <li>{t('GoutamBuddh.summeryFifteen')}</li>
                            </ul>
                        </div>
                        <h2>{t('GoutamBuddh.titleTen')}</h2>
                        <p>{t('GoutamBuddh.summerySixteen')}</p>
                    </div>
                </div>
            </div>
        </div>
        </React.Fragment>

    )
}

export default GoutamBuddha;