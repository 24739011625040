import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import axios from 'axios'


import { ImageObj } from '../../../assets/images/images'
import { Dropdown, Modal, Button } from 'react-bootstrap'
import CHANGEPASSWORD_API_ADMIN from '../../../api/api'
import { addToggle, authHocTrueValue, removeToggle, toasterValue } from '../../../redux/actions/action'
import { useDispatch, useSelector } from 'react-redux'
import { Outlet, useNavigate } from 'react-router-dom'

import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { GiSwordwoman } from 'react-icons/gi';



const HeaderAdmin = () => {
    const dispatch = useDispatch();
    const AdminToken = localStorage.getItem("AdminToken")
    const [show, setShow] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [passErr, setPassErr] = useState();
    const [currentpPassErr, setCurrentPassErr] = useState();

    const [visibilityNew, setVisibilityNew] = useState(true)
    const [visibility, setVisibility] = useState(true)
    const [visibilityConfirm, setVisibilityConfirm] = useState(true)

    const navigate = useNavigate()

    const handleClose = () => setShow(false);
    const handleClosePw = () =>{
        setShowPassword(false)
        setVisibilityNew(true)
        setVisibility(true)
        setVisibilityConfirm(true)
        reset()
    } 
    const handleShowPw = () => setShowPassword(true);

    const toggle = useSelector((state) => state.toggleReducers);

    const { register, handleSubmit, reset } = useForm();


    const pwVisibility = (value) => {
        setVisibility(value)
    }
    const pwVisibilityConfirm = (value) => {
        setVisibilityConfirm(value)
    }

    const pwVisibilityNew = (value) => {
        setVisibilityNew(value)
    }


    const LogOut = () => {
        dispatch(authHocTrueValue(false))
        localStorage.removeItem("AdminToken");
        navigate("/admin")
        dispatch(toasterValue("You have logged out successfully."))

    }

    const changePassword = (data, e) => {
        if (data.newPassword === data.confirm_password) {
            setPassErr("")
            axios.post(CHANGEPASSWORD_API_ADMIN.CHANGEPASSWORD_API_ADMIN, { password: data.password, newPassword: data.newPassword }, { headers: { authorization: `Bearer ${AdminToken}` } })
                .then((res) => {
                    if (res.data.success !== false) {
                        handleClosePw()
                      reset();
                        LogOut()
                        setCurrentPassErr("")
                       

                    } else {
                        setCurrentPassErr(res.data.message)
                    }
                })
        } else {
            setPassErr("Confirm password not matched")
        }

    }




    return (
        <React.Fragment>
        <header className="top-header admin-header">
            <div className="container-fluid">
                <div className="col-md-12 p-0">
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="top-search d-flex main-slide main-tog">
                            <div className="logo">
                                        <GiSwordwoman />
                                        <div className="logo-text">
                                            <h1>KOLI SAMAJ</h1>
                                            <p>VIKASH SANSTHA</p>
                                        </div>
                                    </div>
                                {toggle ?
                                    <div className="toggle-top" onClick={() => dispatch(addToggle(false))}>
                                        <div id="toggle" >
                                            <div className="one" />
                                            <div className="two" />
                                            <div className="three" />
                                        </div>
                                    </div> :
                                    <div className="toggle-top" onClick={() => dispatch(removeToggle(true))}>
                                        <div id="toggle" className="on">
                                            <div className="one" />
                                            <div className="two" />
                                            <div className="three" />
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="d-flex user-top">
                                <p className="hover-white">Welcome Admin</p>

                                <Dropdown className="theme-btn">
                                    <Dropdown.Toggle className="btn" id="dropdown-basic">
                                        <img className="dashboard-icon" src={ImageObj.MaleDoc} alt="side-img" />
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        {/* <Dropdown.Item  onClick={handleShow}>Update Profile</Dropdown.Item> */}
                                        <Dropdown.Item onClick={()=>handleShowPw()}>Change Password</Dropdown.Item>
                                        <Dropdown.Item onClick={()=>LogOut()}>Logout</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>

                            </div>
                        </div>
                    </div>
                </div>
                {/* modal edit */}
                <div className="modal-edit">
                    <Modal show={show} onHide={handleClose} className="edit-content">

                        <div className="max-new-second max-margin">
                            <div className="main-theme bg-theme border shadow">
                                <Modal.Header closeButton>
                                    <Modal.Title></Modal.Title>
                                </Modal.Header>
                                <section className="select-state">
                                    <div className="state-bor">
                                        <div className="log-in otp-num">
                                            <div className="select-steps-box">
                                                <div className="input-head">
                                                    <h2>Update Profile</h2>
                                                </div>
                                            </div>
                                            <div className="select-box-top pr-0">
                                                <div className="input-box">
                                                    <form>
                                                        <div className="col-12 p-0">
                                                            <div className="row">
                                                                <div className="col-sm-12">
                                                                    <div className="form-group">
                                                                        <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Name" required />
                                                                        <small id="emailHelp" className=" d-none form-text text-muted">We'll never share your email with anyone else.</small>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-12">
                                                                    <div className="form-group">
                                                                        <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Email" required />
                                                                        <small id="emailHelp" className="d-none form-text text-muted">We'll never share your email with anyone else.</small>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-12">
                                                                    <div className="form-group">
                                                                        <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Mobile" required />
                                                                        <small id="emailHelp" className="d-none form-text text-muted">We'll never share your email with anyone else.</small>
                                                                    </div>
                                                                </div>

                                                                <Modal.Footer>
                                                                    <div className="theme-btn btn-group-right">
                                                                        <Button className="btn" variant="secondary" onClick={handleClose}>
                                                                            Update
                                                                        </Button>
                                                                        <Button className="btn" variant="primary" onClick={handleClose}>
                                                                            Cancel
                                                                        </Button>
                                                                    </div>
                                                                </Modal.Footer>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>

                    </Modal>

                    {/* change password */}
                    <div className="modal-edit">
                        <Modal show={showPassword} onHide={handleClosePw} className="edit-content">

                            <div className="max-new-second max-margin">
                                <div className="main-theme bg-theme border shadow">
                                    <Modal.Header closeButton>
                                    <div className="select-steps-box">
                                                    <div className="input-head">
                                                        <h2>Change Password</h2>
                                                    </div>
                                                </div>
                                    </Modal.Header>
                                    <section className="select-state">
                                        <div className="state-bor">
                                            <div className="log-in otp-num">
                                                
                                                <div className="select-box-top pr-0">
                                                    <div className="input-box">
                                                        <form onSubmit={handleSubmit(changePassword)}>
                                                            <div className="col-12 p-0">
                                                                <div className="row">
                                                                    <div className="col-sm-12">
                                                                        <div className="form-group icon-position">
                                                                            <input type={visibility ? "password" : "text"} className="form-control"  {...register('password', { required: true })} placeholder="Current Password" required />
                                                                            {
                                                                                visibility ?
                                                                                    <span className="icon" onClick={() => pwVisibility(false)}>
                                                                                        <FaEye />
                                                                                    </span> :
                                                                                    <span className="icon" onClick={() => pwVisibility(true)}>
                                                                                        <FaEyeSlash />
                                                                                    </span>
                                                                            }
                                                                            <small id="emailHelp" className=" form-text text-muted">{currentpPassErr}</small>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sm-12">
                                                                        <div className="form-group icon-position">
                                                                            <input type={visibilityNew ? "password" : "text"} className="form-control"  {...register('newPassword', { required: true })} placeholder="New Password" required />
                                                                            {
                                                                                visibilityNew ?
                                                                                    <span className="icon" onClick={() => pwVisibilityNew(false)}>
                                                                                        <FaEye />
                                                                                    </span> :
                                                                                    <span className="icon" onClick={() => pwVisibilityNew(true)}>
                                                                                        <FaEyeSlash />
                                                                                    </span>
                                                                            }
                                                                            <small id="emailHelp" className="d-none form-text text-muted">We'll never share your email with anyone else.</small>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sm-12">
                                                                        <div className="form-group icon-position">
                                                                            <input type={visibilityConfirm ? "password" : "text"} className="form-control" {...register('confirm_password', { required: true })} placeholder="Confirm Password" required />
                                                                            {
                                                                                visibilityConfirm ?
                                                                                    <span className="icon" onClick={() => pwVisibilityConfirm(false)}>
                                                                                        <FaEye />
                                                                                    </span> :
                                                                                    <span className="icon" onClick={() => pwVisibilityConfirm(true)}>
                                                                                        <FaEyeSlash />
                                                                                    </span>
                                                                            }
                                                                            <small id="emailHelp" className=" form-text text-muted">{passErr}</small>
                                                                        </div>
                                                                    </div>

                                                                    <Modal.Footer>
                                                                        <div className="theme-btn btn-group-right">
                                                                            <Button type="submit" className="btn" variant="secondary" >
                                                                                Update
                                                                            </Button>
                                                                            <Button  type="reset" className="btn" variant="primary" onClick={handleClosePw}>
                                                                                Cancel
                                                                            </Button>
                                                                        </div>
                                                                    </Modal.Footer>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>

                        </Modal>
                    </div>
                </div>
            </div>
        </header>
        <Outlet/>
        </React.Fragment>
    )
}

export default HeaderAdmin;
