import React,{useEffect} from 'react'
import { ImageObj } from '../../../assets/images/images';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

const Mayadevi =()=>{
    const { t } = useTranslation();
    useEffect(() => {
        document.body.classList.add("choclate-bg");
        return(()=>{
        document.body.classList.remove("choclate-bg");

        })
   }, [])


    return(
        <React.Fragment>
        <Helmet>
            <title>Maya Devi History</title>
            <meta name="description" content="Mayadevi or Mahamaya was the mother of Gautam Buddha. He was born 7 km from Lumbini. It happened at the place of Maharaj Anjan and Maharani Yashodhara in the kingdom of Kolia, situated at a distance of . She probably died in 7 days after giving birth to Siddhartha (Gautam Buddha) and Siddhartha was raised by his sister Prajapati Gautami." />
            <meta name="keywords" content="Mayadevi, Mahamaya, Maharaj Anjan, Maharani Yashodhara, kingdom of Kolia, Prajapati Gautami" />
            <meta name="author" content="mahendra koli" />
            <link rel="canonical" href="https://www.kolisamajvikashsanstha.com/mayadevi" />
        </Helmet>
        <div className="container padding-mob-0">
        <div className="history-show-top bg" style={{backgroundImage: `url(${ImageObj.ShowHisBg2})`}}>
                <div className="show-history-inner">
                    <div className="koli-history show-his">
                    <h2>{t('mayaDevi.titleOne')}</h2>
                    <p>{t('mayaDevi.summeryOne')}</p>
                        
                    </div>
                </div>
            </div>
        </div>
        </React.Fragment>
    )
}

export default Mayadevi;