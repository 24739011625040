import React from 'react'
import { Link } from 'react-router-dom'
import { FaFacebookF, FaInstagramSquare, FaTwitter } from 'react-icons/fa';
import {  IoLogoWhatsapp } from 'react-icons/io';

import { ImageObj } from '../../../assets/images/images';




const Footer =()=>{
    return(
        <footer >
                <div className="container">
                    <div className="col-sm-12 p-0">
                        <div className="row">
                            <div className="col-md-3 col-sm-6">
                                <div className="footer-con mid">
                                
                                    <div className="owner">
                                       <div className="owner-img">
                                           <img src={ImageObj.Mahendra} alt="mahendra koli"/>
                                           <div className="social-new">
                                        <div className="badge-box">
                                            <a rel="noopener"  href="https://www.facebook.com/groups/1529385907489747" target="_blank" className="badge"><FaFacebookF /></a>
                                            <a rel="noopener noreferrer"  href="https://www.instagram.com/mahee_arya/" target="_blank" className="badge"><FaInstagramSquare /></a>
                                            <a rel="noopener noreferrer"  href="https://twitter.com/mahendr48502257" target="_blank" className="badge"><FaTwitter /></a>
                                        </div>
                                    </div>
                                        </div>
                                    </div>
                                    <h1 className='text-light fs-4 fw-bold text-center mt-3'>MAHENDRA KOLI</h1>
                                    <h5 className='text-light fs-6 fw-bold text-center '>Founder : KSVS</h5>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-6">
                                <div className="footer-con mid">
                                    <h3>More Info</h3>
                                    <div className="con-list">
                                        <ul>
                                            <li><Link to="/aboutUs">About Us</Link></li>
                                            <li><Link to={"#"}>Contact Us</Link></li>
                                            <li><Link to={"#"}>Help Poor</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-6">
                                <div className="footer-con mid">
                                    <h3>Company</h3>
                                    <div className="con-list">
                                        <ul>
                                            <li><Link to={"#"}>Privacy Policy</Link></li>
                                            <li><Link to={"#"}>Term Conditions</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 col-sm-6">
                                <div className="footer-con last mid">
                                    <div className="con-list ">
                                        <h3>Get in touch</h3>
                                        <ul>
                                            <p>Feel free to get in touch with us via email</p>
                                            <li><Link to={"#"} className="gmail" >kolisamajvikashsanstha@gmail.com <span className="d-block"><IoLogoWhatsapp/>7737813122</span></Link></li>
                                        </ul>
                                    </div>
                                    <div className="social-new">
                                        <div className="badge-box">
                                            <a rel="noopener" href="https://www.facebook.com/groups/184150693867997/" target="_blank" className="badge"><FaFacebookF /></a>
                                            <a rel="noopener" href="https://www.instagram.com/kolisamajvikashsanstha/" target="_blank" className="badge"><FaInstagramSquare /></a>
                                            <a rel="noopener" href="https://twitter.com/kolisamajvikas1" target="_blank" className="badge"><FaTwitter /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="copyright">
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="top-menu-bar">
                                    <div className="logo two">
                                        <img aria-hidden="true" alt="" src="assets/images/logo-2.png" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <nav>
                                    <Link to={"#"} >Copyright © koli samaj vikash sanstha 2020 All Rights Reserved.</Link>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
    )
}

export default Footer;