import React, { useEffect, useLayoutEffect } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate, useLocation } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';




// user authonticatin routes

import ResetMail from '../../components/user/auth/resetMail/resetMail'
import ResetPassword from '../../components/user/auth/resetPassword/resetPassword'
import ResetPasswordLink from '../../components/user/auth/resetPwLink/resetPasswordLink'


import Header from "../../common/user/header/header";
import Home from "../../components/user/home/home";
import PublicPost from "../../components/user/publicPost/publicPost";


import MarriageList from '../../components/user/marrigePages/marriageList/marriageList'
import ViewApplicant from '../../components/user/marrigePages/viewApplicant/viewApplicant'
import Donation from '../../components/user/donationPages/donation/donation'
// import History from '../../components/user/history'
import AllHistory from '../../components/user/allHistoryStore/allHistory'
import Success from '../../components/user/successPeople/successPeople'
import Profile from '../../components/user/viewProfile/profile/profile'
// import GovtEmployeeProfile from '../../components/user/viewProfile/govtEmployeeProfile/govtEmployeeProfile'
// import PoliticianProfile from '../../components/user/viewProfile/politicianProfile/politicianProfile'
// import MemberProfile from '../../components/user/viewProfile/memberProfile/memberProfile'


import Jobs from '../../components/user/jobs/jobs'
import MarriageData from '../../components/user/marrigeForm/marriageForm'
import MarriageDataEdit from '../../components/user/marrigeForm/marriageFormEdit'

// all member list
import Team from '../../components/user/allMembers/team'
import MemberList from '../../components/user/allMembers/members/membersList'
import GovtList from '../../components/user/allMembers/govtServant/govtList'
import PoliticiansList from '../../components/user/allMembers/politician/politicianList'





import AddMemberForm from '../../components/user/form/addMember/addMemberForm'
import EditMemberForm from '../../components/user/form/addMember/editMemberForm'

import PoliticianForm from '../../components/user/form/politicianForm/politicianForm'
import PoliticianFormEdit from '../../components/user/form/politicianForm/politicianFormEdit'
import GovtEmployeeEdit from '../../components/user/form/govtServentForm/govtEmployeeEdit'


// import MlaProfile from '../../components/user/mla-profile-id/mlaProfileId'

import GovtEmployeeForm from '../../components/user/form/govtServentForm/govtEmployeeForm'


// donors route

import ActiveDonors from '../../components/user/donors/activeDonors/activeDonors'
import AtTimeDonors from '../../components/user/donors/atTheTimeDonors/atTheTimeDonors'

// about us
import AboutUs from '../../components/user/aboutUs/aboutUs'

// show history
import TanaJi from '../../components/user/showHistory/tanaJi'
import JhalkariBai from '../../components/user/showHistory/jhalkariBbai'
import YashwantRaoMukne from '../../components/user/showHistory/mukne'
import RajaMandhata from '../../components/user/showHistory/rajaMandhata'
import GoutamBuddha from '../../components/user/showHistory/goutamBuddha'
import Mayadevi from '../../components/user/showHistory/mayaMahamaya'
import KanhojiAgre from '../../components/user/showHistory/kanhojiAgre'




import RequireAuth from "../requireAuth/requireAuth";
// import { useSelector } from "react-redux";



// eslint-disable-next-line import/no-anonymous-default-export
export default () => {

    // eslint-disable-next-line no-unused-vars
    // const scrollReducer = useSelector((state) => state.scrollReducer)

    const Wrapper = ({ children }) => {
        const location = useLocation();
        useLayoutEffect(() => {
            document.documentElement.scrollTo(0, 0);
        }, [location.pathname]);
        return children
    }

    return (

        <Router>
            <Wrapper>
                <RequireAuth>
                    <Route path="/" element={<Header />}>
                        <Route  index element={<Home />} />

                        <Route  path="resetmail" element={<ResetMail/>} />
                        <Route  path="resetPassword/:id" element={<ResetPassword/>} />
                        <Route  path="resetPasswordLink/:id" element={<ResetPasswordLink/>} />


                        <Route path="publicPost" element={<PublicPost />} />

                        <Route path="matrimonial" element={<MarriageList />} />
                        <Route path="viewApplicant/:id" element={<ViewApplicant />} />

                        <Route path="help" element={<Donation />} />
                        <Route path="aboutUs" element={<AboutUs />} />

                        {/* <Route  path="history" element={<History/>} /> */}
                        <Route path="allHistory" element={<AllHistory />} />
                        <Route path="Success-life" element={<Success />} />
                        <Route path="profile/:id" element={<Profile />} />
                    

                        {/* donors route */}

                        <Route path="activeDonors" element={<ActiveDonors />} />
                        <Route path="atTimeDonors" element={<AtTimeDonors />} />



                        {/* members route */}
                        <Route path="allMember" element={<Team />} />
                        <Route path="allMember/memberList" element={<MemberList />} />
                        <Route path="allMember/govtEmployeeList" element={<GovtList />} />
                        <Route path="allMember/politiciansList" element={<PoliticiansList />} />


                        <Route path="Jobs" element={<Jobs />} />
                        <Route path="addMemberForm" element={<AddMemberForm />} />
                        <Route path="editMemberForm/:id" element={<EditMemberForm />} />
    
                        <Route path="politicianForm" element={<PoliticianForm />} />
                        <Route path="politicianFormEdit/:id" element={<PoliticianFormEdit />} />

                        <Route path="matrimonial/:marriageForm" element={<MarriageData />} />
                        <Route path="MarriageDataEdit/:id" element={<MarriageDataEdit />} />
                        <Route path="govtEmployee" element={<GovtEmployeeForm />} />
                        <Route path="govtEmployeeEdit/:id" element={<GovtEmployeeEdit />} />
                        <Route path="tanaji" element={<TanaJi />} />
                        <Route path="jhalkariBai" element={<JhalkariBai />} />
                        <Route path="yashwantRaoMukne" element={<YashwantRaoMukne />} />
                        <Route path="rajaMandhata" element={<RajaMandhata />} />
                        <Route path="goutamBuddha" element={<GoutamBuddha />} />
                        <Route path="mayadevi" element={<Mayadevi />} />
                        <Route path="kanhojiAgre" element={<KanhojiAgre />} />

                        <Route path="*" element={<Navigate to="/" />} />
                    </Route>


                    {/* <Route path="/:id" element={<Home />} /> */}


                </RequireAuth>
            </Wrapper>
        </Router>


    )
}