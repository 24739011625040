import {AUTH_HOC,LOGIN_MODAL_TRUE,SIGNUP_MODAL_TRUE,USER_LOGIN,PAGE_SCROLL} from '../types/type'





const intialState  = false;



 export const  authHocValueReducer = (state = intialState, action)=>{
    switch(action.type){
      case AUTH_HOC: return state = action.payload
        default:
        return state;
    }
    
  };

  export const  loginModalReducer = (state = intialState, action)=>{
    switch(action.type){
      case LOGIN_MODAL_TRUE: return state = action.payload
        default:
        return state;
    }
    
  };

  export const  signUpModalReducer = (state = intialState, action)=>{
    switch(action.type){
      case SIGNUP_MODAL_TRUE: return state = action.payload
        default:
        return state;
    }
    
  };

  export const  userLoginReducer = (state = intialState, action)=>{
    switch(action.type){
      case USER_LOGIN: return state = action.payload
        default:
        return state;
    }
    
  };

  export const  scrollReducer = (state = intialState, action)=>{
    switch(action.type){
      case PAGE_SCROLL: return state = action.payload
        default:
        return state;
    }
    
  };