

import React, { useState, useEffect, useRef } from 'react'


import JoditEditor from "jodit-react";

import { Link, useParams, useNavigate } from "react-router-dom"
import { useForm } from 'react-hook-form';
import axios from 'axios';
import PORTNUMBER_ADMIN from '../../../../../api/api'
import 'react-toastify/dist/ReactToastify.css';


import { toasterValue } from '../../../../../redux/actions/action'
import { useDispatch } from 'react-redux';


const EditContent = () => {

    const dispatch = useDispatch()
    const editor = useRef(null)

    const config = {
        readonly: false // all options from https://xdsoft.net/jodit/doc/
    }




    const { key } = useParams()
    const PORT_ADMIN = PORTNUMBER_ADMIN.PORTNUMBER_ADMIN
    const AdminToken = localStorage.getItem("AdminToken")
    const navigate = useNavigate()
    const [AllContent, setAllContent] = useState(["not"])
    const [content, setContent] = useState("")


    const { register, handleSubmit } = useForm();

    const LoadContent = async () => {
        await axios.get(`${PORT_ADMIN}/cms/details`, { headers: { authorization: `Bearer ${AdminToken}` } })
    }

    useEffect(() => {
        axios.post(`${PORT_ADMIN}/cms/getPageById`, { pageId: key }, { headers: { authorization: `Bearer ${AdminToken}` } }).then((res, err) => {
            setAllContent(res.data.data);
        })
    }, [PORT_ADMIN, AdminToken, key])

    const onSubmit = (data) => {
        data.content = (data.content === null || data.content === undefined ? content : data.content  )
        for(const [key] of Object.entries(data)){
    
            data[key] = (data[key] === undefined || data[key] === null || data[key] === "") ? AllContent[key] : data[key]
        }


        axios.post(`${PORT_ADMIN}/cms/editPage`, { ...data, pageId: key }, { headers: { authorization: `Bearer ${AdminToken}` } })
            .then((res) => {
                LoadContent();
                dispatch(toasterValue("Content is updated successfully."))
                navigate("/admin/contentList")
            })



    };
    useEffect(() => {
        document.body.classList.add('remove-home-header', 'admin-dashboard');
        return () => {
            document.body.classList.remove('remove-home-header', 'admin-dashboard');

        }
    }, [])

    return (

        <div className="tables-field">

            <div className="project-table">
                <div className="col-12">
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="main-head">
                                <h4>Edit Content</h4>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="theme-btn text-right top-head">
                                <Link to="/contentManagement" className="btn">Back</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="table-order-box shadow">
                    <div className="tab-head">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            {


                                <div className="row">

                                    <div className="col-sm-6">

                                        <div className="form-group">
                                            <input type="text" className="form-control" name="pageName" defaultValue={AllContent.pageName}  {...register('pageName', { required: false })} placeholder="Title" id="contentTitle" required />
                                        </div>


                                    </div>
                                    <div className="col-sm-6">

                                        <div className="form-group">
                                            <input type="text" className="form-control" name="pageHeading" defaultValue={AllContent.pageHeading}  {...register('pageHeading', { required: false })} placeholder="Title" id="contentTitle" required />
                                        </div>


                                    </div>
                                    <div className="col-12">


                                        <JoditEditor
                                            className="form-control"
                                            name="content" {...register('content', { required: false })}
                                            ref={editor}
                                            value={AllContent.content}

                                            config={config}
                                            tabIndex={1} // tabIndex of textarea
                                            onBlur={newContent => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                                            onChange={newContent => { }}
                                        />
                                    </div>


                                    <div className="col-lg-12">
                                        <div className="theme-btn btn-group-right text-right">
                                            <button type="submit" className="btn">Submit</button>
                                            <button type="button" className="btn">Reset</button>
                                        </div>
                                    </div>
                                </div>

                            }
                        </form>
                    </div>
                </div>
            </div>

        </div>
    )
}
export default EditContent;
