import React from 'react'
import { Accordion, Card } from 'react-bootstrap'
import { NavLink, Outlet } from 'react-router-dom'
import { FaRegUser } from 'react-icons/fa';








const SideNav = () => {


  return (
    <React.Fragment>
      <aside className="property side-nav admin-sidenav">

        <div className="side-nav-menu">
          <div className="sec">
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  <ul>
                    <li className="deactive"><NavLink to="/admin/userManagement" ><span className="image-nav"><FaRegUser /></span><span className="img-active"><FaRegUser /></span>User Management <i className="fa fa-angle-right" aria-hidden="true" /></NavLink>
                    </li>

                  </ul>
                </Accordion.Header>

              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  <ul>
                    <li className="deactive"><NavLink to="/admin/topStudents" ><span className="image-nav"><FaRegUser /></span><span className="img-active"><FaRegUser /></span>Top Students<i className="fa fa-angle-right" aria-hidden="true" /></NavLink>
                    </li>

                  </ul>
                </Accordion.Header>

              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  <ul>
                    <li className="deactive"><NavLink to="/admin/addEvents" ><span className="image-nav"><FaRegUser /></span><span className="img-active"><FaRegUser /></span>Our Events<i className="fa fa-angle-right" aria-hidden="true" /></NavLink>
                    </li>

                  </ul>
                </Accordion.Header>

              </Accordion.Item>
              <Accordion.Item eventKey="3">
                <Accordion.Header>
                  <ul>
                    <li className="deactive"><NavLink to="/admin/societyNews" ><span className="image-nav"><FaRegUser /></span><span className="img-active"><FaRegUser /></span>Society News<i className="fa fa-angle-right" aria-hidden="true" /></NavLink>
                    </li>

                  </ul>
                </Accordion.Header>

              </Accordion.Item>
              <Accordion.Item eventKey="4">
                <Accordion.Header>
                  <ul>
                    <li className="deactive"><NavLink to="/admin/contentList" ><span className="image-nav"><FaRegUser /></span><span className="img-active"><FaRegUser /></span>ContentList<i className="fa fa-angle-right" aria-hidden="true" /></NavLink>
                    </li>

                  </ul>
                </Accordion.Header>

              </Accordion.Item>


            </Accordion>
          </div>
        </div>
      </aside>
      <Outlet />
    </React.Fragment>
  )
}

export default SideNav;