

    
    export const politician = {
        dots: false,
        nav: true,
        fade: true,
        infinite: true,
        speed: 200,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        pauseOnHover: true,
        responsive: [
            {
                breakpoint: 574,
                settings: {
                  
                    autoplay: false,
                }
            },
        ]
    };

    export const gallerySlider = {
        dots: false,
        nav: true,
        fade: true,
        infinite: true,
        speed: 200,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        pauseOnHover: true
    };
    export const homeBanner = {
        dots: true,
        nav: true,
        fade: false,
        infinite: true,
        speed: 200,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        pauseOnHover: true
    };



    export const centerSlider = {
        className: "center",
        centerMode: true,
        infinite: true,
        centerPadding: "33.4%",

        slidesToShow: 1,
        speed: 200,
        autoplay: false,
        pauseOnHover: true,
        arrow: true,
        dots: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    centerPadding: "2%",
                    centerMode: false,

                }
            }
        ]

    };





    export const comment = {
        dots: false,
        nav: true,
        infinite: true,
        speed: 200,
        slidesToShow: 2,
        slidesToScroll: 2,
        autoplay: true,
        pauseOnHover: true,
        arrow: true,

        responsive: [
            {
                breakpoint: 574,
                settings: {
                    variableWidth: false,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    autoplay: false,
                }
            },
        ]
    };

    export const gallary = {
        autoplay: true,
        infinite: true,
        slidesToShow: 4,
        speed: 500,
        rows: 2,
        fade: true,
        slidesPerRow: 4,
        responsive: [
            {
                breakpoint: 768,
                settings: {

                    slidesToShow: 1,
                    slidesPerRow: 3,


                }
            },
            {
                breakpoint: 574,
                settings: {
                    rows: 1,
                    slidesPerRow: 1,
                    slidesToScroll: 1,
                    slidesToShow: 1,


                }
            }
        ]
    };


    export const news = {
        speed: 5000,
        autoplay: true,
        autoplaySpeed: 0,
        centerMode: true,
        slidesToShow: 3,
        slidesToScroll: 3,
        variableWidth: true,
        infinite: true,
        initialSlide: 3,
        arrows: true,
        buttons: false,
        responsive: [
            {
                breakpoint: 574,
                settings: {
                    variableWidth: false,
                    slidesToShow: 2,
                    slidesToScroll: 2,

                }
            },
        ]
    };



    
    export const benifited = {
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
        infinite: true,
        arrows: true,
        dots: false,
        centerMode: true,
        centerPadding: "100px",
        responsive: [
            {
                breakpoint: 574,
                settings: {
                    centerMode: false,
                    centerPadding: "0px",
                    adaptiveHeight: false,
                }
            },

        ]
    };
