import {TOGGLE_VALUE,TOGGLE_REMOVE, TOASTER, TOASTERERR, EVENTNAME, USER_POST_ID,AUTH_HOC,LOGIN_MODAL_TRUE,SIGNUP_MODAL_TRUE,USER_LOGIN, PAGE_SCROLL} from '../types/type';



export const addToggle = (toggle) =>{
    return{
      type: TOGGLE_VALUE,
      payload: toggle,
    }
    
  }

  export const removeToggle = (toggle) =>{
    return{
      type: TOGGLE_REMOVE,
      payload: toggle,
    }
    
  }

  export const toasterValue = (toaster) =>{
    return{
      type: TOASTER,
      payload: toaster,
    }
  }

    export const toasterErrValue = (toasterErr) =>{
      return{
        type: TOASTERERR,
        payload: toasterErr,
      }
    
  }

  export const EventNameForImage = (eventName) =>{
    return{
      type: EVENTNAME,
      payload: eventName,
    }
  
}


export const SendUserIdPost = (userPostId) =>{
  return{
    type: USER_POST_ID,
    payload: userPostId,
  }

}


export const authHocTrueValue = (authHoc) =>{
  return{
    type: AUTH_HOC,
    payload: authHoc,
  }
  
}

export const loginModalTrue = (loginModal) =>{
  return{
    type: LOGIN_MODAL_TRUE,
    payload: loginModal,
  }
  
}


export const signUpModalTrue = (signUpModal) =>{
  return{
    type: SIGNUP_MODAL_TRUE,
    payload: signUpModal,
  }
  
}


export const userLoginTrue = (userLoginModal) =>{
  return{
    type: USER_LOGIN,
    payload: userLoginModal,
  }
  
}

export const scrollDispatch = (scroll) =>{
  return{
    type: PAGE_SCROLL,
    payload: scroll,
  }
  
}