import React from 'react'
import { ImageObj } from '../../../assets/images/images'



const Jobs = () => {
    return (
        <>
            <section className="home-sec">
                <div className="bg-page" style={{ backgroundImage: `url(${ImageObj.TeamImage})` }}>
                    <div className="container">
                        <div className="col-sm-12">
                            <div className="bg-content">
                                <div className="text-box new">
                                    <h3>Jobs</h3>
                                </div>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="portfolio  pb-0 sec padding dir">
                <div className="container">
                    <div className="col-sm-12">
                        <div className="row">
                            <div className="col-sm-4">
                                <div className="portfolio-left">
                                    <h4>Why Choose Us</h4>
                                    <h3>We’re not the fastest or cheapest, but we’ll treat you fairly, share some laughs and
                      work with you to achieve your goals.</h3>
                                </div>
                            </div>
                            <div className="col-sm-8">
                                <div className="counts mt-0">
                                    <div className="row">
                                        <div className="col-4">
                                            <div className="team-count">
                                                <h5>25+</h5>
                                                <p>Years of Experience</p>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="team-count">
                                                <h5>3562+</h5>
                                                <p>Prorerty Sale</p>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="team-count">
                                                <h5>100%</h5>
                                                <p>Clients Satisfied</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
          <section className="jobs">
              <div className="container">
                  <div className="jobs-table">
                      <div className="box">
                          <div className="jobs-head">
                              <h3>Restaurant Team Member - Crew  </h3>
                              
                          </div>
                          <div className="adress">
                          <address><i className="fa fa-map-marker" aria-hidden="true"></i> Alwar</address>
                          </div>
                          <div className="number">
                          <p><i className="fa fa-phone" aria-hidden="true"></i>987569855</p>

                              </div>
                          <div className="blue listing-type">
                              Full Time
                          </div>
                      </div>
                  </div>
              </div>
          </section>
        </>
    )
}


export default Jobs;