import React, { useEffect, useState, useCallback } from 'react'
import { ImageObj } from '../../../../assets/images/images'
import { Link, useNavigate } from 'react-router-dom'
import PORTNUMBER from '../../../../api/api'
import axios from "axios"
import { MdDelete } from 'react-icons/md';
import { FaRegEdit } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

import { GiLovers } from 'react-icons/gi';

import IMAGE_HOST from '../../../../api/api'
import { useForm } from 'react-hook-form';


// react bootstrap
import { Modal, Button } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import { loginModalTrue } from '../../../../redux/actions/action'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import PaginationCount from '../../../../common/pagination/pagination'

const MarriageList = () => {
    const { t } = useTranslation();
    const TOKEN = localStorage.getItem("token")
    const UserTokenId = localStorage.getItem("UserTokenId")
    const [Count, setCount] = useState(1);
    const navigate = useNavigate()
    const UserActiveList = useSelector((state) => state.userLoginReducer)
    const [itemPerPage, setItemPerPage] = useState();

    const HOST_PORT = IMAGE_HOST.IMAGE_HOST
    const dispatch = useDispatch()
    const UserActive = useSelector((state) => state.userLoginReducer)
    const [userId, setUserId] = useState();
    const [applicantId, setApplicantId] = useState();

    const [userIdList, setUserIdList] = useState();


    // const [indexPage, setIndexPage] = useState(1);
    const [activePage, setActivePage] = useState()
    const [loading, setLoading] = useState(false);
    const [filterData, setFilterData] = useState([]);
    const [filterVisibility, setFilterVisibility] = useState(true);

    const [applicantData, setApplicantData] = useState(["not"])
    const [applicantSelf, setApplicantSelf] = useState(["not"])

    const [statusId, setStatusId] = useState();
    const [activeShow, setActiveShow] = useState(false);
    const [marriageToggle, setMarriageToggle] = useState(true);

    const PORT = PORTNUMBER.PORTNUMBER

    const { register, handleSubmit } = useForm();


    const ScrollFromTop = () => {
        window.scrollTo(0, 600)
    }

    const LoadMarrigeSelf = useCallback(async () => {
        setMarriageToggle(false)
        setLoading(false)
        await axios.get(`${PORT}/user/marriage/list`, { headers: { authorization: `Bearer ${TOKEN}` } })
            .then((res) => {
                setLoading(true)

                setApplicantData(res.data.data.marriageList)

            })
    }, [PORT, TOKEN])


    const LoadMarrigeList = useCallback(async (pageNumber) => {
        setMarriageToggle(true)
        setLoading(false)
        setFilterVisibility(true)

        await axios.post(`${PORT}/user/marriage/all`, { page: pageNumber }, { headers: { authorization: `Bearer ${TOKEN}` } })
            .then((res) => {
                setApplicantData(res.data.data.marriageList)
                setCount(res.data.data.totalMarriage);
                setItemPerPage(res.data.data.limit)

                setLoading(true);

                // history.push("/team")
            })
    }, [PORT, TOKEN])



    const activeHandleClose = () => setActiveShow(false);
    const activeHandleShow = (id) => {
        setActiveShow(true);
        setStatusId(id)
    }

    const openSelfList = useCallback((user) => {
        setUserIdList(user)
        if (TOKEN || UserActiveList) {
            LoadMarrigeSelf()
        } else {
            dispatch(loginModalTrue(true))
        }

    }, [UserActiveList])





    const deleteApplication = async () => {
        await axios.post(`${PORT}/user/marriage/delete`, { marriageId: statusId }, { headers: { authorization: `Bearer ${TOKEN}` } }).then((res, err) => {
            LoadMarrigeList()
            toast.success("Marriage data deleted successfully.")
            activeHandleClose()
        })
    }


    const handlePageChange = (pageNumber) => {

        if (filterVisibility === true) {
            setActivePage(pageNumber);
            LoadMarrigeList(pageNumber)
            // setIndexPage(pageNumber)
            setLoading(false);

        } else {
            setActivePage(pageNumber);
            sendPage(pageNumber)
            // setIndexPage(pageNumber)
            setLoading(false);

        }

    }


    const sendPage = (pageNumber) => {
        axios.post(`${PORT}/user/marriage/filteredList`, { page: pageNumber }, { headers: { authorization: `Bearer ${TOKEN}` } })
    }

    const onSubmit = async (data) => {
        setMarriageToggle(true)

        setLoading(false);
        // sendPage()
        await axios.post(`${PORT}/user/marriage/filteredList`, data, { headers: { authorization: `Bearer ${TOKEN}` } })
            .then((res) => {

                setCount(res.data.data.totalMarriage)
                setFilterData(res.data.data.marriageList)
                setLoading(true);
                setFilterVisibility(false)
                ScrollFromTop()

            })

    }

    const openMarriageForm = useCallback((user) => {
        setUserId(user)
        if (TOKEN || UserActive) {
            navigate("marriageForm")
        } else {
            dispatch(loginModalTrue(true))
        }

    }, [UserActive])


    

    useEffect(() => {
        if (UserActive === true && userId) {
            navigate("marriageForm")
        }
    }, [UserActive])

    
    const viewApplicant = useCallback((user) => {
        setApplicantId(user)
        if (TOKEN || UserActive) {
            navigate(`/viewApplicant/${user}`)
        } else {
            dispatch(loginModalTrue(true))
        }

    }, [UserActive])


    

    useEffect(() => {
        if (UserActive === true && applicantId) {
            navigate(`/viewApplicant/${applicantId}`)
        }
    }, [UserActive])




    useEffect(() => {
        LoadMarrigeList()

    }, [LoadMarrigeList])


    return (
        <React.Fragment>
            <Helmet>
                <title>Koli Matrimonial</title>
                <meta name="description" content="Hi! The Koli Samaj Vikash sanstha, under the aegis of Koli Samaj, has created an online platform giving a new format to the Koli marriage relationship, where you can easily see a life partner for yourself and your family in the areas around you, sitting at home! Click on the link of Koli Samaj Vikash sanstha and register to see your partner!" />
                <meta name="keywords" content="Koli Matrimonial, koli samaj saadi, koli samaj vivah, koli samaj sammelan, koli samaj marrige, koli samaj riste, koli samaj saadi website, koli samaj ladke, koli samaj metrimonial" />
                <meta name="author" content="mahendra koli" />
                <link rel="canonical" href="https://www.kolisamajvikashsanstha.com/matrimonial" />
            </Helmet>
            <section className="bg-banner our-marrige" style={{ backgroundImage: `url(${ImageObj.Marriage})` }}>
                <div className="container">
                    <div className="couple-head">
                        <h2>{t('Matrimonial.Banner')}</h2>
                    </div>
                    <div className="marriage-filter shadow">
                        <div className="tab-head">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <div className="row">

                                    <div className="col-md-2 col-sm-6">
                                        <div className="form-group">
                                            <select name="gender" {...register('gender', { required: false })} className="form-control form-select" id="validationCustomThree11"  >
                                                <option value="" className="disabled-value d-none" >{t('Member.Gender')}</option>

                                                <option value="male">{t('Member.Male')}</option>
                                                <option value="female">{t('Member.Female')}</option>
                                            </select>
                                            <div className="invalid-feedback">
                                                Select Your Gender.
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-2  col-sm-6">
                                        <div className="form-group">
                                            <input type="text" {...register('age', { required: false })} className="form-control" placeholder="Age" />
                                        </div>
                                    </div>
                                    <div className="col-md-2 col-sm-6">
                                        <div className="form-group">
                                            <input type="text" {...register('city', { required: false })} className="form-control" placeholder="City" />
                                        </div>
                                    </div>
                                    <div className="col-md-2 col-sm-6">
                                        <div className="form-group">
                                            <input type="text" {...register('state', { required: false })} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="State" />
                                        </div>
                                    </div>



                                    <div className="col-md-3 col-sm-12">
                                        <div className="theme-btn btn-group-right text-right">
                                            <button type="submit" className="btn">Search</button>
                                            <button type="reset" onClick={() => LoadMarrigeList()} className="btn">Reset</button>
                                        </div>
                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>


                </div>
                <div className="dark">

                </div>
            </section>
            <section className="members pt-100 pb-100">
                <div className="container">
                    <div className=" brown-button menu-btn languages justify-content-start mb-sm-5 mb-2 ">
                        {
                            TOKEN || UserActive ?
                                <Link to="marriageForm" className="btn">Register</Link> :
                                <button onClick={() => openMarriageForm("id")} className="btn ">Login before register</button>
                        }
                        {

                            marriageToggle === true ?
                                <button onClick={() => openSelfList("id")} className="btn ">Your List</button> :
                                <button onClick={() => LoadMarrigeList()} className="btn ">All List</button>

                        }



                    </div>
                    <div className="col-12">
                        <div className="row">
                            {/* <div className="col-sm-6">
                                    <div className="couple-card-box">
                                        <div className="card-box ">
                                            <div className="box-img ">
                                                <img aria-hidden="true" src={ImageObj.Groom} alt="" ></img>
                                                <div className="social ">
                                                    <nav>
                                                        <a className="facebook" href="# "><img aria-hidden="true" src={ImageObj.Facebook}></img></a>
                                                        <a className="twitter" href="# "><img aria-hidden="true" src={ImageObj.Twitter}></img></a>
                                                    </nav>
                                                </div>
                                            </div>
                                            <div className="card-content ">
                                                <ul>
                                                    <li>Name:</li>
                                                    <li>Dinesh</li>
                                                    <li>Father:</li>
                                                    <li>Govind Raam</li>
                                                    <li>Mother:</li>
                                                    <li>Seema</li>
                                                    <li>Gotra:</li>
                                                    <li>Mahawar</li>
                                                    <li>Palace:</li>
                                                    <li>Alwar</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="card-box ">
                                            <div className="box-img ">
                                                <img aria-hidden="true" src={ImageObj.Bride} alt="" ></img>
                                                <div className="social ">
                                                    <nav>
                                                        <a className="facebook" href="# "><img aria-hidden="true" src={ImageObj.Facebook}></img></a>
                                                        <a className="twitter" href="# "><img aria-hidden="true" src={ImageObj.Twitter}></img></a>
                                                    </nav>
                                                </div>
                                            </div>
                                            <div className="card-content ">
                                                <ul>
                                                    <li>Name:</li>
                                                    <li>Priya</li>
                                                    <li>Father:</li>
                                                    <li>Govind Raam</li>
                                                    <li>Mother:</li>
                                                    <li>Seema</li>
                                                    <li>Gotra:</li>
                                                    <li>Mahawar</li>
                                                    <li>Palace:</li>
                                                    <li>Alwar</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="heart"></div>
                                    </div>
                                </div> */}
                            {/* {
                                applicantSelf !== "not" && applicantSelf && applicantSelf.length ? applicantSelf.map((applicant, i) => (

                                    <div className="col-sm-4 col-lg-3" key={i + 1} data-aos="fade-up" data-aos-duration="1000">
                                        <div className="couple-card-box">

                                            <div className="card-box ">
                                                <div className="box-img ">
                                                    <img aria-hidden="true" src={`${HOST_PORT}/${applicant.image}`} alt="" ></img>
                                                    {
                                                        (UserTokenId === String(applicant && applicant.user !== undefined ? applicant.user : "N/A")) ?
                                                            <div className="social ">
                                                                <nav>
                                                                    <span className="facebook anchore" onClick={() => activeHandleShow(applicant._id)}><MdDelete />हटाये</span>
                                                                    <Link to={`/MarriageDataEdit/${applicant._id}`} className="twitter anchore" ><FaRegEdit />बदले</Link>
                                                                </nav>
                                                            </div> : ""
                                                    }

                                                </div>
                                                <div className="card-content ">
                                                    <ul>
                                                        <li>{t('Marriage_Data.PersonalDetails.FullName')} :</li>
                                                        <li>{applicant.full_name}</li>
                                                        <li>{t('Marriage_Data.PersonalDetails.Marital_Status.head')} :</li>
                                                        <li>{applicant.marital_status}</li>
                                                        <li>{t('Marriage_Data.PersonalDetails.state')} :</li>
                                                        <li>{applicant.state}</li>
                                                        <li>{t('Marriage_Data.Education.Detail_edu')} :</li>
                                                        <li>{applicant.education_level}</li>
                                                        <li>{t('Marriage_Data.Education.Detail_edu3')} :</li>
                                                        <li>{applicant.occupation}</li>

                                                    </ul>

                                                </div>
                                                <div className="brown-button">
                                                    <Link className="btn w-100" to={`/viewApplicant/${applicant._id}`} >और देखे</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )):""} */}

                            {

                                filterVisibility ? applicantData !== "not" && applicantData && applicantData.length ? applicantData.map((applicant, i) => (
                                    <div className="col-sm-4 col-lg-3" key={i + 1} data-aos="fade-up" data-aos-duration="1000">
                                        <div className="couple-card-box">

                                            <div className="card-box ">
                                                <div className="box-img ">
                                                    <img aria-hidden="true" src={`${HOST_PORT}/${applicant.image}`} alt="" ></img>
                                                    {
                                                        (UserTokenId === String(applicant && applicant.user !== undefined ? applicant.user?._id : "N/A")) ?
                                                            <div className="social ">
                                                                <nav>
                                                                    <span className="facebook anchore" onClick={() => activeHandleShow(applicant?._id)}><MdDelete />हटाये</span>
                                                                    <Link to={`/MarriageDataEdit/${applicant?._id}`} className="twitter anchore" ><FaRegEdit />बदले</Link>
                                                                </nav>
                                                            </div> : ""
                                                    }

                                                </div>
                                                <div className="card-content ">
                                                    <ul>
                                                        <li>{t('Marriage_Data.PersonalDetails.FullName')} </li>
                                                        <li>{applicant.full_name}</li>
                                                        <li>{t('Marriage_Data.PersonalDetails.Marital_Status.head')} </li>
                                                        <li>{applicant.marital_status}</li>
                                                        <li>{t('Marriage_Data.PersonalDetails.state')} </li>
                                                        <li>{applicant.state}</li>
                                                        <li>{t('Marriage_Data.Education.Detail_edu')} </li>
                                                        <li>{applicant.education_level}</li>
                                                        <li>{t('Marriage_Data.Education.Detail_edu3')} </li>
                                                        <li>{applicant.occupation}</li>

                                                    </ul>

                                                </div>

                                                <div className="brown-button">
                                                    {
                                                        TOKEN || UserActive ?
                                                            <Link className="btn w-100" to={`/viewApplicant/${applicant?._id}`} >और देखे</Link> :
                                                            <button className="btn w-100" onClick={() => viewApplicant(applicant?._id)} >और देखे</button>
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )) : <span className="no-data">No Mrriage Applicant Found</span> :

                                    filterData !== "not" && filterData && filterData.length ? filterData.map((applicant, i) => (
                                        <div className="col-sm-4 col-lg-3" key={i + 1} data-aos="fade-up" data-aos-duration="1000">
                                            <div className="couple-card-box">

                                                <div className="card-box ">
                                                    <div className="box-img ">
                                                        <img aria-hidden="true" src={`${HOST_PORT}/${applicant.image}`} alt="" ></img>
                                                        {
                                                            (UserTokenId === String(applicant && applicant.user !== undefined && applicant?.user?._id !== null ? applicant?.user?._id : "N/A")) ?
                                                                <div className="social ">
                                                                    <nav>
                                                                        <span className="facebook anchore" onClick={() => activeHandleShow(applicant?._id)}><MdDelete />हटाये</span>
                                                                        <Link to={`/MarriageDataEdit/${applicant?._id}`} className="twitter anchore" ><FaRegEdit />बदले</Link>
                                                                    </nav>
                                                                </div> : ""
                                                        }

                                                    </div>
                                                    <div className="card-content ">
                                                        <ul>
                                                            <li>{t('Marriage_Data.PersonalDetails.FullName')}</li>
                                                            <li>{applicant.full_name}</li>
                                                            <li>{t('Marriage_Data.PersonalDetails.Marital_Status.head')}</li>
                                                            <li>{applicant.marital_status}</li>
                                                            <li>{t('Marriage_Data.PersonalDetails.state')}</li>
                                                            <li>{applicant.city}</li>
                                                            <li>{t('Marriage_Data.Education.Detail_edu')}</li>
                                                            <li>{applicant.education_level}</li>
                                                            <li>{t('Marriage_Data.Education.Detail_edu3')}</li>
                                                            <li>{applicant.occupation}</li>

                                                        </ul>

                                                    </div>
                                                    <div className="brown-button">
                                                        <Link className="btn w-100" to={`/viewApplicant/${applicant?._id}`} >और देखे</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )) : <span className="no-data">No Mrriage Applicant Found</span>

                            }



                        </div>
                        {
                            loading === false &&
                            <div className="loader-wrapper absolute-sec">
                                <div className="loader">

                                    <div className="loading-svg">
                                        <GiLovers />

                                    </div>

                                </div>

                            </div>
                        }

                    </div>

                    <PaginationCount count={Count} itemperpage={itemPerPage} activepage={activePage}
                        handlepagechange={handlePageChange} />
                </div>
                {/* delete application modal */}
                <Modal className="status-modal" animation={false} show={activeShow} onHide={activeHandleClose}>

                    <Modal.Body>Are you sure you want to delete this image ?</Modal.Body>
                    <Modal.Footer>
                        <div className="theme-btn btn-group-right text-right">
                            <Button variant="secondary"
                                onClick={deleteApplication}
                            >
                                Yes
                            </Button>
                            <Button variant="primary" onClick={activeHandleClose}>
                                No
                            </Button>
                        </div>

                    </Modal.Footer>
                </Modal>
                {/*  delete application modal  */}
            </section>
        </React.Fragment>
    )
}

export default MarriageList;