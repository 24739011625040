import React, { useEffect } from 'react'
import { ImageObj } from '../../../assets/images/images';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

const KanhojiAgre = () => {
    const { t } = useTranslation();
    useEffect(() => {
        document.body.classList.add("choclate-bg");
        return (() => {
            document.body.classList.remove("choclate-bg");

        })
    }, [])


    return (
        <React.Fragment>
        <Helmet>
            <title>Kanhoji Angre History</title>
            <meta name="description" content="Angre was born on the fort Suvarnadurg, near Ratnagari in the year 1667. His surname Angre is derived from Angarwadi; the family's original name was Sankpal, and the family members before Kanhoji were known as Sankpals." />
            <meta name="keywords" content="Kanhoji Angre, Suvarnadurg, Ratnagari, Angarwadi, Sankpal, Hindus in the island, Kshatriya Maratha, Reginald Edward Enthoven, Dr.Arunchandra Pathak, Vijayadurg, Sekhoji " />
            <meta name="author" content="mahendra koli" />
            <link rel="canonical" href="https://www.kolisamajvikashsanstha.com/kanhojiAgre" />
        </Helmet>
        <div className="container padding-mob-0">
            <div className="history-show-top bg" style={{ backgroundImage: `url(${ImageObj.ShowHisBg2})` }}>
                <div className="show-history-inner">
                    <div className="koli-history show-his">
                    <h2>{t('kanhojiAngre.titleOne')}</h2>
                    <p>{t('kanhojiAngre.summeryOne')}</p>
                    <p>{t('kanhojiAngre.summeryTwo')}</p>
                        <h2>{t('kanhojiAngre.titleTwo')}</h2>
                        <p>{t('kanhojiAngre.summeryThree')}</p>
                        <h2>{t('kanhojiAngre.titleThree')}</h2>
                        <p>{t('kanhojiAngre.summeryFour')}</p>
                        <p>{t('kanhojiAngre.summeryFive')}</p>
                        <p>{t('kanhojiAngre.summerySix')}</p>
                        <p>{t('kanhojiAngre.summerySeven')}</p>
                        <h2>{t('kanhojiAngre.titleFour')}</h2>
                        <div className="list">
                            <ul>
                                <li>{t('kanhojiAngre.summeryEight')}</li>
                                <li>{t('kanhojiAngre.summeryNine')}</li>
                                <li>{t('kanhojiAngre.summeryTen')}</li>
                                <li>{t('kanhojiAngre.summeryEleven')}</li>
                            </ul>
                        </div>
                        <h2>{t('kanhojiAngre.titleFive')}</h2>
                        <p>{t('kanhojiAngre.summeryTwelve')}</p>
                        <p>{t('kanhojiAngre.summeryThirteen')}</p>
                        <p>{t('kanhojiAngre.summeryFourteen')}</p>
                        <h2>{t('kanhojiAngre.titleSix')}</h2>
                        <div className="list">
                            <ul>
                                <li>{t('kanhojiAngre.summeryFifteen')}</li>
                                <li>{t('kanhojiAngre.summerySixteen')}</li>
                                <li>{t('kanhojiAngre.summerySeventeen')}</li>
                                <li>{t('kanhojiAngre.summeryEighteen')}</li>
                                <li>{t('kanhojiAngre.summeryNineteen')}</li>
                                <li>{t('kanhojiAngre.summeryTwenty')}</li>
                                <li>{t('kanhojiAngre.summeryTwentyOne')}</li>
                                <li>{t('kanhojiAngre.summeryTwentyTwo')}</li>
                                <li>{t('kanhojiAngre.summeryTwentyThree')}</li>
                                <li>{t('kanhojiAngre.summeryTwentyFour')}</li>
                                <li>{t('kanhojiAngre.summeryTwentyFive')}</li>
                                <li>{t('kanhojiAngre.summeryTwentySix')}</li>
                                <li>{t('kanhojiAngre.summeryTwentySeven')}</li>
                                <h2>{t('kanhojiAngre.titleSeven')}</h2>
                                <p>{t('kanhojiAngre.summeryTwentyEight')}</p>
                                <div className="floor-table table-css">
                                    <table>
                                        <tr>
                                            <th></th>
                                            <th>छत्रपति (शासनाध्यक्ष)</th>
                                            <th>अंकित सूचना	</th>
                                            <th>अर्थ</th>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="seal-img">
                                                    <img src={ImageObj.KanhojiSeal} alt="image-missing" aria-hidden="true" />
                                                </div>
                                                <p>छत्रपति राजाराम के राज्यकाल में प्रचलित कान्होजी की मुद्रा</p>
                                            </td>
                                            <td>छत्रपति राजाराम</td>
                                            <td>॥श्री॥
                                                राजाराम चरणी

                                                सादर तुकोजी सुत

                                                कान्होजी आंगरे

                                                निरंतर</td>
                                            <td>॥श्री॥
                                                तुकोजी पुत्र कान्होजी आंगरे राजाराम के चरणों में (सेवा में) निरन्तर</td>
                                        </tr>
                                        <tr>
                                            <td>

                                            </td>
                                            <td>छत्रपति साहू</td>
                                            <td>॥श्री॥
                                                राजा शाहू चरणी तत्पर

                                                तुकोजी सुत कान्होजी आंगरे

                                                सरखेल निरंतर</td>
                                            <td>॥श्री॥
                                                तुकोजी के पुत्र कान्होजी आंगरे सरखेल, राजा शाहू के चरणों में निरन्तर तत्पर</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div className="seal-img">
                                                    <img src={ImageObj.SarkhelSeal} alt="image-missing" aria-hidden="true" />
                                                </div>
                                                <p>सरखेल कान्होजी आंगरे की मुद्रा</p>
                                            </td>
                                            <td>छत्रपति शाहू</td>
                                            <td>॥श्री॥
                                                श्री शाहू नृपती प्रि

                                                त्या तुकोजी तनुजन्म

                                                ना कान्होजी सरखे

                                                लस्य मुद्रा जय

                                                ति सर्वदा</td>
                                                <td>॥श्री॥
राजा शाहू की प्रीति से तुकोजी के पुत्र सरखेल कान्होजी आंगरे की मुद्रा सदा विजयी है।</td>
                                        </tr>
                                    </table>
                                </div>
                                <h2>{t('kanhojiAngre.titleEight')}</h2>
                                <p>{t('kanhojiAngre.summeryTwentyNine')}</p>
                                <p>{t('kanhojiAngre.summeryThity')}</p>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </React.Fragment>
    )
}

export default KanhojiAgre;