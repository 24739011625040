import React, { useState,useEffect } from 'react'

import { useNavigate, useLocation, Link } from 'react-router-dom'
import ADMIN_LOGIN_API from "../../../../api/api";
import { authHocTrueValue, toasterValue } from '../../../../redux/actions/action'
import { useForm } from 'react-hook-form';
import axios from 'axios'
import { useDispatch } from 'react-redux';
import { ImageObj } from '../../../../assets/images/images';
import { FaArrowRight } from 'react-icons/fa';





const AdminLogin = (props) => {
    const dispatch = useDispatch()
    const [errPass, setErrPass] = useState();
    const [errLogin, setErrLogin] = useState();


    const navigate = useNavigate();
    const location = useLocation();
    const pathname = location.pathname;
    const { register, handleSubmit } = useForm();

  



    useEffect(() => {
        document.body.classList.add('remove-footer');
        return () => {
          document.body.classList.remove('remove-footer');
    
        }
      }, [])


    //    useEffect(() => {
    //     const Auth1 = localStorage.getItem("username");
    //     const Auth2 = localStorage.getItem("token");

    //     if (Auth1 !== null && Auth2 !== null) {
    //         return <Redirect to="/" />
    //     }else{
    //         return <Redirect to="/marriage-list" />
    //     }
    // })




    const onSubmit = async (data) => {
        await axios.post(ADMIN_LOGIN_API.ADMIN_LOGIN_API, data)
            .then((res) => {
                if (res.data.success !== true) {
                    switch (res.data.message) {
                        case "Username is not exist!":
                            setErrLogin(res.data.message)
                            setErrPass("")
                            break;
                        case "Please enter correct password!":
                            setErrPass(res.data.message)
                            setErrLogin("")
                            break;
                        default:
                            setErrLogin("Technical Err")
                    }

                } else {
                    setErrLogin("")
                    setErrPass("")
                    dispatch(authHocTrueValue(true))
                    localStorage.setItem("AdminToken", res.data.data.token);
                    dispatch(toasterValue("You have logged in successfully."))
                    navigate({ pathname: `${pathname}/userManagement`, customdata: { pathname } })
                }
            })
    }



    return (
        <div className="container">
            <div className="max-new-second max-margin login-mr">
                <div className="main-theme bg-theme border otp-pad new shadow">
                    <section className="select-state">
                        <div className="state-bor">
                            <div className="log-in otp-num">
                                <div className="select-steps-box">
                                    <div className="input-head">
                                        <h2>Welcome Back</h2>
                                    </div>
                                </div>
                                <div className="select-box-top pr-0">
                                    <div className="input-box">
                                        <form onSubmit={handleSubmit(onSubmit)} >
                                            <div className="col-12 p-0">
                                                <div className="row">
                                                    <div className="col-sm-12">

                                                        <div className="form-group">
                                                            <input type="text" className="form-control"  {...register('username', { required: true })} placeholder="Enter email" required />
                                                            <small id="emailHelp" className=" form-text text-muted">{errLogin}</small>
                                                        </div>
                                                    </div>
                                                    {/* <div className="col-sm-12">

                                                        <div className="form-group">
                                                            <input type="email" className="form-control"  {...register('email', { required: true })} placeholder="Enter email" required />
                                                            <small id="emailHelp" className=" form-text text-muted">{errLogin}</small>
                                                        </div>
                                                    </div> */}
                                                    <div className="col-sm-12">
                                                        <div className="form-group">
                                                            <input type="password" className="form-control"  {...register('password', { required: true })} placeholder="Enter password" required />
                                                            <small id="emailHelp" className="  form-text text-muted">{errPass}</small>
                                                            <p className="resend new mt-2"><Link to="resetmail">Forgot Password?</Link></p>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="theme-btn select-m">
                                                <button className="btn verfy-btn" type="submit">LOGIN <FaArrowRight/></button>
                                            </div>
                                        </form>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    )
}

export default AdminLogin;
