import axios from "axios"
import React, { useState, useEffect } from "react"
import PORTNUMBER from '../../../../api/api'
import { useNavigate } from "react-router-dom"
import { useForm, Controller } from "react-hook-form";
import { ImageObj } from "../../../../assets/images/images";
import { useTranslation } from 'react-i18next';
import FormValidation from '../../../../formValidation/formValidation'
import { FaEye, FaEyeSlash } from 'react-icons/fa';
// import ReCAPTCHA from "react-google-recaptcha"
import { toasterValue, toasterErrValue, loginModalTrue, signUpModalTrue } from '../../../../redux/actions/action'
import { useDispatch } from 'react-redux';
import { Helmet } from "react-helmet";

// Import Datepicker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Spinner } from "react-bootstrap";
import ButtonLoader from "../submitButton/submitButton";


const GovtEmployee = (props) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const PORT = PORTNUMBER.PORTNUMBER
    // const [captcha, setCptcha] = useState(true)
    const dispatch = useDispatch()
    const [spinLoadVisibility, setSpinLoadVisibility] = useState(false);

    const { control, register, handleSubmit } = useForm();
    // confirm password
    const [passwordErr, setPassErr] = useState()

    const [visibility, setVisibility] = useState(true)
    const [visibilityConfirm, setVisibilityConfirm] = useState(true)

    const [imageTypeErr, setImageTypeErr] = useState("")
    const [identityErr, setIdentityErr] = useState("")

    const [submitDisabled, setSubmitDisabled] = useState(null)



    const FormValidationNew = FormValidation

    const [formErrMsg, setFormErrMsg] = useState({
        Email: "",
        Mobile: "",
        default: "",
        Success: null
    })



    const [state, setState] = useState({
        onMind: "",
        image: "",
    })


    const [stateIdentity, setStateIdentity] = useState({
        onMind: "",
        identity: "",

    })

    const onImageChange = (event) => {
        var fileName = document.getElementById(props.profileimage ? props.profileimage : "profileImage").value;
        var dot = fileName.lastIndexOf(".") + 1;
        var extFile = fileName.substr(dot, fileName.length).toLowerCase();
      console.log("extFile",extFile);
        if (extFile == "jpg" || extFile == "jpeg" || extFile == "png") {
            if (event.target.files && event.target.files[0]) {
                let reader = new FileReader();
                reader.onload = (e) => {
                    setState({ image: e.target.result });
                    setImageTypeErr("")
                    setSubmitDisabled(false)
                };
                reader.readAsDataURL(event.target.files[0]);

            }
        } else {
            setSubmitDisabled(true)
            setImageTypeErr("Only jpg, png and jpeg image files allowed!")
        }

    }

    const onImageIdentity = (event) => {
        setIdentityErr("")
        setSubmitDisabled(false)

        var fileName = document.getElementById(props.employeeidentity ? props.employeeidentity : "employeeIdentity").value;
        var dot = fileName.lastIndexOf(".") + 1;
        var extFile = fileName.substr(dot, fileName.length).toLowerCase();
        if (extFile == "jpg" || extFile == "jpeg" || extFile == "png") {
            if (event.target.files && event.target.files[0]) {
                let reader = new FileReader();
                reader.onload = (e) => {
                    setStateIdentity({ identity: e.target.result });
                    setSubmitDisabled(false)
                    setIdentityErr("")

                };
                reader.readAsDataURL(event.target.files[0]);

            }
        } else {
            setSubmitDisabled(true)
            setIdentityErr("Only jpg, png and jpeg image files allowed!")

        }


    }


    // confirm password

    const pwVisibility = (value) => {
        setVisibility(value)
    }
    const pwVisibilityConfirm = (value) => {
        setVisibilityConfirm(value)
    }
    useEffect(() => {
        FormValidationNew()

    }, [FormValidationNew])

    // function onChangeCaptcha(value) {
    //     setCptcha(false)
    //     if (value === null) {
    //         setCptcha(true)
    //     }
    // }


    const onSubmit = async (data) => {
        setSpinLoadVisibility(true)
        setSubmitDisabled(true)
        const imageFiles = document.getElementById(props.profileimage ? props.profileimage : 'profileImage').files[0];
        const imageFilesSecond = document.getElementById(props.employeeidentity ? props.employeeidentity : 'employeeIdentity').files[0];


        const fs = new FormData();
        for (var key in data) {
            fs.append(key, data[key]);
        }
        fs.append("image", imageFiles);
        fs.append("identity", imageFilesSecond);
        fs.append("type", "govt");
        if (data.password !== data.confirm_password) {
            setSpinLoadVisibility(false)
            setSubmitDisabled(true)
            setPassErr("Password not mached")
        } else {
          
            const userCreate = await axios.post(`${PORT}/user/create`, fs);
            setSpinLoadVisibility(false)
            setSubmitDisabled(false)
            switch (userCreate.data.message) {
                case "Email is already exist!":
                    setFormErrMsg({
                        Email: userCreate.data.message,
                    });
                    break;

                case "Mobile number is already exist!":
                    setFormErrMsg({
                        Mobile: userCreate.data.message,
                    });
                    break;

                case "User is created successfully!":
                    // await axios.post(`${PORT}/govtServant/create`, fs)
                    //     .then((res) => {
                    //         if (res.data.success === true) {
                    //             setSpinLoadVisibility(false)

                    //             dispatch(loginModalTrue(true))
                    //             dispatch(signUpModalTrue(false))
                    //             dispatch(toasterValue("You have Registerd  successfully."))
                    //         } else {
                    //             setSpinLoadVisibility(true)

                    //             dispatch(toasterErrValue("You have Not Registerd  successfully."))
                    //         }
                    //     })
                    setFormErrMsg({
                        Success: true
                    });
                    break;

                default:
                    setFormErrMsg({
                        default: userCreate.data.message,
                    });
            }
        }

    }



    return (
        <React.Fragment>
            {/* <Helmet>
                <title>Koli Samaj Government Employees Form</title>
                <meta name="description" content="
If you are a government employee To become a member of Koli Samaj Sanstha, fill in the details form and login" />
                <meta name="keywords" content="Application Form koli Government Employees" />
                <meta name="author" content="mahendra koli" />
                <link rel="canonical" href="https://www.kolisamajvikashsanstha.com/GovtEmployee" />
            </Helmet> */}

            <section className="app-form sec padding">
                <div className="container ">
                    <div className="touch-head app">
                        <h4>Application Form Govt Employee</h4>
                        {/* <p>{t('dataMsg.msg')}</p> */}
                    </div>
                    <div className="application main-theme shadow">

                        <div className="touch-form app">
                            <form onSubmit={handleSubmit(onSubmit)} className="needs-validation" noValidate>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="information-user d-flex">
                                            <div className="info-image">
                                                {state.image ?
                                                    <img src={state.image} alt="" />
                                                    : <img aria-hidden="true" src={ImageObj.UserBlank} alt="koli members" />
                                                }
                                            </div>
                                            <h3>{!state.image ? "Upload Image" : "Change Image"}  <input id={props.profileimage ? props.profileimage : "profileImage"} name="image" onChange={onImageChange} type="file" className="form-control" />
                                                <div className="form-text text-muted">
                                                    {imageTypeErr}
                                                </div>
                                            </h3>
                                            {/* <h4>Remove</h4> */}
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="information-user d-flex">
                                            <div className="info-image rounded">
                                                {stateIdentity.identity ?
                                                    <img src={stateIdentity.identity} alt="" />
                                                    : <img aria-hidden="true" src={ImageObj.Identity} alt="koli identity" />
                                                }
                                            </div>
                                            <h3>{!stateIdentity.identity ? "Upload Employee Id/Proof" : "Change id"} <input id={props.employeeidentity ? props.employeeidentity : "employeeIdentity"} name="identity" accept="image/*" onChange={onImageIdentity} type="file" className="form-control" required={stateIdentity.identity ? false : true} />
                                                <div className="invalid-feedback">
                                                    Please upload Your Employee Identity/Proof.
                                                </div>
                                                <div className="form-text text-muted">
                                                    {identityErr}
                                                </div>
                                            </h3>

                                        </div>
                                    </div>

                                </div>

                                <div className="col-sm-12 ">
                                    <div className="row ">
                                        <div className="col-sm-6 ">
                                            <div className="form-group ">
                                                <input type="text " name="name" {...register('name', { required: true })} id="validationCustomTwo5" className="form-control " placeholder={t('Member.Name')} required />
                                                <div className="invalid-feedback">
                                                    Please Fill Your Name.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 ">
                                            <div className="form-group">
                                                <select name="gender" {...register('gender', { required: true })} className="form-control form-select" id="validationCustomThree6" required >
                                                    <option value="" className="disabled-value d-none" >{t('Member.Gender')}</option>
                                                    <option value="male">{t('Member.Male')}</option>
                                                    <option value="female">{t('Member.Female')}</option>
                                                </select>
                                                <div className="invalid-feedback">
                                                    Select Your Gender.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 ">
                                            <div className="form-group ">
                                                <input type="text " name="state" {...register('state', { required: true })} className="form-control " placeholder={t('Member.State')} id="validationCustomFour6" required />
                                                <div className="invalid-feedback">
                                                    please Add Your  State.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 ">
                                            <div className="form-group ">
                                                <input type="text " name="city" {...register('city', { required: true })} className="form-control " placeholder={t('Member.City')} id="validationCustomFive6" required />
                                                <div className="invalid-feedback">
                                                    Please Add Your  City.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 ">
                                            <div className="form-group ">
                                                <input type="text " name="full_address" {...register('full_address', { required: true })} className="form-control " placeholder={t('Member.FullAddress')} id="validationCustomSix6" required />
                                                <div className="invalid-feedback">
                                                    please Enter Your  Address.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 ">
                                            <div className="form-group">
                                                <Controller id="validationCustomEleven"
                                                    control={control}
                                                    name='dob'
                                                    render={({ field }) => (
                                                        <DatePicker
                                                            placeholderText={t('Member.Age')}
                                                            onChange={(date) => field.onChange(date)}
                                                            maxDate={new Date()}
                                                            selected={field.value}
                                                        />
                                                    )}
                                                />
                                                <div className="invalid-feedback">
                                                    Please select date.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 ">
                                            <div className="form-group ">
                                                <input type="number" name="mobile" {...register('mobile', { required: true })} className="form-control " placeholder={t('Member.Mobile')} id="validationCustomSeven66" required />
                                                <div className="invalid-feedback">
                                                    please Enter Your Mobile  Number.
                                                </div>
                                                <small className=" form-text text-muted">{formErrMsg.Mobile}</small>
                                            </div>
                                        </div>
                                        <div className="col-md-6 ">
                                            <div className="form-group ">
                                                <input type="email " name="email"  {...register('email', { required: true })} className="form-control " placeholder={t('Member.Gmail')} id="validationCustomEight8" required />
                                                <div className="invalid-feedback">
                                                    please Add Your Email.
                                                </div>
                                                <small className=" form-text text-muted">{formErrMsg.Email}</small>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group icon-position">
                                                <input type={visibility ? "password" : "text"} className="form-control" id="validationCustomNine2" {...register('password', { required: true })} placeholder="password" required />
                                                {
                                                    visibility ?
                                                        <span className="icon" onClick={() => pwVisibility(false)}>
                                                            <FaEye />
                                                        </span> :
                                                        <span className="icon" onClick={() => pwVisibility(true)}>
                                                            <FaEyeSlash />
                                                        </span>
                                                }


                                                <div className="invalid-feedback">
                                                    Please enter password.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group icon-position password-field">
                                                <input type={visibilityConfirm ? "password" : "text"} className="form-control" id="val_ten2" {...register('confirm_password', { required: true })} placeholder="Confirm password" required />
                                                {
                                                    visibilityConfirm ?
                                                        <span className="icon" onClick={() => pwVisibilityConfirm(false)}>
                                                            <FaEye />
                                                        </span> :
                                                        <span className="icon" onClick={() => pwVisibilityConfirm(true)}>
                                                            <FaEyeSlash />
                                                        </span>
                                                }


                                                <div className="invalid-feedback">
                                                    Please enter confirm password.
                                                </div>
                                                <small className=" form-text text-muted">{passwordErr}</small>
                                            </div>



                                        </div>


                                        <div className="col-md-6 ">
                                            <div className="form-group ">
                                                <input type="text " name="designation"  {...register('designation', { required: true })} className="form-control " placeholder={t('Servant.Designation')} id="validationCustomTwelve3" required />
                                                <div className="invalid-feedback">
                                                    please Enter Your Designation.
                                                </div>
                                            </div>

                                        </div>
                                        <div className="col-md-6 ">
                                            <div className="form-group ">
                                                <input type="text " name="office_city"  {...register('office_city', { required: true })} className="form-control " placeholder={t('Servant.OfficeCity')} id="validationCustomThirteen2" required />
                                                <div className="invalid-feedback">
                                                    please Enter Your Office City Name.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 ">
                                            <div className="form-group ">
                                                <input type="text " name="office_address"  {...register('office_address', { required: true })} className="form-control " placeholder={t('Servant.OfficeAddress')} id="validationCustomFourteen14" required />
                                                <div className="invalid-feedback">
                                                    please Enter Your Office Address.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 ">
                                            <div className="form-group ">
                                                <input type="text " name="office_state"  {...register('office_state', { required: true })} className="form-control " placeholder={t('Servant.OfficeState')} id="validationCustomFifteen2" required />
                                                <div className="invalid-feedback">
                                                    please Enter Your Office State.
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="col-md-12 ">
                                            <div className="form-group ">
                                                <textarea name="reason_of_join" className="form-control"  {...register('reason_of_join', { required: false })} placeholder={t('Member.Reason')} id="validationCustomSixteen26" />
                                                <div className="invalid-feedback">
                                                    please Enter Your To Join Us.
                                                </div>
                                            </div>
                                        </div> */}
                                        {/* <ReCAPTCHA sitekey="6Lcjpz4cAAAAAAa-wXnIOrWRYxX1krMFTIBwVBXZ" onChange={onChangeCaptcha} /> */}
                                        <div className="col-md-12 ">
                                            <div className="brown-button submit ">
                                                <ButtonLoader btndisabled={submitDisabled} loader={spinLoadVisibility} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}

export default GovtEmployee;