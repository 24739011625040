import axios from "axios"
import React, { useState, useEffect, useCallback } from "react"
import PORTNUMBER from '../../../../api/api'
import { useNavigate, useParams } from "react-router-dom"
import { useForm, Controller } from "react-hook-form";
import { ImageObj } from "../../../../assets/images/images";
import { useTranslation } from 'react-i18next';
import FormValidation from '../../../../formValidation/formValidation'
import { toasterValue, toasterErrValue } from '../../../../redux/actions/action'
import { useDispatch } from 'react-redux';

import IMAGE_HOST from '../../../../api/api'


// Import Datepicker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ButtonLoader from "../submitButton/submitButton";

const PoliticianFormEdit = () => {
    const navigate = useNavigate();
    const { id } = useParams()
    const TOKEN = localStorage.getItem("token")
    const UserTokenId = localStorage.getItem("UserTokenId")
    const TOKEN_REF_ID = localStorage.getItem("RefId")
    const dispatch = useDispatch()
    const HOST_PORT = IMAGE_HOST.IMAGE_HOST
    const [spinLoadVisibility, setSpinLoadVisibility] = useState(false);

    const { t } = useTranslation();
    const PORT = PORTNUMBER.PORTNUMBER

    const [userIdData, setUserIdData] = useState(["not"])

    const { control, register, handleSubmit } = useForm();
    const [imageTypeErr, setImageTypeErr] = useState("")
    const [identityErr, setIdentityErr] = useState("")

    const [submitDisabled, setSubmitDisabled] = useState(null)


    const FormValidationNew = FormValidation

    const [formErrMsg, setFormErrMsg] = useState({
        Email: "",
        Mobile: "",
        default: "",
        Success: null
    })
    const [stateIdentity, setStateIdentity] = useState({
        onMind: "",
        identity: ""
    })

    const [state, setState] = useState({
        onMind: "",
        image: "",
    })

    const onImageChange = (event) => {
        var fileName = event.target.value;
        var dot = fileName.lastIndexOf(".") + 1;
        var extFile = fileName.substr(dot, fileName.length).toLowerCase();
        if (extFile == "jpg" || extFile == "jpeg" || extFile == "png") {
            if (event.target.files && event.target.files[0]) {
                let reader = new FileReader();
                reader.onload = (e) => {
                    setState({ image: e.target.result });
                    setImageTypeErr("")
                    setSubmitDisabled(false)
                };
                reader.readAsDataURL(event.target.files[0]);

            }
        } else {
            setSubmitDisabled(true)
            setImageTypeErr("Only jpg, png and jpeg image files allowed!")
        }
    }

    const onImageIdentity = (event) => {

        var fileName = event.target.value;
        var dot = fileName.lastIndexOf(".") + 1;
        var extFile = fileName.substr(dot, fileName.length).toLowerCase();
        if (extFile == "jpg" || extFile == "jpeg" || extFile == "png") {
            if (event.target.files && event.target.files[0]) {
                let reader = new FileReader();
                reader.onload = (e) => {
                    setStateIdentity({ identity: e.target.result });
                    setSubmitDisabled(false)
                    setIdentityErr("")
                };
                reader.readAsDataURL(event.target.files[0]);

            }
        } else {
            setSubmitDisabled(true)
            setIdentityErr("Only jpg, png and jpeg image files allowed!")
        }


    }



    // dote of birth 
    let DobLoad = new Date(userIdData.dob);
    DobLoad.toDateString()
    const DateLoad = userIdData.dob === undefined ? new Date() : DobLoad



    const LoadProfile = useCallback(async () => {
        await axios.post(`${PORT}/user/profile`, { userId: id }, { headers: { authorization: `Bearer ${TOKEN}` } })
            .then((res) => {
                setUserIdData(res.data.data)
            })
    }, [id, TOKEN, PORT])

    useEffect(() => {
        FormValidationNew()
        LoadProfile()
    }, [FormValidationNew, LoadProfile])

    const onSubmit = async (data) => {
        setSpinLoadVisibility(true)

        const imageFiles = document.getElementById('profileImage').files.length;
        const imageFilesIdentity = document.getElementById('politicianIdentity').files.length;

        const fs = new FormData();
        fs.append("politicianId", TOKEN_REF_ID);
        for (const [key] of Object.entries(data)) {
            data[key] = (data[key] === undefined || data[key] === null || data[key] === "") ? userIdData[key] : data[key];
            fs.append(key, data[key]);
        }
        if (imageFiles > 0) {
            const File = document.getElementById('profileImage').files[0];
            fs.append("image", File);
        }

        if (imageFilesIdentity > 0) {
            const FileIdentity = document.getElementById('politicianIdentity').files[0];
            fs.append("politician_identity", FileIdentity);
        }




        const userCreate = await axios.post(`${PORT}/politician/update`, fs, { headers: { authorization: `Bearer ${TOKEN}` } });
        setSpinLoadVisibility(false)
        setSubmitDisabled(false)
        if (userCreate.data.success === true) {
            dispatch(toasterValue("Updated Profile successfully."))
            navigate('/')
            navigate(`/profile/${UserTokenId}`)
            setSpinLoadVisibility(false)

        } else {
            setSpinLoadVisibility(false)

            dispatch(toasterErrValue(" Not Updated Profile successfully."))
        }
        switch (userCreate.data.message) {
            case "Email is already exist!":
                setFormErrMsg({
                    Email: userCreate.data.message,
                });
                break;

            case "Mobile number already exist!":
                setFormErrMsg({
                    Mobile: userCreate.data.message,
                });
                break;


            default:
                setFormErrMsg({
                    default: userCreate.data.message,
                });

        }


    }
    return (
        <section className="app-form sec padding">
            <div className="container ">
                <div className="touch-head app">
                    <h4>Application Form Politician</h4>
                    {/* <p>{t('dataMsg.msg')}</p> */}
                </div>
                <div className="application main-theme shadow">
                    <div className="touch-form app">
                        <form onSubmit={handleSubmit(onSubmit)} className="needs-validation" noValidate>
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="information-user d-flex">
                                        <div className="info-image">
                                            {!state.image ? userIdData.image ?
                                                <img src={`${HOST_PORT}/${userIdData.image}`} alt="" />
                                                : <img aria-hidden="true" src={ImageObj.UserBlank} alt="Committee" />
                                                : <img src={state.image} alt="" />
                                            }
                                        </div>
                                        <h3>{state.image || userIdData.image ? "Change Image" : "Upload Image"} <input id="profileImage" name="image" onChange={onImageChange} type="file" className="form-control" />
                                            <div className="form-text text-muted">
                                                {imageTypeErr}
                                            </div>
                                        </h3>
                                        {/* <h4>Remove</h4> */}
                                    </div>
                                </div>
                                <div className="col-sm-6 d-none">
                                    <div className="information-user d-flex">
                                        <div className="info-image rounded">


                                            {!stateIdentity.identity ? userIdData.politician_identity ?
                                                <img src={`${HOST_PORT}/${userIdData.politician_identity}`} alt="" />
                                                : <img aria-hidden="true" src={ImageObj.UserBlank} alt="Committee" />
                                                : <img src={stateIdentity.identity} alt="" />
                                            }
                                        </div>
                                        <h3>{stateIdentity.identity || userIdData.politician_identity ? "Change Image" : "Upload Image"} <input id="politicianIdentity" name="identity" accept="image/*" onChange={onImageIdentity} type="file" className="form-control" />
                                            <div className="form-text text-muted">
                                                {identityErr}
                                            </div>
                                        </h3>

                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-12 ">
                                <div className="row ">
                                    <div className="col-sm-6 ">
                                        <div className="form-group ">
                                            <label>{t('Member.Name')}</label>
                                            <input type="text " name="name" defaultValue={userIdData.name} {...register('name', { required: false })} id="validationCustomTwo7" className="form-control " placeholder={t('Member.Name')} />
                                            <div className="invalid-feedback">
                                                Please Fill Your Name.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 ">
                                        <div className="form-group">
                                            <label>{t('Member.Gender')}</label>
                                            <select name="gender" {...register('gender', { required: false })} className="form-control form-select" id="validationCustomThree8" required >
                                                <option className="disabled-value d-none" >{userIdData.gender}</option>
                                                <option value="male">{t('Member.Male')}</option>
                                                <option value="female">{t('Member.Female')}</option>
                                            </select>
                                            <div className="invalid-feedback">
                                                Select Your Gender.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 ">
                                        <div className="form-group ">
                                            <label>{t('Member.State')}</label>
                                            <input type="text " name="state" defaultValue={userIdData.state} {...register('state', { required: false })} className="form-control " placeholder={t('Member.State')} id="validationCustomFour9" />
                                            <div className="invalid-feedback">
                                                please Add Your  State.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 ">
                                        <div className="form-group ">
                                            <label>{t('Member.City')}</label>
                                            <input type="text " name="city" defaultValue={userIdData.city} {...register('city', { required: false })} className="form-control " placeholder={t('Member.City')} id="validationCustomFive8" />
                                            <div className="invalid-feedback">
                                                Please Add Your  City.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 ">
                                        <div className="form-group ">
                                            <label>{t('Member.FullAddress')}</label>
                                            <input type="text " name="full_address" defaultValue={userIdData.full_address} {...register('full_address', { required: false })} className="form-control " placeholder={t('Member.FullAddress')} id="validationCustomSix10" />
                                            <div className="invalid-feedback">
                                                please Enter Your  Address.
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-6 ">
                                        <div className="form-group">
                                            <label>{t('Member.Age')}</label>
                                            <Controller id="validationCustomEleven"
                                                control={control}
                                                name='dob'
                                                render={({ field }) => (
                                                    <DatePicker
                                                        placeholderText={t('Member.Age')}
                                                        onChange={(date) => field.onChange(date)}
                                                        maxDate={new Date()}
                                                        selected={field.value ? field.value : DateLoad}
                                                    />
                                                )}
                                            />
                                            <div className="invalid-feedback">
                                                Please select date.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 ">
                                        <div className="form-group ">
                                            <label>{t('Member.Mobile')}</label>
                                            <input type="number" name="mobile" defaultValue={userIdData.mobile} {...register('mobile', { required: false })} className="form-control " placeholder={t('Member.Mobile')} id="validationCustomSeven9" />
                                            <div className="invalid-feedback">
                                                please Enter Your Mobile  Number.
                                            </div>
                                            <small className=" form-text text-muted">{formErrMsg.Mobile}</small>
                                        </div>
                                    </div>
                                    <div className="col-md-6 ">
                                        <div className="form-group ">
                                            <label>{t('Member.Gmail')}</label>
                                            <input type="email " name="email" defaultValue={userIdData.email}  {...register('email', { required: false })} className="form-control " placeholder={t('Member.Gmail')} id="validationCustomEight10" />
                                            <div className="invalid-feedback">
                                                please Add Your Email.
                                            </div>
                                            <small className=" form-text text-muted">{formErrMsg.Email}</small>
                                        </div>
                                    </div>


                                    <div className="col-md-6 ">
                                        <div className="form-group ">
                                            <label>{t('Politician.Designation')}</label>
                                            <input type="text " name="designation" defaultValue={userIdData.designation}  {...register('designation', { required: false })} className="form-control " placeholder={t('Politician.Designation')} id="validationCustomTwelve5" />
                                            <div className="invalid-feedback">
                                                please Enter Your Designation.
                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-md-6 ">
                                        <div className="form-group ">
                                            <label>{t('Politician.OfficeCity')}</label>
                                            <input type="text " name="office_city" defaultValue={userIdData.office_city}  {...register('office_city', { required: false })} className="form-control " placeholder={t('Politician.OfficeCity')} id="validationCustomThirteen4" />
                                            <div className="invalid-feedback">
                                                please Enter Your Office City Name.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 ">
                                        <div className="form-group ">
                                            <label>{t('Politician.OfficeAddress')}</label>
                                            <input type="text " name="office_address" defaultValue={userIdData.office_address}  {...register('office_address', { required: false })} className="form-control " placeholder={t('Politician.OfficeAddress')} id="validationCustomFour10teen" />
                                            <div className="invalid-feedback">
                                                please Enter Your Office Address.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 ">
                                        <div className="form-group ">
                                            <label>{t('Politician.OfficeState')}</label>
                                            <input type="text " name="office_state" defaultValue={userIdData.office_state} {...register('office_state', { required: false })} className="form-control " placeholder={t('Politician.OfficeState')} id="validationCustomFifteen4" />
                                            <div className="invalid-feedback">
                                                please Enter Your Office State.
                                            </div>
                                        </div>
                                    </div>


                                    <div className="col-md-6 ">
                                        <div className="form-group ">
                                            <label>{t('Politician.PoliticianArea')}</label>
                                            <input type="text " name="politician_area" defaultValue={userIdData.politician_area}  {...register('politician_area', { required: false })} className="form-control " placeholder="politician_area" id="validationCustomSixteen23" required />
                                            <div className="invalid-feedback">
                                                please Enter Your Politician Area.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 ">
                                        <div className="form-group ">
                                            <label>{t('Politician.PoliticianParty')}</label>
                                            <input type="text " name="politician_party" defaultValue={userIdData.politician_party}  {...register('politician_party', { required: false })} className="form-control " placeholder="politician_party" id="validationCustomSeventy17" />
                                            <div className="invalid-feedback">
                                                please Enter Your Politician Party.
                                            </div>
                                        </div>
                                    </div>


                                    <div className="col-sm-6 ">
                                        <div className="form-group ">
                                            <label>{t('Politician.PoliticianType')}</label>
                                            <input type="text " name="politician_type" defaultValue={userIdData.politician_type} {...register('politician_type', { required: false })} className="form-control " placeholder="Politician" id="validationCustomNinteen" />
                                            <div className="invalid-feedback">
                                                please Enter Your Politician Type.
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="col-md-12 ">
                                        <div className="form-group ">
                                            <textarea name="reason_of_join" defaultValue={userIdData.reason_of_join} className="form-control"  {...register('reason_of_join', { required: false })} placeholder={t('Member.Reason')} id="validationCustomTwenty" />
                                            <div className="invalid-feedback">
                                                please Enter Your To Join Us.
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="col-md-12 ">
                                        <div className="brown-button submit ">
                                            <ButtonLoader btndisabled={submitDisabled} loader={spinLoadVisibility} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default PoliticianFormEdit;