import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams, Link } from 'react-router-dom'
import { useForm } from 'react-hook-form';
import axios from 'axios'
import { FaEye, FaEyeSlash } from 'react-icons/fa';

import { HiOutlineArrowRight } from 'react-icons/hi'
import { Spinner } from 'react-bootstrap';
import RESETPASSWORD from '../../../../api/api'


const ResetPassword = () => {
    const navigate = useNavigate()
    const [ConfirmErr, setConfErr] = useState()
    const { id } = useParams()
    const [visibility, setVisibility] = useState(true)
    const [visibilityConfirm, setVisibilityConfirm] = useState(true)
    const [decryptedMail, setDecryptedMail] = useState()
    const [spinLoadVisibility, setSpinLoadVisibility] = useState(false);


    const Token = localStorage.getItem("authtoken")

    const { register, handleSubmit } = useForm();
    const DeCryptLoad = useCallback(() => {
        setDecryptedMail(decodeURI(id))
    }, [id])

    // confirm password

    const pwVisibility = (value) => {
        setVisibility(value)
    }
    const pwVisibilityConfirm = (value) => {
        setVisibilityConfirm(value)
    }

    useEffect(() => {
        DeCryptLoad()
        document.body.classList.add("remove-home-header");
        return () => {
            document.body.classList.remove("remove-home-header");
        }
    }, [DeCryptLoad])
    const resetLink = (data) => {
        setSpinLoadVisibility(true)
        if (data.password === data.confirm_password) {
            setSpinLoadVisibility(false)
            axios.post(RESETPASSWORD.RESETPASSWORD, { password: data.password, email: decryptedMail }, { headers: { authtoken: Token } })
                .then((res) => {
                    navigate("/login")
                })
        } else {
            setConfErr("Password not matched")
            setSpinLoadVisibility(false)

        }



    }

    useEffect(() => {
        document.body.classList.add("remove-home-header");
    }, [])

    return (
        <React.Fragment>
           
        <div className="container">
            <div className="max-new-second pd-t-b-100">
                <div className="main-theme bg-theme border otp-pad new shadow">
                    <section className="select-state">
                        <div className="state-bor">
                            <div className="log-in otp-num">
                                <div className="select-steps-box">
                                    <div className="input-head">
                                        <h2>Reset Password</h2>
                                    </div>
                                </div>
                                <div className="select-box-top pr-0">
                                    <div className="input-box">
                                        <form onSubmit={handleSubmit(resetLink)}>
                                            <div className="col-12 p-0">
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <div className="form-group icon-position">
                                                            <input type={visibility ? "password" : "text"} className="form-control" {...register('password', { required: true })} placeholder="Enter password" />
                                                            {
                                                                visibility ?
                                                                    <span className="icon" onClick={() => pwVisibility(false)}>
                                                                        <FaEye />
                                                                    </span> :
                                                                    <span className="icon" onClick={() => pwVisibility(true)}>
                                                                        <FaEyeSlash />
                                                                    </span>
                                                            }
                                                            <small id="emailHelp" className=" d-none form-text text-muted">We'll never share your email with anyone else.</small>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12">
                                                        <div className="form-group icon-position">
                                                            <input type={visibilityConfirm ? "password" : "text"} className="form-control" {...register('confirm_password', { required: true })} placeholder="Confirm password" />
                                                            {
                                                                visibilityConfirm ?
                                                                    <span className="icon" onClick={() => pwVisibilityConfirm(false)}>
                                                                        <FaEye />
                                                                    </span> :
                                                                    <span className="icon" onClick={() => pwVisibilityConfirm(true)}>
                                                                        <FaEyeSlash />
                                                                    </span>
                                                            }
                                                            <small className=" form-text text-muted">{ConfirmErr}</small>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="theme-btn select-m">
                                                <button disabled={spinLoadVisibility} type="submit" className="submit btn verfy-btn" >
                                                    {
                                                        spinLoadVisibility === false ?
                                                            <>
                                                                Reset Password <HiOutlineArrowRight />
                                                            </> :
                                                            <div className="spinner-visibility">
                                                                <Spinner
                                                                    as="span"
                                                                    animation="grow"
                                                                    size="sm"
                                                                    role="status"
                                                                    aria-hidden="true"
                                                                />
                                                                Please Wait...
                                                            </div>
                                                    }

                                                </button>
                                                <p className="resend new">Have an account? <span><Link to="/login">Login </Link></span></p>
                                            </div>
                                        </form>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
        </React.Fragment>

    )
}

export default ResetPassword;