import Tanhaji from './tanhaji.jpg'
import Laxmi from './Laxmi.jpg'
import Yashwant from './yashwant.jpg'
import Teacher1 from './dir-one-long.png'
import Facebook from './facebook-cir.svg'
import Twitter from './twitter-cir.svg'
import RaamNath from './ram2.jpg'
import Marriage from './marriage.jpg'
import SideOne from './side-one.png'
import SideTwo from './side-two.png'
import Heart from './heart.svg'
import Bride from './bride.jpg'
import Groom from './groom.jpg'
import His1 from './venu-2.jpg'
import HistoryBg from './background-wood.jpg'
import BgWood from './bgwood.jpg'
import King from './king.jpg'
import OldBg from './old-bg.jpg'
import Hands from './hands.jpg'
import Poor from './poor.jpg'
import Study from './study.jpg'
import HistoryIcon from './history-icon.svg'
import ShipIcon from './ship-icon.svg'
import SwordIcon from './swords-icon.svg'
import KingIcon from './king.svg'
import GodIcon from './god-icon.svg'
import GotraIcon from './gotra-icon.svg'
import FamouseIcon from './famous-icon.svg'
import ForceIcon from './force-icon.svg'
import CastIcon from './cast-icon.svg'
import GetImg from './get.png'
import RaamKovi from './raam-kovi.jpg'
import RaamKoviTwo from './raam-kovi2.jpg'
import RaamKoviThree from './raam-kovi3.jpg'
import RaamFour from './raam4.jpg'
import RaamFive from './raam5.jpg'
import RaamSix from './raam6.jpg'
import Rajesh from './Rajesh-Chudasama.jpg'
import Ranjeeta from './Ranjeeta-koli.jpg'
import Committee from './committee.png'
import WebBlue from './web-blue.svg'
import Jesse from './jesse.png'
import TeamImage from './team2.jpg'
import DirectorOne from './dir-one-long.png'
import DirectorLongOne from './dir-one-long.png'
import DirectorTwo from './dir-one-long.png'
import DirectorLongTwo from './dir-one-long.png'
import Lettire from './lettire.png'
import Urban from './urban.png'
import JK from './jk.png'
import AB from './ab.png'
import WorkIcon from './suitcase.svg'
import StudiesIcon from './graduation-hat.svg'
import LivesInIcon from './home.svg'
import LocationIcon from './home.svg'
import Mandhata from './mandhata.jpg'
import GoutamBuddha from './gautam-buddha.png'
import Mahamaya from './mahamaya.jpg'
import Communitytree from './community-tree.jpg'
import KuwarJiBavaliya from './kuwarjibhai.jpeg'
import News2 from './news_2.jpg'
import News3 from './news_3.jpg'
import News4 from './news_4.jpg'
import News5 from './news_5.jpg'
import News6 from './news_6.jpg'
import Qoute_1 from './quote-blank-1.svg'
import Qoute_2 from './quote-blank-2.svg'
import MaleDoc from './doctor-active.svg'
import FacebookNew from './facebook.svg'
import Insta from './insta.svg'
import TwitterNew from './twitter.svg'
import WhiteArrow from './arrow-white.svg'
import ShowHisBg from './his-bg-back.jpg'
import ShowHisBg2 from './hisBg_2.jpg'
import ShowHisBefore from './hisBg_before.jpg'
import ShowHisAfter from './hisBg_after.jpg'
import Kanhoji from './kanhoji.jpg'
import KanhojiSeal from './kanhoji_seal.jpeg'
import SarkhelSeal from './sarkhel_seal.jpg'
import Sword from './sword.svg'
import Sword2 from './sword2.svg'
import SuggIconOne from './ps-elem_03.png'
import SuggIconTwo from './ps-elem_05.png'
import SuggIconThree from './ps-elem_13.png'
import SuggIconFour from './ps-elem_10.png'
import HomeSlide from './home-1-slide-background.jpg'
import HisNew1 from './his1.jpg'
import His2 from './his2.jpg'
import His3 from './his3.jpeg'
import His4 from './his4.PNG'
import His5 from './his5.PNG'
import His6 from './his6.PNG'
import HarendraBurea from './harendra-burea.jpg'
import BeenaMahawar from './beena-mahawar.jpg'
import ErKhemChand from './eng-khemchand.jpg'
import HarshMahawar from './harsh-mahawar.jpg'
import UserBlank from './user-blank.png'
import Mahendra from './mahi2.jpg'
import SansthaTitle from './koligif.gif'
import Identity from './id-card.png'
import Kamal from './kamal.jpg'
import Yogesh from './yogi.jpeg'
import  BkKoli from './bk_koli.jpg'



















export const ImageObj={
   Tanhaji:Tanhaji,
   Laxmi:Laxmi,
   Yashwant:Yashwant,
   Teacher1:Teacher1,
   Twitter:Twitter,
   Facebook:Facebook,
   RaamNath:RaamNath,
   Marriage:Marriage,
   SideOne:SideOne,
   SideTwo:SideTwo,
   Heart:Heart,
   Bride:Bride,
   Groom:Groom,
   His1:His1,
   HistoryBg:HistoryBg,
   King:King,
   OldBg:OldBg,
   Hands:Hands,
   Poor:Poor,
   Study:Study,
   HistoryIcon:HistoryIcon,
   ShipIcon:ShipIcon,
   SwordIcon:SwordIcon,
   KingIcon:KingIcon,
   GodIcon:GodIcon,
   GotraIcon:GotraIcon,
   FamouseIcon:FamouseIcon,
   ForceIcon:ForceIcon,
   CastIcon:CastIcon,
   GetImg:GetImg,
   RaamKovi:RaamKovi,
   RaamKoviTwo:RaamKoviTwo,
   RaamKoviThree:RaamKoviThree,
   RaamFour:RaamFour,
   RaamFive:RaamFive,
   RaamSix:RaamSix,
   Rajesh:Rajesh,
   Ranjeeta:Ranjeeta,
   Committee:Committee,
   WebBlue:WebBlue,
   Jesse:Jesse,
   TeamImage:TeamImage,
   DirectorOne:DirectorOne,
   DirectorTwo:DirectorTwo,
   DirectorLongOne:DirectorLongOne,
   DirectorLongTwo:DirectorLongTwo,
   AB:AB,
   Lettire:Lettire,
   Urban:Urban,
   JK:JK,
   WorkIcon:WorkIcon,
   StudiesIcon:StudiesIcon,
   LivesInIcon:LivesInIcon,
   LocationIcon:LocationIcon,
   Mandhata:Mandhata,
   GoutamBuddha:GoutamBuddha,
   Mahamaya:Mahamaya,
   Communitytree:Communitytree,
   KuwarJiBavaliya:KuwarJiBavaliya,
   News2:News2,
   News3:News3,
   News4:News4,
   News5:News5,
   News6:News6,
   Qoute_1:Qoute_1,
   Qoute_2:Qoute_2,
   MaleDoc:MaleDoc,
   FacebookNew:FacebookNew,
   Insta:Insta,
   TwitterNew:TwitterNew,
   WhiteArrow:WhiteArrow,
   ShowHisBg:ShowHisBg,
   ShowHisBg2:ShowHisBg2,
   ShowHisBefore:ShowHisBefore,
   ShowHisAfter:ShowHisAfter,
   BgWood:BgWood,
   Kanhoji:Kanhoji,
   KanhojiSeal:KanhojiSeal,
   SarkhelSeal:SarkhelSeal,
   Sword:Sword,
   Sword2:Sword2,
   SuggIconOne:SuggIconOne,
   SuggIconTwo:SuggIconTwo,
   SuggIconThree:SuggIconThree,
   SuggIconFour:SuggIconFour,
   HomeSlide:HomeSlide,
   HisNew1:HisNew1,
   His2:His2,
   His3:His3,
   His4:His4,
   His5:His5,
   His6:His6,
   HarendraBurea:HarendraBurea,
   BeenaMahawar:BeenaMahawar,
   ErKhemChand:ErKhemChand,
   HarshMahawar:HarshMahawar,
   UserBlank:UserBlank,
   Mahendra:Mahendra,
   SansthaTitle:SansthaTitle,
   Identity:Identity,
   Kamal:Kamal,
   Yogesh:Yogesh,
   BkKoli:BkKoli,






}