import React, { useState, useEffect, useCallback} from "react"
import axios from "axios"
import PORTNUMBER from '../../../../api/api'
import { useNavigate,useParams } from "react-router-dom"
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import { ToastContainer, toast, } from "react-toastify";

import { toasterValue, toasterErrValue } from '../../../../redux/actions/action'
import { useDispatch } from 'react-redux';
import IMAGE_HOST from '../../../../api/api'
import { Link } from 'react-router-dom'




import FormValidation from '../../../../formValidation/formValidation'



const EditStudents = () => {
    const navigate = useNavigate();
    const { id } = useParams()
    const TOKEN = localStorage.getItem("token")
    const UserTokenId = localStorage.getItem("UserTokenId")
    const TOKEN_REF_ID = localStorage.getItem("RefId")
    const dispatch = useDispatch()
    const HOST_PORT = IMAGE_HOST.IMAGE_HOST

        // modal active
        const [activeShow, setActiveShow] = useState(false);
        const [inActiveShow, setInActiveShow] = useState(false);
        const [statusId, setStatusId] = useState();
    
    
    const { t } = useTranslation();
    const PORT = PORTNUMBER.PORTNUMBER

    const [userIdData, setUserIdData] = useState(["not"])

    const {control, register, handleSubmit } = useForm();
    
    const [imageTypeErr, setImageTypeErr] = useState("")
    const [submitDisabled, setSubmitDisabled] = useState(null)

    const FormValidationNew = FormValidation

    const [formErrMsg, setFormErrMsg] = useState({
        Email: "",
        Mobile: "",
        default: "",
        Success: null
    })

    const [state, setState] = useState({
        onMind: "",
        image: "",
    })


    const activeHandleClose = () => setActiveShow(false);
    const activeHandleShow = (id) => {
        setActiveShow(true);
        setStatusId(id)

    }

   

    const onImageChange = (event) => {
        var fileName = event.target.value;
        var dot = fileName.lastIndexOf(".") + 1;
        var extFile = fileName.substr(dot, fileName.length).toLowerCase();
        if (extFile == "jpg" || extFile == "jpeg"  || extFile == "png") {
            if (event.target.files && event.target.files[0]) {
                let reader = new FileReader();
                reader.onload = (e) => {
                    setState({ image: e.target.result });
                    setImageTypeErr("")
                    setSubmitDisabled(false)
                };
                reader.readAsDataURL(event.target.files[0]);
    
            }
        } else {
            setSubmitDisabled(true)
            setImageTypeErr("Only jpg, png and jpeg image files allowed!")
        }

    }

    // dote of birth 
    let DobLoad = new Date(userIdData.dob);
    DobLoad.toDateString()
    const DateLoad = userIdData.dob === undefined ? new Date() : DobLoad





    const LoadProfile = useCallback( async () => {
        await axios.post(`${PORT}/topStudents/student`, { studentId: id }, { headers: { authorization: `Bearer ${TOKEN}` } })
            .then((res) => {
                setUserIdData(res.data.data)
            })
    },[id,TOKEN,PORT])

    useEffect(() => {
        FormValidationNew()
        LoadProfile()
    }, [FormValidationNew,LoadProfile])

    const onSubmit = async (data) => {

        const imageFiles = document.getElementById('profileImage').files.length;
        
        const fs = new FormData();
        fs.append("memberId",TOKEN_REF_ID);
        for(const [key] of Object.entries(data)){
            data[key] = (data[key] === undefined || data[key] === null || data[key] === "") ? userIdData[key] : data[key];
            fs.append(key, data[key]);
         
        }
        if (imageFiles > 0 ) {
            const File = document.getElementById('profileImage').files[0];
            fs.append("image",File);
        }
       

        // for(const [key] of Object.entries(data)){
    
        //     data[key] = (data[key] === undefined || data[key] === null || data[key] === "") ? userIdData[key] : data[key]
        // }
       
      
            const userCreate = await axios.post(`${PORT}/member/update`,fs, { headers: { authorization: `Bearer ${TOKEN}` } });
           
            if(userCreate.data.success === true){
                dispatch(toasterValue(" Profile updated successfully."))
                navigate('/')
                navigate(`profile/${UserTokenId}`)
            }else{
                dispatch(toasterErrValue("Updated profile  not successfully."))
            }
            switch (userCreate.data.message) {
                case "Email is already exist!":
                    setFormErrMsg({
                        Email: userCreate.data.message,
                    });
                    break;

                case "Mobile number is already exist!":
                    setFormErrMsg({
                        Mobile: userCreate.data.message,
                    });
                    break;
                
               
                default:
                    setFormErrMsg({
                        default: userCreate.data.message,
                    });
            
        }

       

    }
    


    useEffect(() => {
        document.body.classList.add('remove-home-header', 'admin-dashboard');
        return () => {
          document.body.classList.remove('remove-home-header', 'admin-dashboard');
    
        }

      }, [])


    useEffect(() => {
        FormValidationNew()
    
    },[FormValidationNew])

    return (
        <div className="tables-field">
          <div className="project-table">
                <div className="col-12">
                    <div className="row">
                        <div className="col-6">
                            <div className="main-head">
                                <h4>Add New Student</h4>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="theme-btn text-right top-head">
                                <Link to="/userManagement" className="btn">Back</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="table-order-box shadow">
                    <div className="tab-head">
                    <small  className=" form-text d-block text-center mb-4 text-muted"></small>
                        <form onSubmit={handleSubmit(onSubmit)} className="needs-validation" noValidate>
                            <div className="row">
                            <div className="col-lg-6">
                                    <div className="form-group">
                                    <input id="studentImage" name="image" {...register('image', { required: false })} type="file" className="form-control" />

                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <input type="text" className="form-control" id="validationCustom01"  {...register('full_name', { required: true })} placeholder=" Name" required />
                                        <div className="invalid-feedback">
                                            User name required.
                                        </div>

                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <input type="text" className="form-control" id="validationCustomTwo1" {...register('father_name', { required: true })} placeholder="Father Name" required />
                                        <div className="invalid-feedback">
                                           Please Enter Father Name
                                        </div>
                                     
                                    </div>
                                </div>
                     
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <input type="text" className="form-control" id="validationCustomThree1" {...register('class', { required: true })} placeholder="Class" required />
                                        <div className="invalid-feedback">
                                            Please enter class.
                                        </div>
                                      
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <input type="number" className="form-control" id="validationCustomFour1" {...register('percentage', { required: true })} placeholder="Percentage" required />
                                        <div className="invalid-feedback">
                                            Please enter percentage.
                                        </div>
                                      
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <input type="text" className="form-control" id="validationCustomFive1" {...register('school', { required: true })} placeholder="School Name" required />
                                        <div className="invalid-feedback">
                                            Please enter school name.
                                        </div>
                                      
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <input type="text" className="form-control" id="validationCustomSix1" {...register('address', { required: true })} placeholder="Address" required />
                                        <div className="invalid-feedback">
                                            Please enter adress.
                                        </div>
                                      
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <input type="text" className="form-control" id="validationCustomSeven1" {...register('roll_num', { required: false })} placeholder="Year"  />
                                        <div className="invalid-feedback">
                                            Please enter Year.
                                        </div>
                                      
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <input type="text" className="form-control" id="validationCustomEight1" {...register('studentid', { required: false })} placeholder="Student Year "  />
                                        <div className="invalid-feedback">
                                            Please enter Year.
                                        </div>
                                      
                                    </div>
                                </div>
                             
                                <div className="col-lg-12">
                                    <div className="theme-btn btn-group-right text-right">
                                        <button type="submit" className="btn">Save</button>
                                        <Link to="/userManagement" className="btn">Cancel</Link>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
    
          
            <ToastContainer />
        </div>
    )
}
export default EditStudents;