
import React, { useEffect } from "react"
import { BrowserRouter as Router, Route, Routes, Navigate, Outlet } from "react-router-dom";
import UserManagement from "../../components/admin/components/userManagement/userManagement";
import GovtServantManagement from '../../components/admin/components/govtServantManagement/govtServantManagement'
import SideNav from '../../components/admin/sideNav/sidenav'
import HeaderAdmin from '../../components/admin/header/header'
import TopStudents from '../../components/admin/components/topStudents/topStudents'
import EventImages from '../../components/admin/components/eventImages/addEventImages'
import AddEvents from '../../components/admin/components/eventImages/addEvents'
import SocietyNews from '../../components/admin/components/societyNews/addSocietyNews'
import ContentList from '../../components/admin/components/contentMangement/contentList/contentList'
import EditContent from '../../components/admin/components/contentMangement/editContent/editContent'

// admin login routes
import ResetMailAdmin from '../../components/admin/auth/resetMail/resetMail'
import ResetPasswordAdmin from '../../components/admin/auth/resetPassword/resetPassword'
import ResetPasswordLinkAdmin from '../../components/admin/auth/resetPwLink/resetPasswordLink'
import EditStudents from "../../components/admin/components/topStudents/editStudents";
import AdminLogin from "../../components/admin/auth/adminLogin/adminLogin";


const AdminRoutes = ({ isAuthedAdmin, setauth }) => {

    return (

        <Routes>
            <Route path="/admin" element={<SideNav />}>
                <Route index element={<AdminLogin isAuthedAdmin={isAuthedAdmin} />} />
                <Route path="userManagement" element={<UserManagement />} />
                <Route path="resetmail" element={<ResetMailAdmin />} />
                <Route path="ResetPasswordAdmin/:id" element={<ResetPasswordAdmin />} />
                <Route path="resetPasswordLink/:id" element={<ResetPasswordLinkAdmin />} />
                <Route path="govtServantManagement" element={<GovtServantManagement />} />
                <Route path="topStudents" element={<TopStudents />} />
                <Route path="topStudents/:id" element={<EditStudents />} />
                <Route path="addEventImages/:id" element={<EventImages />} />
                <Route path="addEvents" element={<AddEvents />} />
                <Route path="societyNews" element={<SocietyNews />} />
                <Route path="contentList" element={<ContentList />} />
                <Route path="contentList/:key" element={<EditContent />} />
            <Route path="*" element={<Navigate to="/admin/userManagement" />} />

                

            </Route>
            <Route path="*" element={<Navigate to="/admin" />} />
 
            {/* <Route path="/" element={<Home />} /> */}


        </Routes>

    )
}

export default AdminRoutes;