
import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import moment from 'moment'
import { ToastContainer, toast, } from "react-toastify";
import { useForm, Controller } from 'react-hook-form';
import { GiSwordsEmblem } from 'react-icons/gi';



// icons
import { FaCog, FaSearch } from 'react-icons/fa';
import { FaEye, FaBan, FaRegStar, FaEyeSlash } from 'react-icons/fa';
import { BiMessageRoundedCheck, BiMessageRoundedX } from 'react-icons/bi';

import { AiOutlineCheckCircle } from 'react-icons/ai';
import { FaImage } from 'react-icons/fa';


import PORTNUMBER_ADMIN from '../../../../api/api'




// Import Datepicker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from 'react-router-dom'

// react bootstrap
import { Modal, Button } from 'react-bootstrap'
import Pagination from "react-js-pagination";
import { MdDeleteForever } from 'react-icons/md';


const UserManagement = () => {
    const PORT_ADMIN = PORTNUMBER_ADMIN.PORTNUMBER_ADMIN
    const AdminToken = localStorage.getItem("AdminToken")

    // modal active
    const [activeShow, setActiveShow] = useState(false);
    const [inActiveShow, setInActiveShow] = useState(false);

    const [deleteShow, setDeleteShow] = useState(false);
    const [deleteId, setDeleteId] = useState();


    const [imageActiveShow, setImageActiveShow] = useState(false);
    const [imageIdPath, setImageIdPath] = useState("");



    const [truePostShow, setTruePostShow] = useState(false);
    const [falsePostShow, setFalsePostShow] = useState(false);

    const [statusId, setStatusId] = useState();


    // const [Loading, setLoading] = useState(false);

    const [Count, setCount] = useState(1);
    const [indexPage, setIndexPage] = useState(1);
    const [activePage, setActivePage] = useState()


    const [users, setUser] = useState([]);
    const [loading, setLoading] = useState(false);


    const [userPerPage] = useState(50);

    const [searchTerm, setSearchTerm] = useState("");

    const [filterData, setFilterData] = useState([]);
    const [filterCount, setFilterCount] = useState([]);

    const [filterVisibility, setFilterVisibility] = useState(true);


    const [visibilityNew, setVisibilityNew] = useState(true)
    const [visibility, setVisibility] = useState(true)
    const [visibilityConfirm, setVisibilityConfirm] = useState(true)
    const [show, setShow] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [passErr, setPassErr] = useState();
    const [currentpPassErr, setCurrentPassErr] = useState();
    const [passwordId, setPasswordId] = useState();


    const { register, control, handleSubmit, reset } = useForm();

    const handleClose = () => setShow(false);
    const handleClosePw = () => {
        setShowPassword(false)
        setVisibilityNew(true)
        setVisibility(true)
        setVisibilityConfirm(true)
        reset()
    }
    const handleShowPw = (id) =>{
        setPasswordId(id)
        setShowPassword(true);
    } 


    const pwVisibility = (value) => {
        setVisibility(value)
    }
    const pwVisibilityConfirm = (value) => {
        setVisibilityConfirm(value)
    }

    const pwVisibilityNew = (value) => {
        setVisibilityNew(value)
    }


    const deleteHandleClose = () => setDeleteShow(false);
    const deleteHandleShow = (id) => {
        setDeleteShow(true);
        setDeleteId(id)

    }



    const activeImageHandleClose = () => setImageActiveShow(false);
    const activeImageHandleShow = (statusId) => {
        setImageIdPath(statusId)
        setImageActiveShow(true);
        // setStatusId(id)

    }


    const activeHandleClose = () => setActiveShow(false);
    const activeHandleShow = (id) => {
        setActiveShow(true);
        setStatusId(id)

    }

    const truePostHandleClose = () => setTruePostShow(false);
    const truePostHandleShow = (id) => {
        setTruePostShow(true);
        setStatusId(id)

    }

    const inActiveHandleClose = () => setInActiveShow(false);
    const inActiveHandleShow = (id) => {
        setInActiveShow(true);
        setStatusId(id)


    }


    const falsePostHandleClose = () => setFalsePostShow(false);
    const falsePostHandleShow = (id) => {
        setFalsePostShow(true);
        setStatusId(id)


    }



    const LoadUser = useCallback(async (pageNumber) => {

        setLoading(false);
        await axios.post(`${PORT_ADMIN}/user/list`, { page: pageNumber }, { headers: { authorization: `Bearer ${AdminToken}` } })
            .then((res) => {

                setUser(res.data.data.userList);
                setCount(res.data.data.totalUser);
                setLoading(true);
                setFilterVisibility(true)
            })


        reset()

    }, [PORT_ADMIN, AdminToken, reset,], [users, Count])



    const deleteUser = async () => {
        await axios.post(`${PORT_ADMIN}/user/delete`, { userId: deleteId }, { headers: { authorization: `Bearer ${AdminToken}` } }).then((res, err) => {
            LoadUser()
            toast.success("User is deleted successfully.")
            deleteHandleClose()
        })
    }



    const activeUser = async () => {
        await axios.post(`${PORT_ADMIN}/user/status/change`, { userId: statusId, status: "active", type: "status" }, { headers: { authorization: `Bearer ${AdminToken}` } }).then((res, err) => {
            LoadUser()
            toast.success("User is activated successfully.")
            activeHandleClose()
        })
    }

    // const activeImageView = async () => {
    //    setImageIdPath(statusId)
    //    console.log("idddddddddd", statusId)
    // }
    const inActiveUser = async () => {
        await axios.post(`${PORT_ADMIN}/user/status/change`, { userId: statusId, status: "inactive", type: "status" }, { headers: { authorization: `Bearer ${AdminToken}` } }).then((res, err) => {

            LoadUser()
            toast.success("User is inactivated successfully.")
            inActiveHandleClose()
        })
    }

    const truePostUser = async () => {
        await axios.post(`${PORT_ADMIN}/user/status/change`, { userId: statusId, status: true, type: "post" }, { headers: { authorization: `Bearer ${AdminToken}` } }).then((res, err) => {
            LoadUser()
            toast.success("User is post status activated successfully.")
            truePostHandleClose()
        })
    }
    const falsePostUser = async () => {
        await axios.post(`${PORT_ADMIN}/user/status/change`, { userId: statusId, status: false, type: "post" }, { headers: { authorization: `Bearer ${AdminToken}` } }).then((res, err) => {

            LoadUser()
            toast.success("User post status is inactivated successfully.")
            falsePostHandleClose()
        })
    }




    const handlePageChange = (pageNumber) => {
        setActivePage(pageNumber);
        setIndexPage(pageNumber)
        LoadUser(pageNumber)
        setLoading(false);
    }



    const changePassword = (data, e) => {
        if (data.password === data.confirm_password) {
            setPassErr("")
            axios.post(`${PORT_ADMIN}/user/change/password`, {userId: passwordId,  password: data.password }, { headers: { authorization: `Bearer ${AdminToken}` } })
                .then((res) => {
                    console.log("pass", res)
                    if (res.data.success !== false) {
                        handleClosePw()
                        reset();
                        // LogOut()
                        setCurrentPassErr("")


                    } else {
                        setCurrentPassErr(res.data.message)
                    }
                })
        } else {
            setPassErr("Confirm password not matched")
        }

    }



    useEffect(() => {

        LoadUser()
    }, [LoadUser])




    const sendPage = (pageNumber) => {
        axios.post(`${PORT_ADMIN}/user/filteredList`, { page: pageNumber }, { headers: { authorization: `Bearer ${AdminToken}` } })
    }

    const onSubmit = async (data) => {

        setLoading(false);
        // sendPage()
        await axios.post(`${PORT_ADMIN}/user/filteredList`, data, { headers: { authorization: `Bearer ${AdminToken}` } })
            .then((res) => {
                setCount(res.data.data.totalUser)
                setFilterData(res.data.data.userList)
                setLoading(true);
                setFilterVisibility(false)

            })

    }


    useEffect(() => {
        document.body.classList.add('remove-home-header', 'admin-dashboard');
        return () => {
            document.body.classList.remove('remove-home-header', 'admin-dashboard');

        }
    }, [])




    return (
        <div className="tables-field">
            <div className="project-table">
                <div className="col-12">
                    <div className="row">
                        <div className="col-6">
                            <div className="main-head">
                                <h4>Add New User</h4>
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="theme-btn top-head text-right">
                                {/* <Link to="/dmin/userManagement" className="btn">Back</Link> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="table-order-box shadow">
                    <div className="tab-head">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="row">
                                <div className="col-lg-4">
                                    <div className="form-group">
                                        <input type="text" {...register('name', { required: false })} className="form-control" placeholder="Name" />
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="form-group">
                                        <input type="email" {...register('email', { required: false })} className="form-control" placeholder="Email" />
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="form-group">
                                        <input type="text" {...register('mobile', { required: false })} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Mobile" />
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="form-group">
                                        <select className="form-select form-control" {...register('type', { required: false })} aria-label="Default select example">
                                            <option className="disabled-value d-none" value=""  >Type</option>
                                            <option value="member">Member</option>
                                            <option value="politician">Politician</option>
                                            <option value="govt">Govt Employee</option>

                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="form-group">
                                        <select className="form-select form-control" {...register('status', { required: false })} aria-label="Default select example">
                                            <option className="disabled-value d-none" value=""  >All Status</option>
                                            <option value="active">Active</option>
                                            <option value="inactive">Inactive</option>
                                        </select>
                                    </div>
                                </div>


                                <div className="col-lg-12">
                                    <div className="theme-btn btn-group-right text-right">
                                        <button type="submit" className="btn">Search</button>
                                        <button type="reset" onClick={() => LoadUser()} className="btn">Reset</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className="project-table">
                <div className="form-search">
                    <form>
                        <div className="form-group icon-input top-search">
                            <input type="text" className="form-control" onChange={(event) => { setSearchTerm(event.target.value) }} placeholder="Search" />
                            <FaSearch />
                        </div>

                    </form>
                </div>
                <div className="table-order shadow">

                    <table>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Email</th>
                                <th>Mobile</th>
                                <th>Registered On</th>
                                <th>Status</th>
                                <th>Actions </th>
                            </tr>
                        </thead>
                        <tbody>


                            {filterVisibility ? users && users.length ? users.filter((val) => {
                                const date = val.createdAt
                                if (searchTerm === "") {
                                    return val
                                } else if (val.name.toLowerCase().includes(searchTerm.toLowerCase())) {
                                    return val
                                } else if (val.email.toLowerCase().includes(searchTerm.toLowerCase())) {
                                    return val
                                } else if (val.mobile.toLowerCase().includes(searchTerm.toLowerCase())) {
                                    return val
                                } else if (moment(date).format("DD/MM/YYYY").toLowerCase().includes(searchTerm.toLowerCase())) {
                                    return val
                                } else if (val.status.toLowerCase().includes(searchTerm.toLowerCase())) {
                                    return val
                                } else {
                                    return false
                                }


                            }).map(user => (
                                <tr key={user._id}>
                                    <td >
                                        {user.name}
                                    </td>
                                    <td>{user.email}</td>
                                    <td>{user.mobile}</td>
                                    <td>{moment(user.createdAt).format("DD/MM/YYYY")}</td>
                                    <td className=""><span className={`badge bg-success ${user.status}`}>{user.status}</span>
                                    </td>
                                    <td className="action-top">
                                        {/* <Link to={`/editNewUser/${user._id}`} className="icons-action"><FaEdit /></Link> */}
                                        <Link to={`/userWineList/${user._id}`} className="icons-action"><FaEye /></Link>
                                        <Link to={`/userFavoriteList/${user._id}`} className="icons-action"><FaRegStar /></Link>
                                        {
                                            user.status === "inactive" ? <span onClick={() => activeHandleShow(user._id)} className="icons-action"><AiOutlineCheckCircle /></span> : <span onClick={() => inActiveHandleShow(user._id)} className="icons-action"><FaBan /></span>

                                        }

                                        {
                                            user.post_status === false ? <span onClick={() => truePostHandleShow(user._id)} className="icons-action"><BiMessageRoundedX /></span> : <span onClick={() => falsePostHandleShow(user._id)} className="icons-action"><BiMessageRoundedCheck /></span>

                                        }
                                        <span onClick={() => activeImageHandleShow(user.identity)} className="icons-action"><FaImage /></span>
                                        {
                                            <span onClick={() => deleteHandleShow(user._id)} className="icons-action"><MdDeleteForever /></span>

                                        }
                                          <span onClick={() => handleShowPw(user._id)} className="icons-action"><FaCog /></span>


                                    </td>

                                </tr>

                            )) : <tr><td className="no-data">NO ANY USER FOUND</td></tr>
                                :
                                filterData && filterData.length ? filterData.filter((val) => {
                                    const date = val.createdAt
                                    if (searchTerm === "") {
                                        return val
                                    } else if (val.name.toLowerCase().includes(searchTerm.toLowerCase())) {
                                        return val
                                    } else if (val.email.toLowerCase().includes(searchTerm.toLowerCase())) {
                                        return val
                                    } else if (val.mobile.toLowerCase().includes(searchTerm.toLowerCase())) {
                                        return val
                                    } else if (moment(date).format("DD/MM/YYYY").toLowerCase().includes(searchTerm.toLowerCase())) {
                                        return val
                                    } else if (val.status.toLowerCase().includes(searchTerm.toLowerCase())) {
                                        return val
                                    } else {
                                        return false
                                    }


                                }).map(user => (
                                    <tr key={user._id}>
                                        <td >
                                            {user.name}
                                        </td>
                                        <td>{user.email}</td>
                                        <td>{user.mobile}</td>
                                        <td>{moment(user.createdAt).format("DD/MM/YYYY")}</td>
                                        <td className=""><span className={`badge bg-success ${user.status}`}>{user.status}</span>
                                        </td>
                                        <td className="action-top">
                                            {/* <Link to={`/editNewUser/${user._id}`} className="icons-action"><FaEdit /></Link> */}
                                            <Link to={`/userWineList/${user._id}`} className="icons-action"><FaEye /></Link>
                                            <Link to={`/userFavoriteList/${user._id}`} className="icons-action"><FaRegStar /></Link>
                                            {
                                                user.status === "inactive" ? <span onClick={() => activeHandleShow(user._id)} className="icons-action"><AiOutlineCheckCircle /></span> : <span onClick={() => inActiveHandleShow(user._id)} className="icons-action"><FaBan /></span>

                                            }
                                            {
                                                user.post_status === false ? <span onClick={() => truePostHandleShow(user._id)} className="icons-action"><BiMessageRoundedX /></span> : <span onClick={() => falsePostHandleShow(user._id)} className="icons-action"><BiMessageRoundedCheck /></span>

                                            }

                                            {
                                                <span onClick={() => deleteHandleShow(user._id)} className="icons-action"><MdDeleteForever /></span>

                                            }

                                        </td>

                                    </tr>

                                )) : <tr ><td className="no-data">NO ANY USER FOUND</td></tr>}



                        </tbody>
                    </table  >
                    {
                        !loading ?
                            <div className="loader-wrapper">
                                <div className="loader">
                                    <div className="loading-svg">
                                        <GiSwordsEmblem />
                                    </div>
                                </div>
                            </div> : ""
                    }


                    {/* <BootstrapTable keyField="id" colunms={columns} data={posts}  filter={filterFactory()} /> */}
                </div>
                <div className='pagination-top'>
                    <Pagination
                        activePage={activePage}
                        itemsCountPerPage={10}
                        totalItemsCount={Count}
                        pageRangeDisplayed={10}
                        onChange={handlePageChange}
                    />
                </div>



            </div>

            {/* modals */}

            {/* active modal */}
            <Modal className="status-modal" animation={false} show={activeShow} onHide={activeHandleClose}>

                <Modal.Body>Are you sure you want to activate user?</Modal.Body>
                <Modal.Footer>
                    <div className="theme-btn btn-group-right text-right">
                        <Button variant="secondary" onClick={activeUser}>
                            Yes
                        </Button>
                        <Button variant="primary" onClick={activeHandleClose}>
                            No
                        </Button>
                    </div>

                </Modal.Footer>
            </Modal>
            {/* active modal end */}

            {/* inactive modal */}
            <Modal className="status-modal" animation={false} show={inActiveShow} onHide={inActiveHandleClose}>
                <Modal.Body>Are you sure you want to inactivate user?</Modal.Body>
                <Modal.Footer>
                    <div className="theme-btn btn-group-right text-right">
                        <Button variant="secondary" onClick={inActiveUser}>
                            Yes
                        </Button>
                        <Button variant="primary" onClick={inActiveHandleClose}>
                            No
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>
            {/* inactive modal end */}


            {/* active modal */}
            <Modal className="status-modal" animation={false} show={truePostShow} onHide={truePostHandleClose}>

                <Modal.Body>Are you sure you want to activate post user?</Modal.Body>
                <Modal.Footer>
                    <div className="theme-btn btn-group-right text-right">
                        <Button variant="secondary" onClick={truePostUser}>
                            Yes
                        </Button>
                        <Button variant="primary" onClick={truePostHandleClose}>
                            No
                        </Button>
                    </div>

                </Modal.Footer>
            </Modal>
            {/* true post user modal end */}

            {/* false post user modal */}
            <Modal className="status-modal" animation={false} show={falsePostShow} onHide={falsePostHandleClose}>
                <Modal.Body>Are you sure you want to inactivate post user?</Modal.Body>
                <Modal.Footer>
                    <div className="theme-btn btn-group-right text-right">
                        <Button variant="secondary" onClick={falsePostUser}>
                            Yes
                        </Button>
                        <Button variant="primary" onClick={falsePostHandleClose}>
                            No
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>
            {/* false modal end */}



            {/* image view user modal */}
            <Modal className="status-modal" animation={false} show={imageActiveShow} onHide={activeImageHandleClose}>
                {/* <Modal.Body> <img src={`${HOST_PORT}/${postById.image}`} alt="" /></Modal.Body> */}
                {imageIdPath}
                <Modal.Footer>
                    <div className="theme-btn btn-group-right text-right">
                        <Button variant="secondary" >
                            Yes
                        </Button>
                        <Button variant="primary" onClick={activeImageHandleClose}>
                            No
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>
            {/* image view user modal */}


            {/* active modal */}
            <Modal className="status-modal" animation={false} show={deleteShow} onHide={deleteHandleClose}>

                <Modal.Body>Are you sure you want to delete User?</Modal.Body>
                <Modal.Footer>
                    <div className="theme-btn btn-group-right text-right">
                        <Button variant="secondary" onClick={deleteUser}>
                            Yes
                        </Button>
                        <Button variant="primary" onClick={deleteHandleClose}>
                            No
                        </Button>
                    </div>

                </Modal.Footer>
            </Modal>
            {/* active modal end */}


            {/* change edit */}
            <div className="modal-edit">
                <Modal show={showPassword} onHide={handleClosePw} className="edit-content">

                    <div className="max-new-second max-margin">
                        <div className="main-theme bg-theme border shadow">
                            <Modal.Header closeButton>
                                <div className="select-steps-box">
                                    <div className="input-head">
                                        <h2>Change Password</h2>
                                    </div>
                                </div>
                            </Modal.Header>
                            <section className="select-state">
                                <div className="state-bor">
                                    <div className="log-in otp-num">

                                        <div className="select-box-top pr-0">
                                            <div className="input-box">
                                                <form onSubmit={handleSubmit(changePassword)}>
                                                    <div className="col-12 p-0">
                                                        <div className="row">
                                                            <div className="col-sm-12">
                                                                <div className="form-group icon-position">
                                                                    <input type={visibility ? "password" : "text"} className="form-control"  {...register('password', { required: true })} placeholder="Current Password" required />
                                                                    {
                                                                        visibility ?
                                                                            <span className="icon" onClick={() => pwVisibility(false)}>
                                                                                <FaEye />
                                                                            </span> :
                                                                            <span className="icon" onClick={() => pwVisibility(true)}>
                                                                                <FaEyeSlash />
                                                                            </span>
                                                                    }
                                                                    <small id="emailHelp" className=" form-text text-muted">{currentpPassErr}</small>
                                                                </div>
                                                            </div>

                                                            <div className="col-sm-12">
                                                                <div className="form-group icon-position">
                                                                    <input type={visibilityConfirm ? "password" : "text"} className="form-control" {...register('confirm_password', { required: true })} placeholder="Confirm Password" required />
                                                                    {
                                                                        visibilityConfirm ?
                                                                            <span className="icon" onClick={() => pwVisibilityConfirm(false)}>
                                                                                <FaEye />
                                                                            </span> :
                                                                            <span className="icon" onClick={() => pwVisibilityConfirm(true)}>
                                                                                <FaEyeSlash />
                                                                            </span>
                                                                    }
                                                                    <small id="emailHelp" className=" form-text text-muted">{passErr}</small>
                                                                </div>
                                                            </div>

                                                            <Modal.Footer>
                                                                <div className="theme-btn btn-group-right">
                                                                    <Button type="submit" className="btn" variant="secondary" >
                                                                        Update
                                                                    </Button>
                                                                    <Button type="reset" className="btn" variant="primary" onClick={handleClosePw}>
                                                                        Cancel
                                                                    </Button>
                                                                </div>
                                                            </Modal.Footer>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>

                </Modal>
            </div>
            <ToastContainer />
        </div>
    )
}
export default UserManagement;