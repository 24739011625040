import React, { Fragment, useState, useEffect } from "react";
import { useNavigate, useLocation, Navigate } from "react-router-dom";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { authHocTrueValue } from "../../redux/actions/action";
import Footer from "../../common/user/footer/footer";
import HeaderAdmin from "../../components/admin/header/header";
import AdminRoutes from "../adminRoutes/adminRoutes";
import AdminLogin from "../../components/admin/auth/adminLogin/adminLogin";
import ResetMail from "../../components/admin/auth/resetMail/resetMail";
import ResetPassword from "../../components/admin/auth/resetPassword/resetPassword";
import ResetPasswordLink from "../../components/admin/auth/resetPwLink/resetPasswordLink";
import SideNav from "../../components/admin/sideNav/sidenav";

// import AdminLogin from "../../components/admin/auth/adminLogin/adminLogin";
// import Footer from "../../common/user/footer/footer";
// import ResetMail from "../../components/admin/auth/resetMail/resetMail";
// import ResetPasswordLink from "../../components/admin/auth/resetPwLink/resetPasswordLink";

const RequireAuth = ({ children }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const AuthHoc = useSelector((state) => state.authHocValueReducer)
    const ToggleValue = useSelector((state) => state.modalValueReducer)

    const location = useLocation();
    // console.log("location.pathname", location.pathname);

    const [authTrue, setAuthTrue] = useState(false)
  

    const adminPath = location.pathname.split('/')[1];
    const PathName = location.pathname;




    useEffect(() => {

        const Auth1 = localStorage.getItem("token");
        const Auth2 = localStorage.getItem("AdminToken");





        if (String(adminPath) === "") {
            // dispatch(authHocTrueValue(false))
            document.body.classList.remove('admin-part');
            if (Auth1 === null) {
                navigate("/")
            } else {
                navigate(String(location !== undefined && location?.pathname !== undefined ? location?.pathname : "/"))

            }
        }

   


        if (String(adminPath) === "admin") {
            dispatch(authHocTrueValue(true))
            document.body.classList.add('admin-part');
            if (Auth2 === null) {
                // navigate("/admin")
            } else {
                navigate(String(location !== undefined && location?.pathname !== undefined ? location?.pathname : "/"))
            }
        }

    }, [PathName, ToggleValue])



    const isAuthedAdmin = () => {
        dispatch(authHocTrueValue(false))
        document.body.classList.remove('admin-part');
        localStorage.removeItem("AdminToken");
    }




    const unAuth = () => {
        localStorage.removeItem("firtName");
        localStorage.removeItem("token");
        dispatch(authHocTrueValue(false))
        document.body.classList.remove('admin-part');

    }





    return (


        AuthHoc === false ? (


            <Fragment>

                {/* {() => authUser()} */}

                {/* <HeaderLogin unAuth={unAuth}/> */}

                <Routes>
                    {children}


                    <Route path="/admin" element={<SideNav/>} >
                        <Route index element={<AdminLogin isAuthedAdmin={isAuthedAdmin} />} />
                        <Route path="resetmail" element={<ResetMail />} />
                        <Route path="ResetPasswordAdmin/:id" element={<ResetPassword />} />
                        <Route path="/admin/resetPasswordLink/:id" element={<ResetPasswordLink />} />
                    </Route>
                    {/* <Route path="*" element={<Navigate to="/admin" />} /> */}

                </Routes>
                <Footer />

            </Fragment>
        ) : (

            <Fragment>
                {/* {() => authAdmin()} */}


                {/* <HeaderAdmin isAuthed={isAuthed} unAuthedAdmin={unAuthedAdmin} /> */}
                <HeaderAdmin unAuth={unAuth} />
                <main className="main">
                    <AdminRoutes setauth={setAuthTrue} isAuthedAdmin={isAuthedAdmin} />
                </main>
                <Footer />
            </Fragment>


        )
    )
}

export default RequireAuth;

