import React,{useEffect} from 'react'
import { ImageObj } from '../../../assets/images/images';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

const RajaMandhata =()=>{
    const { t } = useTranslation();
    useEffect(() => {
        document.body.classList.add("choclate-bg");
        return(()=>{
        document.body.classList.remove("choclate-bg");

        })
   }, [])


    return(
        <React.Fragment>
        <Helmet>
            <title>Mandhatra Mandhata History</title>
            <meta name="description" content="Mandhatri Mandhata was the king of Ayodhya, the emperor of the Vedic period. The presiding deity of Suryavanshi Kshatriya Koli (Koli) is Lord Shri Mandhata Maharaja. King Mandhata ruled the whole earth, that is why Mandhata is known as Prithvipati!" />
            <meta name="keywords" content="Mandhatri, Mandhata, Kshatriya Koli, Prithvipati, Ikshvaku dynasty, Ashwamedha yagyas, Bindumati, Shashbindu, Muchkund, Ambareesh, Purukutsa" />
            <meta name="author" content="mahendra koli" />
            <link rel="canonical" href="https://www.kolisamajvikashsanstha.com/rajaMandhata" />
        </Helmet>
        <div className="container padding-mob-0">
        <div className="history-show-top bg" style={{backgroundImage: `url(${ImageObj.ShowHisBg2})`}}>
                <div className="show-history-inner">
                    <div className="koli-history show-his">
                        <h2>{t('HisMandhata.titleOne')}</h2>
                        <p>{t('HisMandhata.summeryOne')}</p>
                        <p>{t('HisMandhata.summeryTwo')}</p>
                        <p>{t('HisMandhata.summeryThree')}</p>
                        <p>{t('HisMandhata.summeryFour')}</p>
                        <p>{t('HisMandhata.summeryFive')}</p>
                    </div>
                </div>
            </div>
        </div>
        </React.Fragment>
    )
}

export default RajaMandhata;