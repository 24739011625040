import React from 'react'
import {ImageObj} from '../../../assets/images/images'

const Success = () => {
    return (
        <section className="success-people sec padding">
            <div className="container">
                <div className="row">
                    <div className="col-sm-6">
                        <div className="success-card">
                            <div className="card-inner">
                                <div className="inner-img">
                                    <img aria-hidden="true" src={ImageObj.Rajesh} alt="" />
                                </div>
                                <div className="inner-con text-center">
                                    <h3>Rajesh Chudasama</h3>
                                    <p>Rajesh Chudasama is Member of Parliament from Junagadh-Gir Somnath, Gujarat. He was previously a Member of the Gujarat Legislative Assembly. He was elected from Mangrol in Junagadh district as a candidate of the Bharatiya Janata Party in 2012. He has won the 2014 Lok Sabha election from Junagadh.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6">
                    <div className="success-card">
                            <div className="card-inner">
                                <div className="inner-img">
                                    <img aria-hidden="true" src={ImageObj.Ranjeeta} alt="" />
                                </div>
                                <div className="inner-con text-center">
                                    <h3>Ranjeeta Koli</h3>
                                    <p>Ranjeeta Koli is an Indian politician. She was elected to the Lok Sabha, lower house of the Parliament of India from Bharatpur, Rajasthan in the 2019 Indian general election as a member of the Bharatiya Janata Party. She won the election by getting 707992 votes which was nearly 61.74% of the votes.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Success;
