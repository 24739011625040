import axios from "axios"
import React, { useEffect, useState, useRef, useCallback  } from "react"
import PORTNUMBER from '../../../../api/api'
import { useNavigate } from "react-router-dom"
import { useForm, Controller } from "react-hook-form";
import { ImageObj } from "../../../../assets/images/images";
import { useTranslation } from 'react-i18next';
import ReCAPTCHA from "react-google-recaptcha"
import FormValidation from '../../../../formValidation/formValidation'
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { loginModalTrue, signUpModalTrue } from '../../../../redux/actions/action'

// Import Datepicker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import ButtonLoader from "../submitButton/submitButton";
const AddMemberForm = (props) => {

    const { t } = useTranslation();
    const PORT = PORTNUMBER.PORTNUMBER
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [isChecked, setIsChecked] = useState(false);

    const { control, register, handleSubmit, formState: { errors } } = useForm();


    // confirm password
    const [passwordErr, setPassErr] = useState()

    const [visibility, setVisibility] = useState(true)
    const [visibilityConfirm, setVisibilityConfirm] = useState(true)

    const [captcha, setCptcha] = useState(true)
    const [imageTypeErr, setImageTypeErr] = useState("")
    const [submitDisabled, setSubmitDisabled] = useState(false)
    const [spinLoadVisibility, setSpinLoadVisibility] = useState(false);

    const handleCheckboxChange = (e) => {
        const newChecked = e.target.checked;
        setIsChecked(newChecked);
        // Step 4: Send the value (log to console here for simplicity)
        console.log('Checkbox is checked:', newChecked);
    };

    const FormValidationNew = FormValidation

    const [formErrMsg, setFormErrMsg] = useState({
        Email: "",
        Mobile: "",
        default: "",
        Success: null
    })


    const [state, setState] = useState({
        onMind: "",
        image: "",
    })


 

     
    const eidRef = useRef(null);
   
    const scrollToEid = useCallback(() => {
        if (eidRef.current) {
          const elementPosition = eidRef.current.getBoundingClientRect().top + window.pageYOffset;
          window.scrollTo({ top: elementPosition, behavior: 'smooth' });
        } else {
          console.error('eidRef is not attached to any element.');
        }
      }, [eidRef]);

     




    const onImageChange = (event) => {
        var fileName = document.getElementById(props.profileimage ? props.profileimage : "ProfileImage").value;
        var dot = fileName.lastIndexOf(".") + 1;
        var extFile = fileName.substr(dot, fileName.length).toLowerCase();
        if (extFile == "jpg" || extFile == "jpeg" || extFile == "png") {
            if (event.target.files && event.target.files[0]) {
                let reader = new FileReader();
                reader.onload = (e) => {
                    setState({ image: e.target.result });
                    setImageTypeErr("")
                    setSubmitDisabled(false)
                };
                reader.readAsDataURL(event.target.files[0]);

            }
        } else {
            setSubmitDisabled(true)
            setImageTypeErr("Only jpg, png and jpeg image files allowed!")
        }


    }


    // confirm password

    const pwVisibility = (value) => {
        setVisibility(value)
    }
    const pwVisibilityConfirm = (value) => {
        setVisibilityConfirm(value)
    }

    function onChangeCaptcha(value) {
        setCptcha(false)
        if (value === null) {
            setCptcha(true)
        }
    }

    useEffect(() => {
        FormValidationNew(scrollToEid)

    }, [FormValidationNew, scrollToEid])

    // const imageFiles = document.getElementById('profileImage');
    // console.log("imageFiles", imageFiles)

    const onSubmit = async (data) => {

        console.log("data", data)
        setSpinLoadVisibility(true)
        setSubmitDisabled(true)

        const imageFiles = document.getElementById(props.profileimage ? props.profileimage : 'ProfileImage').files[0];



        const fs = new FormData();
        for (var key in data) {
            fs.append(key, data[key]);
        }
        fs.append("image", imageFiles);
        if (isChecked === true) {
            fs.append("type", "govt");

        } else {
            fs.append("type", "member");

        }





        if (data.password !== data.confirm_password) {
            setPassErr("Password not mached")
            setSpinLoadVisibility(false)
            setSubmitDisabled(false)


        } else {

            const userCreate = await axios.post(`${PORT}/user/create`, fs);
            setSpinLoadVisibility(false)
            setSubmitDisabled(false)
            switch (userCreate.data.message) {
                case "Email is already exist!":
                    setFormErrMsg({
                        Email: userCreate.data.message,
                    });
                    break;

                case "Mobile number is already exist!":
                    setFormErrMsg({
                        Mobile: userCreate.data.message,
                    });
                    break;

                case "User is created successfully!":
                    setFormErrMsg({
                        Success: true
                    });

                    toast("You have Registerd  successfully.")
                    dispatch(loginModalTrue(true))
                    dispatch(signUpModalTrue(false))


                    // await axios.post(`${PORT}/member/create`, fs)
                    //     .then((res) => {

                    //         if (res.data.success === true) {

                    //             toast("You have Registerd  successfully.")
                    //             dispatch(loginModalTrue(true))
                    //             dispatch(signUpModalTrue(false))
                    //         } else {

                    //             dispatch(toasterErrValue("You have not Registerd successfully."))
                    //         }
                    //     })

                    break;

                default:
                    toast(userCreate.data[0].msg)
                    setFormErrMsg({
                        default: userCreate.data.message,
                    });
            }
        }

    }


    return (
        <React.Fragment>
            {/* <Helmet>
                <title>Koli Samaj Form</title>
                <meta name="description" content="To become a member of Koli Samaj Sanstha, fill in the details form and login" />
                <meta name="keywords" content="Application Form Member" />
                <meta name="author" content="mahendra koli" />
                <link rel="canonical" href="https://www.kolisamajvikashsanstha.com/addMemberForm" />
            </Helmet> */}
            <section className="app-form sec padding" ref={eidRef}>
                <div className="container ">
                    <div className="touch-head app">
                        <h4>Application Form Member</h4>
                        <p className="mb-1">We will keep your details confidential</p>
                        <p>हम आपका विवरण गोपनीय रखेंगे</p>



                    </div>
                    <div className="application main-theme shadow" >
                        <div className="touch-form app">
                            <form onSubmit={handleSubmit(onSubmit)}  className="needs-validation" noValidate>
                                <div className="col-12">
                                    <div className="information-user d-flex">
                                        <div className="info-image">
                                            {state.image ?
                                                <img src={state.image} alt="" />
                                                : <img aria-hidden="true" src={ImageObj.UserBlank} alt="Committee" />
                                            }
                                        </div>
                                        <h3>{!state.image ? "Upload Image" : "Change Image"} <input id={props.profileimage ? props.profileimage : "ProfileImage"} name="image" onChange={onImageChange} type="file" className="form-control" required={state.image.identity ? false : true}/>
                                            <div className="invalid-feedback">
                                                Please upload Your Image.
                                            </div>

                                            <div className="form-text text-muted">
                                                {imageTypeErr}
                                            </div>
                                        </h3>
                                        {/* <h4>Remove</h4> */}
                                    </div>
                                </div>
                                <div className="col-sm-12 ">
                                    <small className=" form-text d-block text-center mb-4 text-muted">{formErrMsg.default}</small>
                                    <div className="row ">

                                        <div className="col-sm-6 ">
                                            <div className="form-group ">
                                                <input type="text " name="name" {...register('name', { required: true })} id="validationCustomTwo3" className="form-control " placeholder={t('Member.Name')} required />
                                                <div className="invalid-feedback">
                                                    Please Fill Your Name.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 ">
                                            <div className="form-group">
                                                <select name="gender" {...register('gender', { required: true })} className="form-control form-select" id="validationCustomThree3" required >
                                                    <option value="" className="disabled-value d-none" >{t('Member.Gender')}</option>
                                                    <option value="male">{t('Member.Male')}</option>
                                                    <option value="female">{t('Member.Female')}</option>
                                                </select>
                                                <div className="invalid-feedback">
                                                    Select Your Gender.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 ">
                                            <div className="form-group ">
                                                <input type="text " name="state" {...register('state', { required: true })} className="form-control " placeholder={t('Member.State')} id="validationCustomFour3" required />
                                                <div className="invalid-feedback">
                                                    please Add Your  State.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 ">
                                            <div className="form-group ">
                                                <input type="text " name="city" {...register('city', { required: true })} className="form-control " placeholder={t('Member.City')} id="validationCustomFive3" required />
                                                <div className="invalid-feedback">
                                                    Please Add Your  City.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 ">
                                            <div className="form-group ">
                                                <input type="text" name="full_address" {...register('full_address', { required: true })} className="form-control " placeholder={t('Member.FullAddress')} id="validationCustomSix3" required />
                                                <div className="invalid-feedback">
                                                    please Enter Your  Address.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6 ">
                                            <div className="form-group">

                                                <Controller
                                                    name="dob"
                                                    control={control}
                                                    rules={{ required: "Please select a date." }}
                                                    render={({ field }) => (
                                                        <DatePicker
                                                            placeholderText="Select date"
                                                            onChange={(date) => field.onChange(date)}
                                                            maxDate={new Date()}
                                                            selected={field.value}
                                                            id="validationCustomEleven"
                                                        />
                                                    )}
                                                />
                                                {errors.dob && (
                                                    <div className="invalid-feedback" style={{ display: 'block' }}>
                                                        {errors.dob.message}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="col-sm-6 ">
                                            <div className="form-group ">
                                                <input type="number" name="mobile" {...register('mobile', { required: true })} className="form-control " placeholder={t('Member.Mobile')} id="validationCustomSeven3" required />
                                                <div className="invalid-feedback">
                                                    please Enter Your Mobile  Number.
                                                </div>
                                                <small className=" form-text text-muted">{formErrMsg.Mobile}</small>
                                            </div>
                                        </div>
                                        <div className="col-md-6 ">
                                            <div className="form-group ">
                                                <input type="email " name="email"  {...register('email', { required: true })} className="form-control " placeholder={t('Member.Gmail')} id="validationCustomEight3" required />
                                                <div className="invalid-feedback">
                                                    please Add Your Email.
                                                </div>
                                                <small className=" form-text text-muted">{formErrMsg.Email}</small>
                                            </div>
                                        </div>
                                        
                                        <div className={!isChecked ? `col-md-12` : `col-md-12 d-none`}>
                                            <div className="form-group ">
                                                <input type="text " name="work"  {...register('work', { required: false })} className="form-control " placeholder={t('Member.Work')} id="validationCustomEight4" false />
                                                <div className="invalid-feedback">
                                                    please Add Your Work.
                                                </div>
                    
                                            </div>
                                        </div>
                                        
                                        <div className="col-lg-6">
                                            <div className="form-group icon-position">
                                                <input type={visibility ? "password" : "text"} className="form-control" id="validationCustomNine1" {...register('password', { required: true })} placeholder="password" required />
                                                {
                                                    visibility ?
                                                        <span className="icon" onClick={() => pwVisibility(false)}>
                                                            <FaEye />
                                                        </span> :
                                                        <span className="icon" onClick={() => pwVisibility(true)}>
                                                            <FaEyeSlash />
                                                        </span>
                                                }


                                                <div className="invalid-feedback">
                                                    Please enter password.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="form-group icon-position password-field">
                                                <input type={visibilityConfirm ? "password" : "text"} className="form-control" id="val_ten1" {...register('confirm_password', { required: true })} placeholder="Confirm password" required />
                                                {
                                                    visibilityConfirm ?
                                                        <span className="icon" onClick={() => pwVisibilityConfirm(false)}>
                                                            <FaEye />
                                                        </span> :
                                                        <span className="icon" onClick={() => pwVisibilityConfirm(true)}>
                                                            <FaEyeSlash />
                                                        </span>
                                                }


                                                <div className="invalid-feedback">
                                                    Please enter confirm password.
                                                </div>
                                                <small className=" form-text text-muted">{passwordErr}</small>
                                            </div>



                                        </div>
                                        <div className="col-md-12 ">
                                            <div className="mb-3">
                                                <label className="custom-checkbox">
                                                    Are you in government service?
                                                    <input
                                                        className="me-2"
                                                        type="checkbox"
                                                        checked={isChecked}
                                                        onChange={handleCheckboxChange}
                                                    />
                                                    <span className="checkmark"></span>

                                                </label>
                                            </div>
                                        </div>

                                        {isChecked &&
                                            <><div className="col-md-6 ">
                                                <div className="form-group ">
                                                    <input type="text " name="designation" {...register('designation', { required: true })} className="form-control " placeholder={t('Servant.Designation')} id="validationCustomTwelve3" required />
                                                    <div className="invalid-feedback">
                                                        please Enter Your Designation.
                                                    </div>
                                                </div>

                                            </div><div className="col-md-6 ">
                                                    <div className="form-group ">
                                                        <input type="text " name="office_city" {...register('office_city', { required: true })} className="form-control " placeholder={t('Servant.OfficeCity')} id="validationCustomThirteen2" required />
                                                        <div className="invalid-feedback">
                                                            please Enter Your Office City Name.
                                                        </div>
                                                    </div>
                                                </div><div className="col-md-6 ">
                                                    <div className="form-group ">
                                                        <input type="text " name="office_address" {...register('office_address', { required: true })} className="form-control " placeholder={t('Servant.OfficeAddress')} id="validationCustomFourteen14" required />
                                                        <div className="invalid-feedback">
                                                            please Enter Your Office Address.
                                                        </div>
                                                    </div>
                                                </div><div className="col-md-6 ">
                                                    <div className="form-group ">
                                                        <input type="text " name="office_state" {...register('office_state', { required: true })} className="form-control " placeholder={t('Servant.OfficeState')} id="validationCustomFifteen2" required />
                                                        <div className="invalid-feedback">
                                                            please Enter Your Office State.
                                                        </div>
                                                    </div>
                                                </div></>
                                        }


                                        {/* <div className="col-md-12 ">
                                            <div className="form-group ">
                                                <textarea name="reason_of_join" className="form-control"  {...register('reason_of_join', { required: false })} placeholder={t('Member.Reason')} id="validationCustomTwelve1" />
                                                <div className="invalid-feedback">
                                                    please Add Your Join Us Reason.
                                                </div>
                                            </div>
                                        </div> */}
                                        {/* <ReCAPTCHA sitekey="6Lcjpz4cAAAAAAa-wXnIOrWRYxX1krMFTIBwVBXZ" onChange={onChangeCaptcha} /> */}
                                        <div className="col-md-12 ">
                                            <div className="brown-button submit " >

                                                <ButtonLoader btndisabled={submitDisabled} loader={spinLoadVisibility} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>

    )
}

export default AddMemberForm;