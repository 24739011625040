import React, { useEffect, useState, useCallback } from 'react'
import axios from 'axios';
import PORTNUMBER from '../../../../api/api'
import { useParams, Link } from "react-router-dom"
import { ToastContainer, toast } from "react-toastify";
import { MdDelete } from 'react-icons/md';
import {GiSwordsEmblem  } from 'react-icons/gi';

import { useSelector } from 'react-redux'



// react bootstrap
import { Modal, Button, ProgressBar } from 'react-bootstrap'
const SocietyNews = () => {

  const { id } = useParams()
  const Token = localStorage.getItem("token")
  const PORT = PORTNUMBER.PORTNUMBER


  const [imagesWine, setImagesWine] = useState([]);
  const [loading, setLoading] = useState(false);
  const [buttonVisible, setButtonVisible] = useState(false);

  // modal active
  const [activeShow, setActiveShow] = useState(false);
  const [statusId, setStatusId] = useState();


  const [progress, setProgress] = useState()
  const [progressVisiblity, setProgressVisiblity] = useState(null)
  const [wineLoading, setWineLoading] = useState(false)

  const eventNameForImage = useSelector((state) => state.eventNameReducers)

  const activeHandleClose = () => setActiveShow(false);
  const activeHandleShow = (id) => {
    setActiveShow(true);
    setStatusId(id)
  }

  const activeUser = async () => {
    await axios.post(`${PORT}/product/deleteImage`, { imageId: statusId, productId: id }, { headers: { authorization: `Bearer ${Token}` } }).then((res, err) => {
      LoadImages()
      toast.success("Wine image is deleted successfully.")
      activeHandleClose()
    })
  }


  const handleImageChange = (e) => {
    if (e.target.files) {
      // setButtonVisible(true)
      onSubmit()

    }
  };

  const LoadImages = useCallback(async () => {

    await axios.post(`${PORT}/product/detail`, { productId: id }, { headers: { authorization: `Bearer ${Token}` } })
      .then((res) => {
  
        setImagesWine(res.data.data.images);

      })
    setWineLoading(false)
    setLoading(true);
  },[PORT,id,Token])

  useEffect(() => {
    LoadImages()
  },[LoadImages])


  const onSubmit = (e) => {

    setProgressVisiblity(true)
    setButtonVisible(false)
    const imageFiles = document.getElementById('imagesUpload').files;
    if (imageFiles.length > 0) {


      var fd = new FormData();

      for (let i = 0; i < imageFiles.length; i++) {
        fd.append("images", imageFiles[i]);
      }
      fd.append("productId", id);
      const headerConfig = {
        headers: {
          'authorization': `Bearer ${Token}`,
          'Content-Type': `multipart/form-data; boundary=${fd._boundary}`,
        },
        onUploadProgress: fd => {
          //Set the progress value to show the progress bar
          setProgress(Math.round((100 * fd.loaded) / fd.total))
        },
      }
      axios.post(`${PORT}/product/addImage`, fd, headerConfig)
        .then((res) => {
          LoadImages()
          toast.success("Wine images are uplaoded successfully.");
          setProgressVisiblity(false)
          setProgress(null)

        })
      setWineLoading(true)
    } else {
    
      toast.error("Upload atleast one image");
    }

  };

  useEffect(() => {
    document.body.classList.add('remove-home-header', 'admin-dashboard');
    return () => {
      document.body.classList.remove('remove-home-header', 'admin-dashboard');

    }
  }, [])
  return (
    <React.Fragment>

      <div className="col-12">
        <div className="row">
          <div className="col-sm-9">
            <div className="main-head">
              <h4>Wine images for {eventNameForImage}</h4>
            </div>
          </div>
          <div className="col-sm-3">
            <div className="theme-btn text-right top-head">
              <Link to="/adminWine" className="btn">Back</Link>
            </div>
          </div>
        </div>
      </div>
      <div className="wine-images shadow">
        <div className="tab-head">
          <div className="row">
            <div className="col-lg-2 col-md-3 col-sm-6">
              <div className="theme-btn  file-upload-button">
                <input type="file" id="imagesUpload" multiple onChange={handleImageChange} />
                <button className="btn">Upload Images</button>
              </div>
            </div>
            <div className="col-lg-10 col-md-9 col-sm-6">
              <div className="theme-btn progress-bttons ">
                {progressVisiblity ? progress && <button className="btn btn-progress w-100"><ProgressBar now={progress} label={`${progress}%`} /></button> : ""}
                {
                  buttonVisible ? <button type="submit" onClick={(e) => onSubmit(e)} className="btn w-100" >Click To Upload</button> : ''
                }

              </div>
            </div>
          </div>


          <div className="images-box-wine">

            {loading ? imagesWine && imagesWine.length ?
              <div className="row row-cols-2 row-cols-lg-5 row-cols-md-3  g-2 ">
                {
                  imagesWine.map((images,i) => (
                    <div className="col mt-0" key={i + 1}>

                      <div className="images-box ">
                        <img src={images.file} alt="" />
                        <span onClick={() => activeHandleShow(images._id)} className="icons-action"><MdDelete /></span>
                      </div>


                    </div>
                  ))
                }

              </div> :
              <div className="images-box min-height-box">
                <div className="no-data">
                  <h3>No image availabe for this wine.</h3>
                </div>
              </div> :
              <div className="loader-wrapper">
                <div className="loader">
                  {/* <img src="../assets/images/logo-loader.png" alt="" /> */}
                  <div className="loading-svg">
                    <GiSwordsEmblem />

                  </div>

                </div>

              </div>
            }






            {
              wineLoading ?
                <div className="loader-wrapper">
                  <div className="loader">
                    {/* <img src="../assets/images/logo-loader.png" alt="" /> */}
                    <div className="loading-svg">
                      < GiSwordsEmblem/>

                    </div>

                  </div>

                </div> : ""
            }

          </div>
        </div>

        {/* modals */}

        {/* active modal */}
        <Modal className="status-modal" animation={false} show={activeShow} onHide={activeHandleClose}>

          <Modal.Body>Are you sure you want to delete this image ?</Modal.Body>
          <Modal.Footer>
            <div className="theme-btn btn-group-right text-right">
              <Button variant="secondary"
                onClick={activeUser}
              >
                Yes
              </Button>
              <Button variant="primary" onClick={activeHandleClose}>
                No
              </Button>
            </div>

          </Modal.Footer>
        </Modal>
        {/* active modal end */}

        <ToastContainer />


      </div>
    </React.Fragment>
  )
}

export default SocietyNews;