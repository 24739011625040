import React, { useCallback, useEffect, useState } from 'react'
import { ImageObj } from '../../../../assets/images/images'
import { useNavigate, useParams,Link } from 'react-router-dom'
import { useForm } from 'react-hook-form';
import axios from 'axios'

import RESETPASSWORD_API_ADMIN from '../../../../api/api'

const ResetPassword = () => {
    const navigate = useNavigate()
    const [ConfirmErr, setConfErr] = useState()

    const { id } = useParams()

    const [decryptedMail, setDecryptedMail] = useState()


    const Token = localStorage.getItem("authtoken")

    const { register, handleSubmit } = useForm();


    const DeCryptLoad = useCallback( () => {
        setDecryptedMail(decodeURI(id))
    },[id])

    useEffect(() => {
        DeCryptLoad()
        document.body.classList.add("remove-home-header");
        return () => {
            document.body.classList.remove("remove-home-header");
        }
    }, [DeCryptLoad])
    const resetLink = (data) => {
        if (data.password === data.confirm_password) {
            axios.post(RESETPASSWORD_API_ADMIN.RESETPASSWORD_API_ADMIN, { password: data.password, email: decryptedMail }, { headers: { authtoken: Token } })
                .then(() => {
                    navigate("/admin")

                })
        } else {
            setConfErr("Password not matched")
        }



    }

    useEffect(() => {
        document.body.classList.add("remove-home-header");
    }, [])

    return (
        <div className="container">
            <div className="max-new-second max-margin">
                <div className="main-theme bg-theme border otp-pad new shadow">
                    <section className="select-state">
                        <div className="state-bor">
                            <div className="log-in otp-num">
                                <div className="select-steps-box">
                                    <div className="input-head">
                                        <h2>Reset Password</h2>
                                    </div>
                                </div>
                                <div className="select-box-top pr-0">
                                    <div className="input-box">
                                        <form onSubmit={handleSubmit(resetLink)}>
                                            <div className="col-12 p-0">
                                                <div className="row">
                                                    <div className="col-sm-12">
                                                        <div className="form-group">
                                                            <input type="password" className="form-control" {...register('password', { required: true })} placeholder="Enter password" />
                                                            <small id="emailHelp" className=" d-none form-text text-muted">We'll never share your email with anyone else.</small>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-12">
                                                        <div className="form-group">
                                                            <input type="password" className="form-control" {...register('confirm_password', { required: true })} placeholder="Confirm password" />
                                                            <small className=" form-text text-muted">{ConfirmErr}</small>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="theme-btn select-m">
                                                <button type="submit" className="submit btn verfy-btn" >Reset Password <span><img aria-hidden="true" src={ImageObj.WhiteArrow} alt="" /></span></button>
                                                <p className="resend new">Have an account? <span><Link to="/admin">Login <i className="fas fa-angle-right " aria-hidden="true" /></Link></span></p>
                                            </div>
                                        </form>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    )
}

export default ResetPassword;