import axios from "axios"
import React, { useState, useEffect, useCallback } from "react"
import PORTNUMBER from '../../../../api/api'
import { useNavigate, useParams } from "react-router-dom"
import { useForm, Controller } from "react-hook-form";
import { ImageObj } from "../../../../assets/images/images";
import { useTranslation } from 'react-i18next';
import FormValidation from '../../../../formValidation/formValidation'
import { toasterValue, toasterErrValue } from '../../../../redux/actions/action'
import { useDispatch } from 'react-redux';
import IMAGE_HOST from '../../../../api/api'


// Import Datepicker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ButtonLoader from "../submitButton/submitButton";

const EditMember = () => {
    const navigate = useNavigate();
    const { id } = useParams()
    const TOKEN = localStorage.getItem("token")
    const UserTokenId = localStorage.getItem("UserTokenId")
    const TOKEN_REF_ID = localStorage.getItem("RefId")
    const dispatch = useDispatch()
    const HOST_PORT = IMAGE_HOST.IMAGE_HOST
    const [spinLoadVisibility, setSpinLoadVisibility] = useState(false);



    const { t } = useTranslation();
    const PORT = PORTNUMBER.PORTNUMBER

    const [userIdData, setUserIdData] = useState(["not"])

    const { control, register, handleSubmit } = useForm();

    const [imageTypeErr, setImageTypeErr] = useState("")
    const [submitDisabled, setSubmitDisabled] = useState(null)

    const FormValidationNew = FormValidation

    const [formErrMsg, setFormErrMsg] = useState({
        Email: "",
        Mobile: "",
        default: "",
        Success: null
    })

    const [state, setState] = useState({
        onMind: "",
        image: "",
    })



    const onImageChange = (event) => {
        var fileName = event.target.value;
        var dot = fileName.lastIndexOf(".") + 1;
        var extFile = fileName.substr(dot, fileName.length).toLowerCase();
        if (extFile == "jpg" || extFile == "jpeg" || extFile == "png") {
            if (event.target.files && event.target.files[0]) {
                let reader = new FileReader();
                reader.onload = (e) => {
                    setState({ image: e.target.result });
                    setImageTypeErr("")
                    setSubmitDisabled(false)
                };
                reader.readAsDataURL(event.target.files[0]);

            }
        } else {
            setSubmitDisabled(true)
            setImageTypeErr("Only jpg, png and jpeg image files allowed!")
        }

    }

    // dote of birth 
    let DobLoad = new Date(userIdData.dob);
    DobLoad.toDateString()
    const DateLoad = userIdData.dob === undefined ? new Date() : DobLoad

    const isValidDate = (date) => {
        return date instanceof Date && !isNaN(date.getTime());
    };

    const defaultDate = new Date(); // Safe default date
    const initialDate = isValidDate(DateLoad) ? DateLoad : defaultDate; // Ensure DateLoad is a valid date



    const LoadProfile = useCallback(async () => {
        await axios.post(`${PORT}/user/profile`, { userId: id }, { headers: { authorization: `Bearer ${TOKEN}` } })
            .then((res) => {
                setUserIdData(res.data.data)
            })
    }, [id, TOKEN, PORT])

    useEffect(() => {
        FormValidationNew()
        LoadProfile()
    }, [FormValidationNew, LoadProfile])

    const onSubmit = async (data) => {
        setSpinLoadVisibility(true)
        const imageFiles = document.getElementById('profileImage').files.length;

        const fs = new FormData();
        fs.append("userId", id);
        for (const [key] of Object.entries(data)) {


            data[key] = (data[key] === undefined || data[key] === null || data[key] === "") ? userIdData[key] : data[key];
            fs.append(key, data[key]);

        }
        if (imageFiles > 0) {
            const File = document.getElementById('profileImage').files[0];
            fs.append("image", File);
        }


        // for(const [key] of Object.entries(data)){

        //     data[key] = (data[key] === undefined || data[key] === null || data[key] === "") ? userIdData[key] : data[key]
        // }


        const userCreate = await axios.post(`${PORT}/user/profile/update`, fs, { headers: { authorization: `Bearer ${TOKEN}` } });
        setSpinLoadVisibility(false)
        setSubmitDisabled(false)
        if (userCreate.data.message === "User is updated successfully!") {
            setSpinLoadVisibility(false)
            dispatch(toasterValue(" Profile updated successfully."))
            navigate('/')
            navigate(`/profile/${UserTokenId}`)
        } else {
            dispatch(toasterErrValue("Updated profile  not successfully."))
        }
        switch (userCreate.data.message) {
            case "Email is already exist!":
                setFormErrMsg({
                    Email: userCreate.data.message,
                });
                break;

            case "Mobile number already exist!":
                setFormErrMsg({
                    Mobile: userCreate.data.message,
                });
                break;


            default:
                setFormErrMsg({
                    default: userCreate.data.message,
                });

        }



    }
    return (
        <React.Fragment>

            <section className="app-form sec padding">
                <div className="container ">
                    <div className="touch-head app">
                        <h4>Application Form Member</h4>
                    </div>
                    <div className="application main-theme shadow">
                        <div className="touch-form app">
                            <form onSubmit={handleSubmit(onSubmit)} className="needs-validation" noValidate>
                                <div className="col-12">
                                    <div className="information-user d-flex">
                                        <div className="info-image">


                                            {!state.image ? userIdData.image ?
                                                <img src={`${HOST_PORT}/${userIdData.image}`} alt="" />
                                                : <img aria-hidden="true" src={ImageObj.UserBlank} alt="Committee" />
                                                : <img src={state.image} alt="" />
                                            }

                                        </div>
                                        <h3>{state.image || userIdData.image ? "Change Image" : "Upload Image"} <input id="profileImage" name="image" onChange={onImageChange} type="file" className="form-control" />
                                            <div className="form-text text-muted">
                                                {imageTypeErr}
                                            </div>
                                        </h3>
                                        {/* <h4>Remove</h4> */}
                                    </div>
                                </div>
                                <div className="col-sm-12 ">
                                    <div className="row ">

                                        <div className="col-sm-6 ">
                                            <div className="form-group ">
                                                <label>{t('Member.Name')}</label>
                                                <input type="text " name="name" defaultValue={userIdData.name} {...register('name', { required: false })} id="validationCustomTwo3" className="form-control " placeholder={t('Member.Name')} />
                                                <div className="invalid-feedback">
                                                    Please Fill Your Name.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 ">
                                            <div className="form-group">
                                                <label>{t('Member.Gender')}</label>
                                                <select name="gender" {...register('gender', { required: false })} className="form-control form-select" id="validationCustomThree4" required >
                                                    <option className="disabled-value d-none" >{userIdData.gender}</option>
                                                    <option value="male">{t('Member.Male')}</option>
                                                    <option value="female">{t('Member.Female')}</option>
                                                </select>
                                                <div className="invalid-feedback">
                                                    Select Your Gender.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 ">
                                            <div className="form-group ">
                                                <label>{t('Member.State')}</label>
                                                <input type="text " name="state" defaultValue={userIdData.state} {...register('state', { required: false })} className="form-control " placeholder={t('Member.State')} id="validationCustomFour4" />
                                                <div className="invalid-feedback">
                                                    please Add Your  State.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 ">
                                            <div className="form-group ">
                                                <label>{t('Member.City')}</label>
                                                <input type="text " name="city" defaultValue={userIdData.city} {...register('city', { required: false })} className="form-control " placeholder={t('Member.City')} id="validationCustomFive4" />
                                                <div className="invalid-feedback">
                                                    Please Add Your  City.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 ">
                                            <div className="form-group ">
                                                <label>{t('Member.FullAddress')}</label>
                                                <input type="text " name="full_address" defaultValue={userIdData.full_address} {...register('full_address', { required: false })} className="form-control " placeholder={t('Member.FullAddress')} id="validationCustomSix4" />
                                                <div className="invalid-feedback">
                                                    please Enter Your  Address.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6 ">
                                            <div className="form-group">
                                                <label>{t('Member.Age')}</label>
                                                <Controller
                                                    id="validationCustomEleven"
                                                    control={control}
                                                    name="dob"
                                                    render={({ field }) => (
                                                        <DatePicker
                                                            placeholderText={t('Member.Age')}
                                                            onChange={(date) => field.onChange(date)}
                                                            maxDate={new Date()} // Ensure no future dates
                                                            selected={isValidDate(field.value) ? field.value : initialDate} // Validate `field.value` before using it
                                                        />
                                                    )}
                                                />
                                                <div className="invalid-feedback">
                                                    Please select date.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-sm-6 d-none">
                                            <div className="form-group ">
                                                <label>{t('Member.Mobile')}</label>
                                                <input type="number" name="mobile" defaultValue={userIdData.mobile} {...register('mobile', { required: false })} className="form-control " placeholder={t('Member.Mobile')} id="validationCustomSeven4" disabled />
                                                <div className="invalid-feedback">
                                                    please Enter Your Mobile  Number.
                                                </div>
                                                <small className=" form-text text-muted">{formErrMsg.Mobile}</small>
                                            </div>
                                        </div>
                                        <div className="col-md-6 d-none">
                                            <div className="form-group ">
                                                <label>{t('Member.Gmail')}</label>
                                                <input type="email " name="email" defaultValue={userIdData.email}  {...register('email', { required: false })} className="form-control " placeholder={t('Member.Gmail')} id="validationCustomEight5" disabled />
                                                <div className="invalid-feedback">
                                                    please Add Your Email.
                                                </div>
                                                <small className=" form-text text-muted">{formErrMsg.Email}</small>
                                            </div>
                                        </div>

                                        {userIdData.type === "govt" &&
                                            <>
                                                <div className="col-md-6 ">
                                                    <div className="form-group ">
                                                        <label>{t('Servant.Designation')}</label>
                                                        <input type="text " name="designation" defaultValue={userIdData.designation}  {...register('designation', { required: false })} className="form-control " placeholder={t('Servant.Designation')} id="validationCustomTwelve2" />
                                                        <div className="invalid-feedback">
                                                            please Enter Your Designation.
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="col-md-6 ">
                                                    <div className="form-group ">
                                                        <label>{t('Servant.OfficeCity')}</label>
                                                        <input type="text " name="office_city" defaultValue={userIdData.office_city}  {...register('office_city', { required: false })} className="form-control " placeholder={t('Servant.OfficeCity')} id="validationCustomThirteen1" />
                                                        <div className="invalid-feedback">
                                                            please Enter Your Office City Name.
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 ">
                                                    <div className="form-group ">
                                                        <label>{t('Servant.OfficeAddress')}</label>
                                                        <input type="text " name="office_address" defaultValue={userIdData.office_address}  {...register('office_address', { required: false })} className="form-control " placeholder={t('Servant.OfficeAddress')} id="validationCustomFourteen13" />
                                                        <div className="invalid-feedback">
                                                            please Enter Your Office Address.
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 ">
                                                    <div className="form-group ">
                                                        <label>{t('Servant.OfficeState')}</label>
                                                        <input type="text " name="office_state" defaultValue={userIdData.office_state} {...register('office_state', { required: false })} className="form-control " placeholder={t('Servant.OfficeState')} id="validationCustomFifteen1" />
                                                        <div className="invalid-feedback">
                                                            please Enter Your Office State.
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                        {!userIdData.designation && 
                                            <div className="col-md-12 ">
                                                <div className="form-group ">
                                                    <label>{t('Member.Work')}</label>
                                                    <input type="text " name="work" defaultValue={userIdData.work}  {...register('work', { required: false })} className="form-control " placeholder={t('Member.Work')} id="validationCustomEight6" />
                                                    <div className="invalid-feedback">
                                                        please Add Your Work.
                                                    </div>

                                                </div>
                                            </div>

                                            }
                                        {/* <div className="col-md-12 ">
                                        <div className="form-group ">
                                        <label>{t('Member.Reason')}</label>
                                            <textarea name="reason_of_join" defaultValue={userIdData.reason_of_join} className="form-control"  {...register('reason_of_join', { required: false })} placeholder={t('Member.Reason')} id="validationCustomTwenty"   />
                                            <div className="invalid-feedback">
                                                please Enter Your To Join Us.
                                            </div>
                                        </div>
                                    </div> */}
                                        <div className="col-md-12 ">
                                            <div className="brown-button submit ">
                                                <ButtonLoader btndisabled={submitDisabled} loader={spinLoadVisibility} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>

    )
}

export default EditMember;