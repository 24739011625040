import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './i18next/i18next'

import './index.scss';
import App from './app/App';
import store from './app/store'
import { Provider } from 'react-redux'
import { GiSwordsEmblem } from 'react-icons/gi';
import reportWebVitals from './reportWebVitals';



const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
  <Suspense fallback={(<div className="loader-wrapper">
    <div className="loader">

      <div className="loading-svg">
        <GiSwordsEmblem />

      </div>

    </div>

  </div>)}>
    <Provider store={store}>
      <App />
    </Provider>
  </Suspense>
</React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
reportWebVitals();

