import React, { useState, useEffect, useCallback, useRef, } from 'react'
import { ImageObj } from '../../../../assets/images/images'

import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { RiUserSearchFill } from 'react-icons/ri';
import IMAGE_HOST from '../../../../api/api'
import { useForm } from 'react-hook-form';
// import { FaFacebookF, FaInstagramSquare, FaTwitter } from 'react-icons/fa';
// import { IoLogoWhatsapp } from 'react-icons/io';
import { Helmet } from 'react-helmet'
import { loginModalTrue } from '../../../../redux/actions/action'
import { useDispatch, useSelector } from 'react-redux'
// import PaginationCount from '../../../common/pagination/pagination'
import { usePaginatedUsers } from '../../../../hooks/usePaginatedUsers';




const Team = () => {
    // const [filters, setFilters] = useState({});
    const [filterData, setFilterData] = useState([]);

    const { data: userList, isLoading, isError, error, fetchNextPage, isFetchingNextPage, hasNextPage } = usePaginatedUsers({type: "govt", ...filterData});
    // const { data: filterUsers, isLoading : filterLoading, isError : filterError, error : errorUser } = useFilteredUsers(filterData);
    const observerRef = useRef(); // Reference for the observer
    console.log("userList", userList)
    const UserActive = useSelector((state) => state.userLoginReducer)


    const dispatch = useDispatch()
    const navigate = useNavigate()
    const TOKEN = localStorage.getItem("token")
    const { t } = useTranslation();
 
    const [userId, setUserId] = useState();

    const HOST_PORT = IMAGE_HOST.IMAGE_HOST

    const { register, handleSubmit } = useForm();


    const onSubmit = async (data) => {
        setFilterData({type: "govt", ...data})
    }

    const openProfile = useCallback((user) => {
        setUserId(user)
        if (TOKEN || UserActive) {
            navigate(`/profile/${user}`)
        } else {
            dispatch(loginModalTrue(true))
        }

    }, [UserActive])

    useEffect(() => {
        if (UserActive === true && userId) {
            navigate(`/profile/${userId}`)
        }
    }, [UserActive])


    // useEffect(() => {
    //     loadUser();

    // }, [loadUser]);

    // Intersection Observer callback
    const handleIntersection = (entries) => {
        if (entries[0].isIntersecting && hasNextPage) {
            fetchNextPage(); // Fetch the next page when the observer is triggered
        }
    };

    useEffect(() => {
        const observer = new IntersectionObserver(handleIntersection, {
            root: null,
            threshold: 1.0, // Fully visible before triggering
        });

        if (observerRef.current) {
            observer.observe(observerRef.current);
        }

        return () => {
            if (observerRef.current) {
                observer.unobserve(observerRef.current);
            }
        };
    }, [hasNextPage]); // Re-run when `hasNextPage` changes

    if (isLoading) {
        return <div className="loader-wrapper absolute-sec">
            <div className="loader">

                <div className="loading-svg">
                    <RiUserSearchFill />

                </div>

            </div>

        </div>;
    }

    if (isError) {
        return <div>Error: {error.message}</div>;
    }


    return (
        <React.Fragment>
          <Helmet>
                <title>All Koli Government Employee</title>
                <meta name="description" content="To give new direction to the Koli society and to give education assistance and advance the society to Koli Samaj Vikash sanstha sangathan and give your development!" />
                <meta name="keywords" content="Government Employee koli samaj" />
                <meta name="author" content="mahendra koli" />
                <link rel="canonical" href="https://www.kolisamajvikashsanstha.com/allMember/govtEmployeeList" />
            </Helmet>
            <section className="home-sec">
                <div className="bg-page all-team" style={{ backgroundImage: `url(${ImageObj.TeamImage})` }}>
                    <div className="container">
                        <div className="col-sm-12">
                            <div className="bg-content">
                                <div className="text-box new">
                                <h3>{t('MembersList.employee')}</h3>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="portfolio  pb-0 sec padding dir">
                <div className="container">
                    <div className="col-sm-12">
                        <div className="row">
                            <div className="col-sm-4">
                                <div className="portfolio-left">
                                    <h4>{t('whyChoose.head')}</h4>
                                    <h3>{t('whyChoose.description')}</h3>
                                </div>
                            </div>
                            <div className="col-sm-8">
                                <div className="counts mt-0">
                                    <div className="row">
                                        <div className="col-4">
                                            <div className="team-count">
                                                <h5>1000</h5>
                                                <p>{t('MembersList.totalMembers')}</p>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="team-count">
                                                <h5>500+</h5>
                                                <p>{t('MembersList.totalEmployee')}</p>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="team-count">
                                                <h5>500</h5>
                                                <p>{t('MembersList.totalPoltician')}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <section className="team-sec pb-0 sec padding dir">
                <div className="container">
                    <div className="brown-head text-center">
                        <p>Out Team</p>
                        <h4>Board of directors</h4>
                    </div>


                    <div className="directors">
                        <div className="col-sm-12">
                            <div className="row">
                                <div className="col-md-6" data-aos="fade-right" data-aos-duration="1000">
                                    <div className="director-box">
                                        <div className="box-img">
                                            <div className="img-inner" style={{ backgroundImage: `url(${ImageObj.Mahendra})` }}>
                                               
                                            </div>
                                        </div>
                                        <div className="box-content new">
                                            <h5>Mahendra Mahawar Koli</h5>
                                            <h6>Founder | Supervisor</h6>



                                            <div className="social-new team-admin">
                                                <div className="badge-box">
                                                    <a rel="noopener noreferrer" href="https://www.facebook.com/Mahendra-mahawar-koli-108679051609189" target="_blank" className="badge"><FaFacebookF /></a>
                                                    <a rel="noopener noreferrer" href="https://www.instagram.com/mahee_arya/" target="_blank" className="badge"><FaInstagramSquare /></a>
                                                    <a rel="noopener noreferrer" href="https://twitter.com/mahendr48502257" target="_blank" className="badge"><FaTwitter /></a>
                                                </div>
                                            </div>


                                            <p>नमस्कार दोस्तो में महेंद्र महावर कोली Founder/Supervisor <span className="d-block"></span> कोली समाज विकास संस्था की ओर से आप सभी का एकता के इस मंच पर हार्दिक स्वागत करता हु! 
आशा करता हु कोली समाज को आगे बढ़ाने में आप हमारी मदद करेंगे धन्यवाद!
</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6" data-aos="fade-right" data-aos-duration="2000">
                                    <div className="director-box">
                                        <div className="box-img">
                                            <div className="img-inner">
                                                <img aria-hidden="true" src={ImageObj.BkKoli} alt="director-img" />
                                            </div>
                                        </div>
                                        <div className="box-content new">
                                            <h5>Banwari koli <span> (BK Mahawar)</span></h5>
                                            <h6>Co-Supervisor </h6>
                                            <div className="social-new team-admin">
                                                <div className="badge-box">
                                                    <a rel="noopener noreferrer" href="https://www.facebook.com/bk.mahawar.37" target="_blank" className="badge"><FaFacebookF /></a>
                                                    <a rel="noopener noreferrer" href="https://www.instagram.com/bkmahawar15/" target="_blank" className="badge"><FaInstagramSquare /></a>
                                                    <a rel="noopener" href="https://twitter.com/mahendr48502257" target="_blank" className="badge"><FaTwitter /></a>
                                                </div>
                                            </div>
                                            <p>नमस्कार दोस्तो में बनवारी महावर कोली Co-Supervisor <span className="d-block"></span> कोली समाज विकास संस्था की ओर से आप सभी का एकता के इस मंच पर हार्दिक स्वागत करता हु! 
आशा करता हु कोली समाज को आगे बढ़ाने में आप हमारी मदद करेंगे धन्यवाद! </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
          <section className="directors-sec sec padding dir" >
                <div className="container">
                    <div className="brown-head new text-center">
                        <p>Out Team</p>
                        <h4>Our Awesome team</h4>
                        <h6>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt
                            ut labore et dolore magna aliquyam erat, sed diam</h6>
                    </div>
                    <div className="directors loader-sec">
                        <div className="user-filter-box">
                            <div className="tab-head">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="row filter-box" >
                                        {/* <div className="col-md-2 col-sm-6">
                                            <div className="form-group">
                                                <select disabled={true} className="form-select form-control" {...register('type', { required: false })} aria-label="Default select example">
                                                    <option className="disabled-value d-none" value="govt"  >Govt Employee</option>
                                               

                                                </select>
                                            </div>
                                        </div> */}
                                        <div className="col-md-3 col-sm-6">
                                            <div className="form-group">
                                                <input type="text" {...register('name', { required: false })} className="form-control" placeholder="Name" />
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-sm-6">
                                            <div className="form-group">
                                                <input type="text" {...register('city', { required: false })} className="form-control" placeholder="City" />
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-sm-6">
                                            <div className="form-group">
                                                <input type="text" {...register('state', { required: false })} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="State" />
                                            </div>
                                        </div>



                                        <div className="col-md-3 col-sm-12">
                                            <div className="theme-btn btn-group-right text-right">
                                                <button type="submit" className="btn">Search</button>
                                                <button type="reset"
                                                     onClick={() => setFilterData('')} 
                                                    className="btn">Reset</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-sm-12">
                            <div className="row">
                                {userList.pages.map((page, pageIndex) => (
                                    <React.Fragment key={pageIndex}>
                                        {
                                            !isLoading ? userList ? page.data.map((user, i) => (
                                                <div className="col-lg-3 col-sm-4" key={i + 1} data-aos="fade-up" data-aos-duration="1000">
                                                    <div onClick={() => openProfile(user._id)} className="director-box team cursor-pointer">
                                                        <div className="box-img">
                                                            <div className="img-inner">
                                                                {
                                                                    user.image ?
                                                                        <img aria-hidden="true" src={`${HOST_PORT}/${user.image}`} alt="koli_member" ></img> :
                                                                        <img aria-hidden="true" src={ImageObj.Teacher1} alt="koli_member" />
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="box-content">
                                                            <h5>{user.name}</h5>
                                                            <h6>{user.type === "govt" ? "Govt. Employee" : user.type}</h6>
                                                        </div>
                                                    </div>
                                                </div>

                                            )) : <span className="no-data">No List Found</span>
                                                : <div className="loader-wrapper absolute-sec">
                                                    <div className="loader">

                                                        <div className="loading-svg">
                                                            <RiUserSearchFill />

                                                        </div>

                                                    </div>

                                                </div>

                                        }
                                    </React.Fragment>
                                ))}
                                {/* This is the element that triggers fetching more data when it comes into view */}
                                {hasNextPage && <div ref={observerRef}>
                                    {hasNextPage && isFetchingNextPage && <div className="spinner">
                                        <div className="rect1"></div>
                                        <div className="rect2"></div>
                                        <div className="rect3"></div>
                                        <div className="rect4"></div>
                                        <div className="rect5"></div>
                                    </div>}
                                </div>}
                            </div>
                        </div>

                        {/* <PaginationCount count={Count} itemperpage={itemPerPage} activepage={activePage}
                        //  handlepagechange={handlePageChange} 
                        /> */}
                    </div>
                </div>
            </section>
            <section className="directors-sec-last sec padding dir pt-0">
                <div className="container">
                    <div className="brown-head new text-center">
                        <p>Partners</p>
                        <h4>Our Partners</h4>
                        <h6>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt
                            ut labore et dolore magna aliquyam erat, sed diam</h6>
                    </div>
                    <div className="directors">
                        <div className="col-sm-12">
                            <div className="row">
                                <div className="col-lg-3 col-sm-4">
                                    <div className="partner-box team">
                                        <div className="box-img">
                                            <div className="img-inner">
                                                <img aria-hidden="true" src={ImageObj.AB} alt="ab-img" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-sm-4">
                                    <div className="partner-box team">
                                        <div className="box-img">
                                            <div className="img-inner">
                                                <img aria-hidden="true" src={ImageObj.AB} alt="urban-img" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-sm-4">
                                    <div className="partner-box team">
                                        <div className="box-img">
                                            <div className="img-inner">
                                                <img aria-hidden="true" src={ImageObj.AB} alt="lettire-img" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-sm-4">
                                    <div className="partner-box team">
                                        <div className="box-img">
                                            <div className="img-inner">
                                                <img aria-hidden="true" src={ImageObj.AB} alt="jk-img" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}


export default Team;